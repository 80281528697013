.slider {
  display: grid;
  user-select: none;
}

.slider-active :first-child {
  pointer-events: none;
}

.iconContainer {
  grid-column: 1;
  grid-row: 1;
  display: grid;
  align-items: center;
  width: '100%';

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .leftIcon {
      margin-left: 20px;
      height: 19px;
      width: 19px;
    }

    .rightIcon {
      margin-right: 20px;
      height: 19px;
      width: 19px;
    }
  }
}

.icon {
  color: white;
  height: 19px;
  width: 19px;
}

.innerItem {
  grid-column: 1;
  grid-row: 1;
  background-color: 'white';
}
