@import 'theme/mixins';

.input {
  overflow: visible;
  --min-height: auto;
  --highlight-height: 0;
  --background: transparent;
  --background-activated: transparent;
  --background-activated-opacity: 1;
  --background-focused: transparent;
  --background-focused-opacity: 1;
  --background-hover: transparent;
  --background-hover-opacity: 1;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;

  ion-col {
    padding: 0;
  }

  ion-input {
    height: 40px;
  }

  ion-textarea {
    white-space: pre-wrap;
  }

  .component {
    min-height: 44px;
    font-size: 14px;
    line-height: 16px;
    margin: -2px 16px;
    --padding-top: 12px;
    --padding-bottom: 12px;
    --padding-start: 0;
    --padding-end: 0;
    --placeholder-opacity: 1;
    --placeholder-color: var(--mipro-color-medium);
    color: var(--mipro-color-dark);
  }

  ion-button:has(+ .component) {
    margin-inline-start: 16px;
  }

  .component ~ ion-button {
    margin-inline-end: 16px;
  }

  .labelWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    max-width: unset;

    .label,
    .requiredLabel {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: var(--mipro-color-dark);
    }

    .requiredLabel {
      margin-inline-start: 4px;
      color: var(--mipro-color-secondary);
    }
  }

  ion-label ~ .wrapper {
    margin-top: 4px;
  }

  .wrapper {
    align-items: center;
    flex-wrap: nowrap;
    border-radius: 6px;
    border: 1px solid var(--mipro-color-dark);
    background: var(--mipro-color-white);

    ion-button {
      position: relative;
      @include increaseTouchTargetSize(50%, 0, 50%);

      svg,
      span {
        font-size: 14px;
        color: var(--mipro-color-medium);
      }
    }

    &:focus-within {
      border-color: var(--mipro-color-primary) !important;
      box-shadow: 0px 0px 9px 0px #0c6cd44d;
    }
  }

  &.error {
    .wrapper {
      border-color: var(--mipro-color-danger);
    }
  }

  &.warning {
    .wrapper {
      border-color: var(--mipro-color-warning);
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 1;

    .component,
    .charCounter {
      color: var(--mipro-color-disabled-shade);
    }

    .labelWrapper {
      .label,
      .requiredLabel {
        color: var(--mipro-color-disabled-shade);
      }
    }

    .wrapper {
      background: var(--mipro-color-disabled);
      border-color: var(--mipro-color-disabled-shade);

      ion-button {
        svg,
        span {
          color: var(--mipro-color-medium);
        }
      }
    }
  }

  &.readonly {
    .component,
    .charCounter {
      color: rgba(var(--mipro-color-dark-text-rgb), 0.5);
    }

    .wrapper {
      border-color: rgba(var(--mipro-color-dark-text-rgb), 0.5);
    }
  }

  &.viewonly {
    .wrapper {
      border: none;
      box-shadow: none;
    }

    .component {
      margin: 0;
      min-height: 20px;
      --padding-top: 4px;
      --padding-bottom: 0;
      color: var(--mipro-color-dark);
    }

    ion-button {
      display: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input::placeholder {
    text-transform: none;
  }
}

.toUpperCase {
  text-transform: uppercase;
}

.charCounter {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: var(--mipro-color-warning);
}

.helperWrapper {
  margin-top: 8px;
}
