@import 'theme/mixins';

.employeeActionRow {
  --min-height: 0px;
  --padding-top: 16px;
  --padding-bottom: 16px;
  .employeeWrapper {
    display: flex;
    align-items: baseline;
    gap: 8px;
    width: 100%;
    .employeeSpec {
      display: flex;
      flex-direction: column;
      width: 100%;
      .firstRow {
        display: flex;
        justify-content: space-between;
      }
      .secondRow {
        display: flex;
        align-items: center;
        text-transform: uppercase;

        .title {
          @include textEllipsis(1);
        }

        .separator {
          display: inline-block;
          padding: 0 4px;
        }
      }
    }
    .aging{
      margin-left: auto;
    }

  }
}



