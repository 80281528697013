.content-default {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-default-color);
}

.disabled {
  color: var(--ion-color-secondary);
}

.title-screen-section {
  --text-default-color: var(--ion-text-color-dark);
  @extend .content-default;
  font-family: var(--ion-font-family-alt);
  font-size: 20px;
  font-weight: 700;
  font-stretch: condensed;
}

.title-card-red {
  --text-default-color: var(--ion-text-color-red);
  @extend .content-default;
  font-family: var(--ion-font-family-alt);
  font-size: 22px;
  font-weight: 700;
  font-stretch: condensed;
}

.underlined-title-section {
  --text-default-color: var(--ion-text-color-dark);
  @extend .content-default;
  font-family: var(--ion-font-family);
  font-size: 18px;
  font-style: italic;
  text-transform: capitalize !important;
}

.title-action-card {
  --text-default-color: var(--ion-text-color-dark);
  @extend .content-default;
  font-family: var(--ion-font-family-alt);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  font-stretch: condensed;
}

.title-info-card {
  --text-default-color: var(--ion-text-color-dark);
  @extend .content-default;
  font-weight: 600;
}

.title-info-card-read {
  @extend .content-default, .title-info-card;
  font-weight: 400;
}

.title-info-card-activity {
  @extend .content-default, .title-info-card;
  --text-default-color: var(--ion-text-color-light);
}

.content-heavy {
  @extend .content-default;
  font-weight: 600;
}

.content-small {
  --text-default-color: var(--ion-text-color-light);
  @extend .content-default;
  font-size: 13px;
  line-height: 18px;
}

.content-small-heavy {
  --text-default-color: var(--ion-text-color-light);
  @extend .content-default;
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
}

.content-small-unread {
  @extend .content-default;
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
}

.label-header {
  @extend .content-default;
  font-size: 13px;
  font-weight: 600;
}

.label-header-micro {
  --text-default-color: var(--ion-color-medium);
  @extend .content-default;
  font-size: 11px;
}

.label-micro {
  @extend .content-default;
  font-size: var(--label-micro-text-size);
  line-height: 16px;
}

.label-micro-unread {
  @extend .content-default;
  --text-default-color: var(--ion-text-color-dark);
  font-size: var(--label-micro-text-size);
  line-height: 16px;
  font-weight: 600;
}

.label-micro-italic {
  @extend .content-default;
  font-size: var(--label-micro-text-size);
  line-height: 16px;
  font-style: italic;
}

.content-smaller {
  --text-default-color: var(--ion-text-color-light);
  @extend .content-default;
  font-size: 12px;
  line-height: 17px;
}

.announcement-subtitle {
  --text-default-color: var(--color-annoucement-subtitle);
  @extend .content-default;
  font-size: 14px;
  line-height: 12px;
}

.mipro-h1-headline {
  --text-default-color: var(--mipro-color-dark-text);
  @extend .content-default;
  font-family: var(--ion-font-family-alt);
  font-size: 25px;
  font-weight: bold;
  font-stretch: condensed;
  text-transform: uppercase;
}

.mipro-h2-headline {
  --text-default-color: var(--ion-color-light);
  @extend .content-default;
  font-family: var(--ion-font-family-alt);
  font-size: 20px;
  font-weight: bold;
  font-stretch: condensed;
  text-transform: uppercase;
}

@mixin mipro-h3-headline {
  --text-default-color: var(--mipro-color-dark-text);
  @extend .content-default;
  font-family: var(--ion-font-family-alt);
  font-style: normal;
  font-stretch: condensed;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  text-transform: uppercase;
}

.mipro-h3-headline {
  @include mipro-h3-headline();
}

.mipro-h4-headline {
  --text-default-color: var(--mipro-color-dark-text);
  @extend .content-default;
  font-size: 16px;
  font-family: var(--ion-font-family);
  font-weight: bold;
  line-height: 120%;
}

.mipro-h6-headline {
  --text-default-color: var(--mipro-color-dark-text);
  @extend .content-default;
  font-size: 12px;
  font-family: var(--ion-font-family);
  line-height: 24px;
  font-weight: 500;
}

.mipro-body-copy {
  font-family: var(--ion-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  --text-default-color: var(--mipro-color-dark-text);
}

.mipro-body-copy-bold {
  @extend .mipro-body-copy;
  font-weight: 700;
}

.mipro-report-info {
  @extend .content-smaller;
  font-weight: 400;
  font-size: 14px;
}

.mipro-product-headline {
  --text-default-color: var(--mipro-color-dark-text);
  @extend .content-default;
  font-family: var(--ion-font-family-alt);
  font-style: normal;
  font-stretch: condensed;
  font-weight: 700;
  font-size: 18px;
}

.list-item-overlay {
  color: var(--ion-text-color-light);
  font-family: var(--ion-font-family);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.list-item-title {
  color: var(--mipro-color-dark-text);
  font-family: var(--ion-font-family-alt);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}
.list-item-subtitle {
  color: var(--mipro-color-dark-text);
  font-family: var(--ion-font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
.list-item-secondaryText {
  color: var(--mipro-color-dark-text);
  font-family: var(--ion-font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14.4px;
}
