@import 'theme/mixins';

.updatingCard {
  background: var(--ion-color-medium);
  pointer-events: none;
}

.topStatus {
  font-size: 11px;
  margin: 8px 16px 0 16px;
  ion-col {
    padding-left: 0;
    padding-right: 0;
  }
}

.loader {
  margin-top: 16px;
}

.salePlaysCompleted {
  margin: 16px;
  ion-col {
    border-color: var(--ion-color-success) !important;
  }
  svg {
    color: var(--ion-color-success) !important;
  }
  span {
    color: var(--ion-color-success) !important;
  }
}

.noSalesPlays {
  margin: 16px;
  ion-col {
    border-color: #CACACA !important;
  }
  svg {
    color: #CACACA , !important;
  }
  span {
    color: var(--color-gray) !important;
    font-weight: bold;
    line-height: 20px;
    padding-top: 4px;
  }
}


.warningMessage {
  margin: 16px;
}

.titleLine {
  margin-left: 16px;
}
