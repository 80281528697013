.overlay {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);;
  backdrop-filter: blur(3px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  .iconRow {
    align-self: center;
    justify-self: center;
    padding-block-start: 24px;
    .icon {
      font-size: 38px;
      color: var(--ion-color-primary);
    }
  }
  .msgRow {
    flex-wrap: wrap;
    width: 65%;
    justify-content: center;
    padding-block-end: 36px;
  }
  .overlayHeaderText {
    font-family: var(--ion-font-family);
    font-weight: bold;
    font-size: 15px;
  }
  .overlayDetailText {
    font-family: var(--ion-font-family);
    font-size: 14px;
  }
  .dismissButton {
    width: 175px;
    height: 40px;
    --border-radius: 4px;
    --border-width: 2px;
    --border-color: var(--ion-color-primary);
  }
}


.infoBar {
  background-color: var(--ion-color-primary);
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
  height: 36px;
  .infoBarContent {
    display: flex;
    align-items: center;
  }
  .label {
    margin-left: 8px;
    --text-default-color: var(--ion-color-primary-contrast);
    font-size: 13px;
    font-weight: bold;
    font-family: var(--ion-font-family);
  }
  .icon {
    font-size: 18px;
    color: var(--ion-color-primary-contrast);
  }
  .closeButton {
    color: var(--ion-color-primary-contrast);
    opacity: 0.5;
  }
}

.reportBanner{
  margin: -16px -16px 16px;
}
