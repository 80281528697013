.insetList {
  border-bottom: 1px solid var(--ion-border-color);
  td {
    padding: 8px 16px;
  }
  td:first-child {
    max-width: 50%;
  }

  &:last-child {
    border: none;
  }
}
.table {
  width: 100%;
}
