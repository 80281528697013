@import 'theme/mixins';

%iconLink {
  &.iconLink {
    --border-radius: 8px;
    --padding-start: 0px;
    --padding-top: 0px;
    --padding-right: 0px;
    --padding-bottom: 0px;
    --min-height: auto;

    &::part(native) {
      border: 1px solid var(--ion-color-primary);
    }

    .start {
      margin-top: 0px;
      margin-bottom: 0px;
      margin-inline-end: 16px;
      background-color: var(--ion-color-primary);
      padding: 16px;

      svg {
        color: var(--ion-color-light);
      }
    }

    .textEllipse {
      font-weight: 500;
    }
  }
}

%banner {
  &.banner {
    --background: var(--ion-color-primary);

    &::part(native) {
      border: none;
    }

    .textEllipse {
      color: var(--mipro-color-white);
    }

    .endIcon {
      color: var(--mipro-color-white);
    }
  }
}

.navigationRow {
  --accent-color: var(--ion-color-primary);
  --min-height: 52px;
  --background: var(--ion-color-light);

  &::part(native) {
    border-bottom: 1px solid var(--mipro-color-light-gray);
  }

  &::part(detail-icon) {
    color: var(--accent-color);
    opacity: 1;
    font-size: 1.6rem;
  }

  .textEllipse {
    @include textEllipsis(2);
  }

  .logo {
    width: 20px;
  }

  .endIcon {
    color: var(--accent-color);
  }

  .end {
    margin-inline-start: 16px;
  }

  .start {
    margin-inline-end: 8px;
  }

  @extend %iconLink;
  @extend %banner;
}
