.priceLabel {
  ion-button {
    text-decoration: none;
  }

  svg {
    font-size: 10px;
    margin-right: 2px;
    margin-bottom: 1px;
  }

  span {
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }

  &.priceOverride {
    svg {
      color: var(--mipro-color-light-red) !important;
    }
    span{
      color: var(--mipro-color-light-red) !important;
    }
  }

  &.priceUpdate {
    svg {
      color: var(--mipro-color-dark-gray) !important;
    }
    span{
      color: var(--mipro-color-dark-gray) !important;
    }
  }
}
