.content {
  --background: var(--ion-color-primary-contrast);

  .isDeletedLabel {
    white-space: normal;
    display: flex;
    flex-flow: nowrap;
    align-items: flex-start;

    svg {
      width: 16px;
      height: 16px;
      margin: 0;
      margin-inline-end: 8px;
    }

    span,
    svg {
      color: var(--mipro-color-dark-orange);
    }

    span {
      font-family: Inter;
      font-size: 14px;
      font-weight: 300;
    }
  }

  .contentTitle {
    padding: 16px;
  }

  .options {
    width: 100px;
  }

  .deleteOption {
    background: var(--mipro-color-light-red);
    svg, span {
      color: var(--ion-background-color);
    }
  }

  .loader {
    margin-top: 20px;
  }

  .warngMessage {
    margin: 0 16px;
    .warningBtn {
      margin-block-start: 16px;
      --background: var(--mipro-color-primary);
      width: 100%;
    }
  }
}

.confirmModal {
  .modalContent {
    --padding-top: 20px;
  }

  .btnWrapper {
    margin-top: 20px;

    .fullWidth {
      width: 100%;
    }
  }
}


