.legendWrapper {
  margin-bottom: 16px;

  .segmentTabs {
    width: 200px;
    border-radius: 3px;
    --text-color: var(--ion-color-medium);
    --background: var(--mipro-color-primary);
    ion-segment-button {
      height: 32px;

      &:first-child {
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
      }

      &:last-child {
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
      }

      span {
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }

  .legendRow {
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;

    .legendText {
      display: flex;
      align-items: center;

      .legendIcon {
        width: 8px;
        height: 8px;
        margin-inline-end: 4px;
      }

      &:first-child {
        margin-inline-end: 8px;
      }
    }
  }
}
