.content {
  --background: var(--mipro-color-white);
}

.formWrapper,
.reviewWrapper {
  padding: 0 16px 16px;
}

.formField {
  margin-top: 16px;
}

.attachmentWrapper {
  margin-top: 16px;

  ion-row {
    margin-top: 8px;
  }
}

.valueSkeleton {
  width: 50%;
  height: 26px;
  margin: 0;
}

.message {
  margin: 0;
}
