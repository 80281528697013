.content {
  --padding-top: 0px;
  --padding-bottom: 16px;
}

.titleRow {
  padding: 16px 16px 0;

  span {
    margin-bottom: 8px;
    display: block;
    text-transform: uppercase;
  }
}

.accordion {
  margin-top: 16px;

  .accordion,
  .accordionSection {
    margin: 0;
  }

  .accordionHeader {
    border-bottom: 1px solid var(--mipro-color-medium-gray);
  }
}

.Territories {
  .input {
    margin: 0px 15px 12px 22px;
    --searchbar-border-color: var(--ion-text-color);
    background: var(--mipro-color-white);
  }
}

.warning {
  padding: 0px 16px;
}

.locationBadgeRow {
  background: var(--mipro-color-white);
  padding: 16px;

  .badge {
    background: var(--mipro-color-pale-blue);
    width: fit-content;
    padding: 14px;
  }

  .badgeText {
    color: var(--mipro-color-dark-text);
    font-size: 12px;
    font-weight: 600;
    line-height: 1.15;
    text-align: center;
  }
}

.ionItemAccordion {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.ionItem {
  font-weight: 600;
  --min-height: 55px;

  .locName,
  &.branchItem .locName {
    font-weight: 600;
  }

  .button {
    width: 40px;
    text-decoration: none;

    span {
      text-transform: uppercase;
    }
  }

  ion-button::part(native):focus {
    text-decoration: none;
  }
}

.leafNode .locName {
  font-weight: 400;
}

.rootNode,
.leafNode {
  [data-testid='header-arrow'] {
    display: none;
  }
}
