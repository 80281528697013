.content {
  --background: var(--mipro-color-background);

  .wrapper {
    padding: 24px 0;
    background: var(--mipro-color-white);
  }

  .ocnTitle {
    text-align: start;
    display: block;
    margin-top: -6px;
  }

  .productName {
    margin: 0 16px;
    padding: 24px 0 16px;
    border-bottom: 1px solid var(--mipro-color-light-gray);
  }
}

.formLoader {
  position: fixed;
}

.loader,
.message {
  padding: 16px;
}

.formContent {
  padding: 16px 16px 0;

  .input {
    margin-top: 16px;

    ion-row {
      border-radius: 6px;
    }

    ion-col {
      padding: 0px;
    }
  }

  .minoRow {
    gap: 4px;
    margin-top: 16px;

    span {
      font-size: 14px;
      line-height: 20px;
      color: var(--mipro-color-dark);
    }

    .label {
      font-weight: 500;
    }
  }

  .checkbox {
    margin-top: 16px;
    // TODO add this to common checkbox component
    margin-inline-end: 10px;
    --border-radius: 5px;
    --border-width: 1.5px;
    --border-color: var(--mipro-color-medium-gray);
  }

  .dateInput {
    margin-top: 16px;

    // TODO add this to common dateInput component
    ion-row {
      border-radius: 6px;
      border: 1px solid var(--mipro-color-dark);
    }
  }

  .formRow {
    gap: 16px;
    margin-top: 16px;

    ion-col {
      padding: 0;
    }
  }
}
