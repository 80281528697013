.discardModal {
  .discardTitle {
    width: 60%;
    font-size: 24px;
    line-height: 110%;
  }

  .discardContent {
    --padding-start: 27px;
    --padding-end: 27px;
  }

  .msgRow {
    margin-top: 16px;
  }

  .discardMsg {
    line-height: 21px;
    font-weight: 500;
    --text-default-color: var(--mipro-color-black);
  }

  .discardButton {
    margin-top: 16px;
    width: 100%;
    --border-radius: 6px;
  }

  .primaryButton {
    --background: var(--mipro-color-primary);
  }
}
