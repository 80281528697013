@import 'theme/mixins';

.modal {
  --padding-top: 0px;
  --padding-bottom: 16px;
  --background: var(--mipro-color-background);
}

.modalHeader {
  padding-bottom: 0px;
}

.modalWrapper {
  ion-toolbar {
    padding-bottom: 0px !important;
  }

  .fa-plus-circle {
    height: 24px !important;
    width: 24px !important;
  }

  .sendLoader {
    ion-spinner {
      color: var(--ion-color-secondary-contrast);
    }
  }
}

.titleRow {
  margin-bottom: 8px;

  .addRecipientButton {
    position: relative;
    margin-inline-start: 8px;
    @include increaseTouchTargetSize();

    .plusCircle {
      height: 24px;
      width: 24px;
    }
  }
}

.section {
  background-color: var(--mipro-color-white);
  padding: 16px 16px 16px;

  .title {
    top: 0;
    text-transform: uppercase;
    --background: var(--ion-item-background);
  }
}

.moreBtn {
  margin-bottom: 16px;
  border-bottom: 0px;
  border: 1px solid var(--ion-border-color);

  span {
    color: var(--ion-color-primary);
    font-weight: 600;
    font-size: 14px;
  }
}

.buttonsWrapper {
  padding: 16px;
  border-top: 1px solid var(--ion-border-color);

  ion-button {
    flex: 1;
  }

  .loader {
    ion-spinner {
      width: 16px;
      height: 16px;
    }
  }

  ion-button:first-child {
    margin-inline-end: 16px;
  }
}

.searchWrapper {
  margin-top: 16px;
}

.searchbar {
  --searchbar-border-color: var(--ion-text-color);
  margin-bottom: 16px;

  ion-row {
    border-radius: 6px;
  }
}

.employeesList {
  margin: 16px -16px 0px;

  ion-item {
    --background: var(--ion-item-background);
  }
}

.warningMessage {
  margin: 16px;
}

.selectedEmployees {
  margin: 0px -16px;
  padding: 16px;
  background: var(--ion-item-background);
  border: 1px solid var(--ion-border-color);
  border-left: 0px;
  border-right: 0px;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
}

.recipientsWrapperInverted,
.recipientsWrapper {
  display: flex;
  gap: 8px;

  .contentMessage {
    margin-inline-end: 8px;
  }

  .recipientButtonInverted,
  .recipientButton {
    padding: 4px 8px 4px 16px;
    border-radius: var(--ion-avatar-size);
    align-items: center;
    background: var(--ion-item-background);

    ion-button {
      position: relative;
      margin-inline-start: 8px;
      @include increaseTouchTargetSize();
    }

    &.withoutButton {
      padding: 0 16px;
    }
  }

  .recipientButtonInverted {
    background: var(--mipro-color-pale-blue);
    padding: 4px 8px;
    flex-wrap: nowrap;
    flex: 1 0 auto;
  }

  .recipientButtonInvertedIcon {
    height: 14px;
    width: 14px;
    color: var(----mipro-color-dark-text);
  }
}

.employeeName {
  font-weight: 600;
}

.employeeName {
  font-size: 12px;
}

.hide {
  display: none;
}
