@import 'theme/mixins';

.container {
  margin-top: 4px;

  .text {
    color: var(--ion-color-medium);
    display: block;
  }

  .link {
    position: relative;
    @include increaseTouchTargetSize(50%, 0, 50%);
    vertical-align: middle;

    svg {
      font-size: 11px;
    }
  }

  .linkText {
    color: var(--mipro-color-primary);
    font-weight: 600;
  }

  .pick12Icon {
    top: 2px !important;
  }
}
