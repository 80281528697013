.reviewWrapper {
  padding: 0 16px 16px;

  .item {
    --padding-start: 6px;

    .label {
      .valueTextWrapper {
        margin-top: 6px;
        white-space: pre-wrap;

        .valueText {
          color: var(--mipro-color-dark-text);
        }
      }
    }
  }
}
