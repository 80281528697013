.section {
  margin-top: 16px;

  ion-item {
    margin: 0 -16px;
  }
}

.warningMessage {
  &.fullscreenMessage {
    margin: 0 44px;
    height: 100%;

    ion-col {
      border: none;
      padding: 0;
      align-self: center;
    }
  }
}

.loader {
  margin-top: 16px;
}
