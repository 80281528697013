@import 'theme/mixins';

.filterCard {
  margin: 0;

  .formItem {
    --min-height: 26px;
    --padding-top: 0;
    --padding-bottom: 16px;
    --padding-start: 0;
    --padding-end: 0;
    --border-color: transparent;
    --background: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 1;
    --background-focused: transparent;
    --background-focused-opacity: 1;
    --background-hover: transparent;
    --background-hover-opacity: 1;

    &:last-child {
      --padding-bottom: 0;
    }

    ion-label {
      margin: 0;
    }

    .checkbox {
      margin: 0;
      margin-inline-end: 16px;
    }
  }



  .headerButton {
    width: 100%;
    padding: 16px;

    .headerRow {
      width: 100%;
    }

    span {
      text-transform: uppercase;
    }

    svg {
      margin-inline-end: 16px;
      color: var(--ion-color-primary);
    }
  }

  .content {
    padding: 0 16px 16px;
    .radioItem {
      margin-top: 16px;
    }
  }
}

.dateInput {
  margin-bottom: 16px;

  &:last-child {
    margin: 0;
  }
}

.filterButtonsWrapper {
  padding: 0 16px 8px;

  .filterButton {
    height: 32px;
    margin: 0 8px 8px 0;
    padding: 0 8px 0 16px;
    border-radius: var(--ion-avatar-size);
    align-items: center;
    background: var(--ion-background-color);

    ion-button {
      margin-inline-start: 8px;
      position: relative;
      @include increaseTouchTargetSize();

      svg {
        color: var(--ion-color-medium);
      }
    }
  }
}
