.statusTitle {
  line-height: 1.5;
  display: block;
  text-transform: uppercase;
  color: var(--ion-text-color-dark);
}

.subtitle {
  width: 100%;
  padding-bottom: 4px;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  vertical-align: bottom;
  margin-left: -2px;
}


.card {
 box-shadow: none;
  padding: 13px 14px 0px 18px;
  background-color: var(--ion-text-color-reversed);
  margin-left: -2px;
  border-radius: 8px;

}

.featured {
  align-self: flex-end;
}

.date {
  @extend .featured;
  font-size: 11px;
}

.featureDescription {
  min-width:220px;
  padding-right: 3px;
}

.description {
  @extend .featureDescription;
  min-height: 18px;
  margin: 2px 0 0 8px;
  text-align: left;
  overflow: hidden;
  color: var(--ion-color-light-contrast);
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  .title {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
  }
}
.bottomDescription {
  margin-left: -8px;
  height: 55px;
}


