.filterWrapper {
  background: var(--color-gray);
  padding: 16px 16px 16px;
  &.customerLessFilter {
    background: transparent;
    padding: 8px 16px 16px;
  }
}

.checkboxWrapper {
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .checkbox {
    --border-color: var(--ion-color-primary-contrast);
    --border-radius: 4px;
    --checkbox-background: transparent;

    span {
      color: var(--ion-color-primary-contrast);
      text-transform: capitalize;
    }
  }
}

.advancedSearchWrapper {
  .filterButton {
    padding: 0 12px;
    border-radius: 40px;
    height: 35px;
    border: 1px solid var(--ion-color-medium);
    position: relative;

    svg,
    span {
      font-size: 12px;
      color: var(--ion-color-primary-contrast);
    }

    &:hover svg {
      color: var(--ion-color-primary-contrast);
    }
  }

  .hasFilters {
    background: var(--mipro-color-pale-blue);
    border: none;

    svg {
      color: var(--mipro-color-dark-text);
    }
    &:hover svg {
      color: var(--mipro-color-dark-text);
    }

    span {
      border-radius: 50%;
      height: 14px;
      width: 14px;
      background: var(--mipro-color-primary);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.zCodeBtn {
  margin-top: 16px;
  ion-button {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.list {
  background: var(--ion-background-color, #f5f5f5);
  min-height: 250px;
}

.spinner {
  position: fixed;
}
