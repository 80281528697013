.detailCard {
  background-color: var(--mipro-color-white);
  padding: 16px 0px;

  .textBlock {
    display: block;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .insetList {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .insetCustomValue {
    display: flex;

    span {
      margin-right: auto;
    }
  }
}

.shopModal {
  --padding-top: 16px !important;

  .modalText {
    svg {
      color: var(--ion-color-primary);
    }
    span {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }

  .spacer {
    margin-top: 16px;
  }

  .address {
    display: block;

    ion-button {
      width: 100%;
      border-bottom: 1px solid var(--mipro-color-light-gray);
      padding: 16px;

      svg {
        color: var(--ion-color-primary);
      }

      span {
        color: var(--ion-color-primary);
        font-weight: 500;
      }

      &::part(native) {
        width: auto;
      }
    }
  }
}

.linkButton {
  width: 100%;
}

.ocnIndicator {
  padding: 8px 0;
}
