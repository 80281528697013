.loader {
  padding: 16px;
}
.infoBar {
  padding: 0 16px;
  ion-col {
    padding-left: 16px;
    padding-right: 16px;
  }
  .message {
    color: var(--ion-text-color-light);
    font-size: 13px;
    line-height: 18px;
  }
}
