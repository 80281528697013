.documentCard {
  margin: 0;
  padding: 8px 16px;
  box-shadow: none;
  border-bottom: 1px solid var(--ion-border-color);

  &.disabledCard {
    background: var(--mipro-color-disabled);
    opacity: 0.8;
  }

  &:hover {
    cursor: pointer;
  }

  ion-row {
    .info {
      flex: 1;
    }

    .actions {
      display: flex;
      align-items: center;

      .envelope {
        padding-right: 8px;

        &.disabledButton {
          opacity: 1 !important;

          svg {
            color: var(--mipro-color-disabled-shade);
          }
        }
      }
    }
  }

  .titleRow {
    padding: 0;
    display: flex;
    align-items: center;

    .titleWrap {
      text-transform: uppercase;
    }

    .needSyncBadge,
    .noAccessBadge {
      border-radius: 10px;
      margin-inline-start: 8px;
      padding: 0 8px;
      background: var(--ion-color-primary);
      color: var(--ion-text-color-reversed);
    }
    .noAccessBadge {
      background: var(--ion-color-warning);
    }
  }

  .subtitleRow {
    color: var(--ion-color-medium);
    align-items: center;

    ion-col {
      padding: 0;
    }
  }

  .status {
    padding-left: 5px;
  }

  .documentDetail {
    padding-right: 8px;
  }
}
