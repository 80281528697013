@import 'theme/mixins';

.activityPage {
  .activitiesTabs {
    .selectedSegmentTab {
      span {
        --text-default-color: var(--mipro-color-white) !important;
      }
    }
    span {
      --text-default-color: var(--mipro-color-medium) !important;
    }
  }
  .cancelButton,
  .actionButton,
  .selectAllButton {
    --ion-text-color: var(--ion-text-color-reversed);
  }

  .text {
    --text-default-color: var(--ion-text-color-reversed);
  }

  .list > :nth-child(1) {
    z-index: 2 !important;
  }

  .header {
    &.contentHeader {
      ion-title {
        opacity: 1 !important;
        span {
          display: flex;
        }
      }
      ion-buttons:last-child {
        position: relative;
        right: auto;
        top: auto;
      }
    }
    .cancelButton {
      margin-inline-end: 8px;
      height: 36px;
    }
    .toolbar {
      display: flex;
      justify-content: space-between;
      height: 42px !important;
      --min-height: 30px !important;
      padding-bottom: 16px;
      margin: 0 -16px;

      &.multiSelectToolbar {
        padding-inline-start: 22px;
        padding-inline-end: 16px;
      }

      div {
        .deleteCont {
          margin-right: 8px;
          line-height: 0.5rem;
          .selectAllButton,
          .actionButton {
            position: relative;
            @include increaseTouchTargetSize(50%, 0, 50%);
            --border-radius: 0;
            --background: transparent;
            --background-activated: transparent;
            --background-focused: transparent;
            --background-hover: transparent;
            svg {
              color: var(--ion-color-primary-contrast) !important;
            }
          }
        }
      }
      .tabsRow {
        width: 100%;

        ion-segment {
          width: 100%;
          display: flex;

          ion-segment-button {
            flex: 1;
          }
        }
      }
    }
  }
}

.multiSelectHeader {
  opacity: 0.8;
  .headerEyebrowBack {
    visibility: hidden;
  }
}

.headerActions {
  margin-inline-end: 8px;
  svg {
    color: var(--mipro-color-white) !important;
  }
}

.hiddenTab {
  display: none;
}

.filter {
  span {
    color: var(--mipro-color-dark-text) !important;
  }
  svg {
    color: var(--mipro-color-dark-text) !important;
  }
}
