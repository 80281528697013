.content {
  --background: var(--ion-color-primary-contrast);

  .warngMessage {
    margin: 0 16px;
  }

  .loader {
    margin-top: 20px;
  }

  .contentTitle {
    padding: 16px 16px 4px;

    .col {
      padding-inline-start: 0px;
    }
  }

  .descWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--mipro-color-dark-text);
  }
}

.headerWrapper {
  margin-bottom: 0px;
  margin-top: 12px;
}

.nameText {
  padding-inline-start: 4px;
}
