.pick12LeaderBoardModal {
  --height: 500px;
  --border-radius: 4px;
  --ion-backdrop-color: var(--ion-color-light-contrast);
  --backdrop-opacity: 0.7;
  --background: transparent;
  ion-content {
    --background: transparent;
  }
  .swiperPd {
    padding-top: 20px;
    padding-bottom: 20px;
    background: var(--ion-color-light);
  }
  .nameWrapper {
    width: 100%;
    .name {
      text-transform: uppercase;
      font-size: 32px;
      font-weight: bolder;
      font-family: var(--ion-font-family-alt);
    }
  }
  .firstName {
   padding-top: 10px;
  }
  .secondName {
    padding-top: 7px;
  }
  .loc {
    width: 100%;
    padding-top: 18px;
    .miLocText {
      text-transform: uppercase;
      font-size: 20px;
      color: var(--ion-text-color-light);
      font-weight: bolder;
      font-family: var(--ion-font-family-alt);
    }
  }
  .circle {
    width: 100px;
    height: 102px;
    border: 4px solid var(--ion-color-secondary-shade);
    border-radius: 50%;
    font-size: 48px;
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .detailCard {
    width: 100%;
    margin-top: 12px;
    ion-item {
      color : var(--ion-text-color-dark);
      font-family: var(--ion-font-family);
      font-size: 14px;
      --min-height: 34px;
      --padding-start: 4px;
      --inner-padding-end: 4px;
      ion-label{
        text-overflow:unset !important;
        overflow: inherit !important;
        margin-inline: 0px;
      }
      ion-label[slot="start"] {
        font-weight: 500;
      }
      ion-label[slot="end"] {
        font-weight: 400;
      }
    }
  }

  .customerList {
    width: 100%;
    ion-item {
      color : var(--ion-text-color-dark);
      font-family: var(--ion-font-family);
      --min-height: 26px;
      --padding-start: 12px;
      --inner-padding-end: 12px;
      ion-label{
        margin-inline: 0px;
      }
      ion-label[slot="start"]{
        flex: 1 1 25%;
      }
    }
    ion-item:not([slot="header"]):nth-child(odd) {
      --background: transparent;
    }
    ion-item:nth-child(even) {
      --background: #F6F6F6;
    }
    ion-item[slot="header"] {
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 4px;
    }
    ion-item:not([slot="header"]) {
      font-weight: 500;
      font-size: 14px;
    }
    .inActive {
      color: #696969;
    }
  }
}


.slideOverride {
  width: 325px;
  --swiper-pagination-bullet-size: .5rem;
  height: 100%;
  --bullet-background-active: #595959;
  --bullet-background: #ADADAD;
}


