.ionPageItem {
  --background: var(--ion-background-color);
  --inner-padding-top: 18px;
  --inner-padding-bottom: 24px;
  .disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}

.ionToggle {
  position: absolute;
  height: 24px;
  width: 41px;
  top: 8px;
  right: 0;
  --handle-width: 18px;
  padding-right: 0;

  &::part(native) {
    background-color: #0aa840;
  }
  &::part(track) {
    height: 24px;
    width: 41px;
    /* Required for iOS handle to overflow the height of the track */
    overflow: visible;
  }
}
