.appVersion {
  width: 100%;
  display: block;
  white-space: pre-line;
  --text-default-color: var(--ion-text-color-light);
  --label-micro-text-size: 12px;
}

.appVersionCont {
  padding: 16px;
}
