.modal {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --background: var(--ion-background-color);
}

.header {
  display: flex;
  padding: 16px;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--ion-safe-area-top);
}

.filterButton {
  width: auto !important;
  height: auto !important;
  padding: 0 16px;
  background: linear-gradient(to top, #282828, #313131);
  border: 1px solid var(--ion-text-color-reversed);
  opacity: 0.9;

  &.appliedFilters {
    background: var(--ion-color-primary);
  }

  .appliedLabel {
    opacity: 0.56;
    margin-inline-start: 4px;
  }

  span,
  svg {
    color: var(--ion-text-color-reversed) !important;
  }

  svg {
    margin-inline-start: 8px;
    font-size: 16px;
  }
}

.filterCard {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.buttonsWrapper {
  padding: 16px;
  border-top: 1px solid var(--ion-border-color);

  ion-button {
    flex: 1;
  }

  ion-button:first-child {
    margin-inline-end: 16px;
  }
}
