.supInfo {
  background-color: var(--ion-background-color);
  --padding-bottom: 16px;
}

.infoWrapper {
  display: grid;
  margin: 16px;

  span {
    white-space: pre-wrap;
    flex: 1;
    text-align: left;
  }
}

.loader {
  margin-top: 16px;
}

.warningMessage {
  margin: 16px;
}

.actionRow {
  border-top: 1px solid var(--ion-border-color);
}
