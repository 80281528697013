@import 'theme/mixins';

.card {
  background: var(--ion-background-color);

  .container {
    width: 100%;
    background: var(--ion-background-color);

    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .icon {
        font-size: 11px;
        margin-right: 5px;
        color: var(--ion-color-medium);

        &.rate {
          font-size: 13px;
          margin-inline-start: 8px;
        }

        &.positive {
          color: var(--ion-color-success);
        }

        &.negative {
          color: var(--ion-color-danger);
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;

      .buttonRow {
        margin-top: 8px;

        .spaceFiller {
          flex: 1;
        }
      }

      .editText {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        color: var(--ion-color-primary);
      }
    }
  }

  .editButton {
    position: relative;
    @include increaseTouchTargetSize();
  }
}

.userName {
  text-transform: capitalize;
}
