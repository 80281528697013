.content {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 16px;
  --padding-end: 16px;
}

.button {
  margin-bottom: 16px;
  width: 100%;
}

.toggle {
  --background: none;
  --inner-padding-bottom: 0;
  --inner-padding-top: 0;
  margin-bottom: 16px;
  --padding-start: 0;

  .toggleWrapper {
    display: flex;
    align-items: center;
  }

  ion-toggle {
    order: -1;
    position: unset;
    padding: 0 16px 0 0;
  }
}

.discardModal {
  .discardTitle {
    width: 60%;
    font-size: 24px;
    line-height: 110%;
  }

  .discardContent {
    --padding-start: 27px;
    --padding-end: 27px;
  }

  .discardMsg {
    display: block;
    margin-top: 16px;
    line-height: 21px;
    --text-default-color: var(--mipro-color-black);
  }

  ion-button {
    margin-top: 16px;
    width: 100%;
  }
}

.dbActions {
  position: relative;
  align-items: center;
  margin-bottom: 16px;
  flex-flow: nowrap;

  .label {
    width: 120px;
  }

  .loader {
    top: -4px;
    left: -8px;
    right: -8px;
    bottom: -4px;
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1;
    border-radius: 6px;
    background: var(--ion-backdrop-color);
  }

  ion-button {
    flex: 1;
  }

  .downloadButton {
    margin: 0 16px;
  }

  .deleteButton {
    background: var(--ion-color-danger);
    --background: var(--ion-color-danger);
  }
}
