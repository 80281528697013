.reasonWrapper {
  display: flex;
  flex-direction: column;

  .reasonText {
    font-weight: 400;
    text-align: start;
    white-space: break-spaces;
  }

  .reasonError {
    color: var(--ion-color-danger);
  }
}

.dcAvailability {
  width: 100%;
  margin: 4px 0;

  ion-row {
    padding: 4px;

    span {
      flex: 1;
    }

    &:nth-child(odd) {
      background: var(--ion-row-background);
    }
  }
}

.content {
  .priceOverrideData {
    margin: 16px 0 8px;

    & > ion-row:nth-child(7) {
      .moreInfoLabel {
        width: 100%;
      }
    }
  }

  .additionalData {
    margin: 16px 0;

    & > ion-row:last-child {
      .moreInfoLabel {
        width: 100%;
      }
    }
  }

  .additionalData,
  .priceOverrideData {
    .moreInfoLabel {
      min-width: 175px;
      width: 50%;
    }
  }
}

.saveButton {
  width: 100%;
}

.message {
  .thumbsUp {
    color: var(--ion-color-success);
  }

  .thumbsDown {
    color: var(--ion-color-danger);
  }
}

.loader {
  margin-top: 16px;
  padding-bottom: 32px;
}

.feedbackButton {
  width: 200px;
  margin-top: 16px;
}
