.datePicker {
  --padding-top: 32px;
  --padding-bottom: 0px;
  --padding-start: 0;
  --padding-end: 0;

  .datePickerContent {
    --padding-start: 0px;
    --padding-end: 0px;
    --padding-top: 16px;
  }

  &::part(handle) {
    width: 50px;
    background: var(--color-help-modal-handle);
  }

  &.dateTimePicker {
    --padding-bottom: 32px;
  }

  ion-select {
    font-family: var(--ion-font-family), SansSerif, serif;
    width: 20%;
  }

}

.DateToolbarButtons {
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: var(--mipro-color-background);
  border-bottom: 1px solid var(--mipro-color-light-gray);

  span {
    color: var(--mipro-color-dark-text) !important;
  }
}

.day {
  font-weight: 600;
  line-height: 40px;
}

.dayWrapper {
  margin: 4px 0;
}

.selected {
  div {
    border-radius: 50%;
    background: var(--ion-color-primary);
    color: var(--ion-text-color-reversed);
  }
}

.holiday {
  background: #c6c6c6;
}

.businessDay {
  border: 2px solid var(--color-calendar-border);
  border-radius: 50%;
}

.businessDayLeft {
  border: 0;
  border-top: 2px solid var(--color-calendar-border);
  border-left: 2px solid var(--color-calendar-border);
  border-bottom: 2px solid var(--color-calendar-border);
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
}

.businessDayRight {
  border: 0;
  border-top: 2px solid var(--color-calendar-border);
  border-right: 2px solid var(--color-calendar-border);
  border-bottom: 2px solid var(--color-calendar-border);
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
}

.businessDayMiddle {
  border: 0;
  border-top: 2px solid var(--color-calendar-border);
  border-bottom: 2px solid var(--color-calendar-border);
  border-radius: 0;
  &.holiday {
    border-left: 2px solid #c6c6c6;
  }
}

.legend {
  display: flex;
  padding: 0 16px 16px;
  justify-content: center;
}

.legendRow {
  --min-height: unset;
  --padding-top: 4px;
  --padding-bottom: 4px;
  --padding-start: 0;
  --padding-end: 0;
  border: none;

  &:first-child {
    margin-inline-end: 16px;
  }

  ion-button {
    width: 10px;
    height: 10px;
  }

  .legendBusinessDay {
    background: var(--ion-item-background);
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid var(--ion-border-color);
  }

  .legendHoliday {
    background: var(--color-calendar-border);
    border-radius: 50%;
  }
}

.buttons {
  margin: 8px auto;
  padding: 16px 16px 0px 16px;
  text-align: center;
  display: flex;
  border-top: 1px solid var(--ion-border-color);

  .datePickButton {
    flex: 1;
    height: 42px;
    width: 150px;
    border-radius: 4px;
    &:first-child {
      margin-inline-end: 16px;
    }
  }
}

.weekDay {
  font-size: 12px;
  font-weight: 500;
}

.time {
  line-height: 40px;
  height: 40px !important;
  padding: 0 !important;
  font-weight: 600 !important;
}

.react-datepicker-time__input {
  background-color: white;
}

.weekendDay {
  color: var(--ion-color-medium) !important;
}

.timeSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  text-align: center;
  padding: 8px 16px 0px;
  border-top: 1px solid var(--ion-border-color);

  div:first-child {
    display: flex;
    align-items: center;
  }

  .clockIcon {
    width: 14px;
    height: 14px;
    margin-right: 8px;
    color: var(--mipro-color-light-blue);
  }

  .timeButton {
    background-color: var(--mipro-color-background);
    border-radius: 8px;

    span {
      font-weight: 400;
      padding: 8px 16px;
    }
  }
}

.item {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;

  & > div {
    width: 100%;
  }
}

.itemOption {
  width: 150px;
  background: var(--mipro-color-pale-blue);
  color: var(--mipro-color-primary);

  svg {
    position: absolute;
  }

  .prevOption {
    left: 16px;
  }

  .nextOption {
    right: 16px;
  }
}

.qtdCellWrapper {
  padding-block: 16px;

  span {
    display: block;
  }

  .qtdText {
    font-size: 25px;
    font-weight: 700;
    font-stretch: condensed;
    font-family: var(--ion-font-family-alt);
    margin-bottom: 16px;
  }

  .qtdMonths {
    display: flex;
    gap: 16px;
    justify-content: center;

    .notAvailable {
      color: var(--mipro-color-medium-shade) !important;
    }

    span {
      font-weight: 500;
    }
  }
}