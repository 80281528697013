.barCodeButton {
  height: 40px;
  --padding-start: 16px;
  --padding-end: 16px;
}

.portalWrapper {
  position: absolute;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  clip-path: polygon(
    0% 0%,
    0% 100%,
    10% 100%,
    10% 40%,
    90% 40%,
    90% 60%,
    10% 60%,
    10% 100%,
    100% 100%,
    100% 0%
  );

  .header {
    ion-toolbar {
      ion-row {
       justify-content: center;

        span {
          font-size: 25px;
        }
      }
    }
  }

  .scanUsage {
    padding: 16px;
    margin: 16px;
    border-radius: 6px;
    border: 1px solid var(--ion-color-light);

    span {
      width: 100%;
      text-align: center;
      font-size: 14px;
      color: var(--ion-color-light);
    }
  }

  .scanResult {
    span {
      font-size: 28px;
      text-align: center;
      width: 100%;
      color: var(--ion-color-light);
    }
  }

  .footer {
    background: transparent;

    ion-toolbar {
      --background: var(--ion-item-background);
      --padding-top: 16px;
      --padding-bottom: 16px;
      --padding-start: 16px;
      --padding-end: 16px;
    }

    ion-button {
      flex: 1;
    }

    .cancelButton {
      margin-inline-end: 16px;
    }
  }
}

.center {
  position: relative;
  height: inherit;

  .redBox {
    position: absolute;
    top: 0;
    bottom: 32px;
    left: 0;
    right: 0;
    margin: auto 16%;
    height: 13px;
    background: rgba(245, 25, 0, 0.3);
  }
}
