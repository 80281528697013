@mixin textVariants {
  &.success {
    color: var(--mipro-color-medium-green);
  }

  &.alert {
    color: var(--mipro-color-dark-red);
  }
}

@mixin trackVariants {
  &.success {
    border-style: solid;
    border-color: var(--mipro-color-medium-green);
  }

  &.alert {
    border-style: solid;
    border-color: var(--mipro-color-dark-red);
  }
}

.timeline {
  .row {
    display: flex;
    flex-direction: column;

    &:last-child .contentRow .colStart {
      visibility: hidden;
    }

    .titleRow,
    .contentRow {
      display: flex;
    }

    .contentRow .colEnd {
      margin-top: 8px;
    }

    .colTraceAfter {
      min-height: 55px; 
    }

    .colTraceBefore {
      min-height: 22px; 
    }

    .colStart {
      width: 22px;
      display: flex;
      justify-content: center;
    }

    .colEnd {
      margin-left: 8px;
      flex: 1;
    }

    .icon {
      font-size: 22px;
      color: var(--mipro-color-light-gray);

      @include textVariants;
    }

    .title {
      font-weight: 700;
      font-size: 18px;
      font-family: var(--ion-font-family-alt);
      text-transform: uppercase;

      @include textVariants;
    }

    .trace {
      border: 2px dashed var(--mipro-color-light-gray);

      @include trackVariants;
    }

    .insetList {
      ion-button {
        display: flex;
      }

      tr {
        display: flex;
        border: none;
        gap: 4px;
        margin-bottom: 4px;
        
        &:last-child {
          margin-bottom: 0px;
        }
      }

      td {
        display: flex;
        padding: 0px;

        &:last-child {
          span {
            font-weight: 500
          }
        }

        span {
          font-size: 14px;
          font-family: var(--ion-font-family);
          font-weight: 600;
          line-height: normal;
          text-transform: none;
        }
      }
    }
  }
}
