.approvalWrapper {
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 10px;

  svg {
    font-size: 14px;
    margin-right: 4px;
  }
}

.earMode {
  svg {
    color: var(--mipro-color-light-green);

  }
}

.nonEarMode {
  svg {
    color: var(--mipro-color-darker-red);
  }
}
