$small: 32px;
$medium: 72px;


.errWarning {
  color: var(--ion-color-danger);
}

.attachmentCont {
  overflow: visible;
  --min-height: auto;
  --highlight-height: 0;
  --background: transparent;
  --background-activated: transparent;
  --background-activated-opacity: 1;
  --background-focused: transparent;
  --background-focused-opacity: 1;
  --background-hover: transparent;
  --background-hover-opacity: 1;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;

  .labelRow {
    align-items: center;
  }

  .attachmentRow {
    margin-top: 16px;
    align-items: center;
    &.viewSmall {
      margin-top: 0px;
    }
  }

  .sizeExceedError {
    color: var(--ion-color-danger);
    padding-block-start: 8px;
  }

  ion-col {
    --ion-grid-column-padding: 0;
  }

  ion-input {
    height: 40px;
    --padding-top: 11px;
    --padding-bottom: 11px;
    --padding-start: 16px;
    --padding-end: 16px;
    --placeholder-color: var(--ion-color-medium);
    --placeholder-opacity: 1;
  }

  .label {
    line-height: 18px;
    --text-default-color: var(--mipro-dark-text);
  }

  .fileImg {
    width: 80%;
    height: 80%;
    color: var(--mipro-color-dark-blue);
  }

  .attachItemCol {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 3px solid var(--disabled-colors-disabled, #ededed);
    min-width: 100px;
    max-width: 100px;
    width: 100px;
    min-height: 100px;
    max-height: 100px;
    height: 100px;
    gap: 7px;
    margin-inline-end: 15px;
    margin-block-end: 15px;

    &.small {
      box-sizing: content-box;
      padding: 2px;
      min-width: $small;
      max-width: $small;
      width: $small;
      min-height: $small;
      max-height: $small;
      height: $small;
      cursor: pointer;
      &.viewOnly {
        border: none;
        margin-inline-end: 0;
        margin-block-end: 0;
        ion-img {
          &::part(image) {
            border-radius: 0px;
          }
        }
      }
    }

    &.medium {
      box-sizing: content-box;
      padding: 6px;
      min-width: $medium;
      max-width: $medium;
      width: $medium;
      min-height: $medium;
      max-height: $medium;
      height: $medium;
      cursor: pointer;
    }

    &.addColumn {
      border-color: var(--mipro-color-light-gray);
    }

    &.disabledColumn {
      border-color: var(--disabled-colors-disabled, #ededed);
    }

    &.imageCol {
      border-color: transparent;
    }

    &.imageError {
      border-color: var(--ion-color-danger);
    }

    &.previewDisabled {
      box-shadow: 0 2px 12px 0 var(--ion-color-primary),
      0 0 4px 0 var(--ion-color-primary);
    }

    ion-img {
      width: 100%;
      height: 100%;

      &::part(image) {
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  .addImageButton {
    width: 100%;
    height: 100%;
    position: relative;
    --background: var(--mipro-color-light-gray);
    --border-width: 0px;

    &.disabledAdd {
      --background: none;
      opacity: 1;

      svg {
        color: var(--mipro-color-light-gray);
      }
    }

    svg {
      width: 26px;
      height: 26px;
    }
  }

  .removeIcon {
    width: 9px;
    height: 13px;
  }

  .removeButton {
    width: 25px;
    height: 25px;
    background: var(--mipro-color-white);
    border-radius: 50%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -12px;
    right: -12px;
  }
}
