.unbilled {
  .header {
    padding: 8px 0px;
  }
  .customHeader {
    display: flex;
    justify-content: flex-start;
  }
  .pageCustomTitle {
    span {
      color: var(--mipro-color-white);
    }
  }
  .warningMessage {
    padding: 0px 16px;
  }

  .drilldownTable {
    background: var(--mipro-color-white);
    padding-inline-start: 0px !important;
    --background: var(--mipro-color-white);

    .tableTH,
    .tableTD {
      border-right: 1px solid var(--mipro-color-light-gray);
      color: var(--mipro-color-dark-text);
    }

    .tableTH > div {
      min-width: 90px;
    }

    .tableTH:not(:first-child) > div {
      min-width: 130px;
    }

    .tableTD:not(:first-child) > div {
      min-width: 130px;
    }

    .tableTD:first-child > div {
      justify-content: left;
    }


    .icon {
      height: 15px;
      color: var(--ion-color-primary);
    }
  }
  .stickyHeader {
    background: var(--ion-item-background);
    position: sticky;
    top: 0;
    z-index: 5;
  }
  .overlayInfoBar {
    height: 45px;
    margin: 0px -16px;
  }
}
