@import 'theme/mixins';

.title {
  display: block;
  text-transform: uppercase;
  line-height: 24px;
  margin-bottom: 8px;
}

.item {
  .removeButton {
    svg {
      color: var(--ion-color-danger);
    }
  }

  &:first-child {
    border-top: 1px solid var(--ion-border-color);
  }
}

.container {
  flex-wrap: nowrap;
  align-items: center;
}

.wrapper {
  padding: 16px 0;
}

.title {
  margin-bottom: 0px;
  color: var(--ion-text-color-dark);
}

.avatarTitle {
  color: var(--ion-text-color-reversed);
}

.avatar {
  margin-inline-end: 8px;
  @include cardAvatar(var(--ion-avatar-size));
}

.pick12Icon {
  top: 4px;
}

.corpIcon {
  width: 13px;
  height: 16px;
  margin-right: 4px;
  margin-top: 4px;
  vertical-align: middle;
  text-align: center;
  position: relative;
  float: left;
  color: var(--mipro-color-purple);
}
