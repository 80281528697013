.settings {
  .content {
    --padding-top: 8px;
    --padding-end: 16px;
    --ion-background-color: var(--ion-color-light);
    --label-micro-text-size: 13px;

    .ionGrid {
      padding: 0;
    }

    .ionPageItem {
      --background: var(--ion-background-color);
      --inner-padding-top: 24px;
      --inner-border-width: 0px;
      --inner-padding-bottom: 0px;
      --inner-padding-end: 4px;
      --border-color: var(--ion-border-separator);
    }
    ion-item {
      margin-inline-end: -20px;
      --padding-start: 0px;
      &[disabled='true'] {
        opacity: 0.7; // override the IonItem default of 0.3
      }
    }
    .avatar {
      width: 32px;
      height: 32px;
    }

    .sectionSeparator {
      margin-inline-end: -16px;
      height: 10px;
      --ion-item-background: var(--ion-background-separator);
    }
    .sectionRow {
      padding-inline-start: 20px;
    }
    .primary {
      --padding-start: 20px;
      --padding-end: 28px;
    }
    .lastItemRow {
      border-bottom: none;
      --border-style: none;
    }

    .currencyBtn {
      span {
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
      }
    }

    .singleCurrency {
      margin-inline-end: 10px;
    }

    .settingsTitles {
      line-height: 19.2px;
      font-family: var(--ion-font-family);
    }
    .profileIcon {
      width: 19px;
      height: 15px;
      color: var(--ion-color-secondary);
      padding-inline-end: 8px;
    }
    .flag {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-right: 4px;
    }
  }
}
