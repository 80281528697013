.searchButton {
  height: 30px;
  position: relative;
  margin-inline-start: 16px;
  margin-inline-end: 12px;
  --padding-start: 0;
  --padding-end: 0;
  --overflow: visible;

  &.condensed {
    margin-left: 8px;
    
    svg {
      margin: 0;
      color: var(--mipro-color-medium-gray) !important;
    }
  }
}

.content {
  --padding-start: 0;
  --padding-end: 0;

  .header {
    padding: 16px 0 8px;
    background: var(--mipro-color-white);
  }
}

.loader,
.message {
  padding: 0;
  margin: 16px;
}

.tabs {
  position: sticky;
  top: 0px;
  z-index: 2;
  border-top: 0px;
}
