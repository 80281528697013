ion-popover::part(content) {
  border-radius: 6px;
}

.dropdown {
  --min-width: 100px;
  --width: auto;
  --box-shadow: 0px 4px 15px 0px #0000004d;

  .dropdownContent {
    display: block;
  }

  ion-list {
    ion-item {
      --padding-start: 10px;
      --padding-end: 10px;
      --ion-border-color: #f0f0f0;
      --inner-border-width: 0px 0px 1px 0px;
      --min-height: 34px;
      &:first-child {
        padding-top: 3px;
      }
      &:last-child {
        --border-style: none;
        --padding-bottom: 3px;
      }
      .icon {
        font-size: 9px !important;
        margin-left: 4px;
        color: #0c6cd4;
      }
      .iconLeft {
        margin-right: 4px;
      }
    }
  }
}

.menuText {
  color: var(--ion-color-primary);
  font-size: 11px;
  font-family: var(--ion-font-family);
  font-weight: 500;
  &.selected {
    font-weight: 700;
  }
}
