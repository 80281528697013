.radio {
  margin-right: 24px;
  height: 20px;
}

.marginTop {
  margin-top: 4px;
}

.flex {
  display: flex;
  align-items: center;
}

.text {
  white-space: pre-wrap;
}

.inline {
  display: inline-flex;
}
