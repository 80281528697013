.selectWrapper {
  --min-height: auto;
  --highlight-height: 0;
  --background: transparent;
  --background-activated: transparent;
  --background-activated-opacity: 1;
  --background-focused: transparent;
  --background-focused-opacity: 1;
  --background-hover: transparent;
  --background-hover-opacity: 1;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;

  ion-col {
    --ion-grid-column-padding: 0;
  }

  .label {
    line-height: 18px;
  }

  .select {
    --padding-start: 16px;
    --padding-end: 16px;
    max-width: 100%;
    width: 100%;
    position: relative;

    &::part(container) {
      flex: 1;
    }

    ::part(icon) {
      position: absolute;
      right: 16px;
    }
  }

  .wrapper {
    margin-top: 8px;
    border: 1px solid var(--ion-text-color-light);
    align-items: center;
    background: var(--ion-item-background);
  }

  &.error {
    .label,
    ion-select {
      color: var(--ion-color-danger);
    }

    ion-button svg {
      color: var(--ion-color-danger);
    }

    .wrapper {
      margin-bottom: 4px;
    }

    ion-row {
      border-color: var(--ion-color-danger);
    }
  }

  &.disabled {
    .wrapper {
      border-color: var(--ion-border-color);
    }

    ion-label {
      opacity: 1 !important;

      .label {
        color: var(--ion-color-medium);
      }
    }

    ion-select {
      --color: var(--ion-color-medium);

      select {
        opacity: 1 !important;
      }
    }
  }
}

.errorMessage {
  color: var(--ion-color-danger);
}
