@import 'theme/mixins';

.button {
  position: relative;
  @include increaseTouchTargetSize(50%, 0%, 50%);
}

.filter {
  padding: 0 12px;
  margin-right: 8px;
  line-height: 0.5rem;
  border-radius: 40px;
  --border-radius: 40px;
  height: 35px !important;
  border: 1px solid var(--ion-color-medium);

  &.filterSelected {
    background-color: var(--ion-color-primary);
    border: none;
  }

  span {
    text-transform: capitalize;
    font-weight: normal;
    color: var(--ion-color-primary-contrast) !important;
  }

  svg {
    font-size: 13px;
    margin-inline-end: 10px;
    color: var(--ion-color-primary-contrast) !important;
  }
}

.sort {
  text-decoration: none !important;

  span {
    font-size: 13px;
    font-weight: normal;
  }

  svg {
    font-size: 13px;
  }
}

.sortRow {
  order: 2;
  display: flex;
  flex-wrap: wrap;
  margin-inline-start: 16px;

  .sortLabel {
    margin-inline-end: 10px;

    span,
    svg {
      text-transform: capitalize;
      color: var(--ion-text-color-dark);
    }

    svg {
      margin-inline-end: 4px;
    }
  }
}

.content {
  .sectionTitle {
    display: block;
    margin: 16px 0;
    color: var(--mipro-color-black);
  }

  .filterRow {
    height: 30px;
    margin-right: 10px;
    margin-bottom: 8px;
    border-radius: 24px;
    --border-radius: 24px;
    --padding-top: 8px;
    --padding-bottom: 8px;
    --padding-start: 16px;
    --padding-end: 16px;

    &::part(native) {
      border: 1px solid var(--mipro-color-dark-text);
    }

    svg {
      font-size: 13px;
      margin-inline-end: 8px;
    }

    span {
      font-weight: 600;
      text-transform: capitalize;
      color: var(--ion-text-color-dark);
    }

    &.optionSelected {
      --background: var(--mipro-color-pale-blue);
      --color: var(--mipro-color-dark-text);
      &::part(native) {
        border: none;
      }
    }
  }
}

.hideOption {
  display: none;
}

.filterSheet {
  &::part(handle) {
    background: var(--color-help-modal-handle);
  }
}

.footer {
  border-top: 1px solid var(--ion-border-color);
  padding-top: 1rem;

  .buttonBar {
    display: flex;
    gap: 1rem;
    --ion-color-primary: var(--mipro-color-light-blue);

    .fullWidth {
      width: 100%;
    }
  }
}
