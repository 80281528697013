.VisitHistory {
  .headerRow {
    padding: 0 16px;
    margin-bottom: 16px;
    align-items: center;

    .spaceFiller {
      flex: 1;
    }

    .filters {
      padding: 10px 16px;
      margin-inline-start: 16px;
    }

    .helpIcon {
      color: var(--ion-color-primary);
    }
  }

  .content {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 16px;
    --padding-end: 16px;

  }

  .activityCard {
    padding: 16px 0;
    border-top: 1px solid var(--ion-border-color);

    &:first-child {
      border: none;
    }
  }
}

.loader {
  margin-top: 16px;
}

.helpModalWrapper {
  .green {
    svg {
      color: var(--mipro-color-light-green) !important;
    }
  }
  .actionRow {
    &:last-child {
      border-bottom: 0;
      --border-color: transparent;
    }
  }
}

.modelContent {
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}
