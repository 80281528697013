@import 'theme/mixins';

.header {
  position: relative;

  .container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
  }

  .wrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    min-height: 67px;
  }

  &.dark {
    ion-toolbar {
      --border-width: 0 0 1px;
      --border-style: solid;
      --border-color: var(--ion-color-dark);
      --background: linear-gradient(
        to bottom,
        var(--ion-color-dark),
        var(--color-gray)
      );
    }

    .wrapper ion-button,
    .wrapper ion-menu-button,
    .headerActions ion-button {
      color: var(--ion-color-light);

      svg {
        color: var(--ion-color-light);
      }
    }

    .eyebrow,
    .title,
    .subTitle,
    .pageTitle,
    .customerName,
    .customerEyebrow {
      color: var(--ion-color-light);
    }
  }

  &.light {
    ion-toolbar {
      --border-width: 0 0 1px;
      --border-style: solid;
      --border-color: var(--ion-border-color);
      --background: var(--ion-color-light);
    }

    .wrapper ion-button,
    .wrapper ion-menu-button,
    .headerActions ion-button {
      color: var(--ion-color-dark);

      svg {
        color: var(--ion-color-dark);
      }
    }

    .eyebrow,
    .title,
    .subTitle,
    .pageTitle,
    .customerName,
    .customerEyebrow {
      color: var(--ion-color-dark);
    }
  }

  ion-toolbar {
    --opacity: 1;
    --opacity-scale: 1;
    --padding-start: 16px;
    --padding-end: 16px;
    --border-width: 0;
    --border-style: none;
    --background: transparent;
    --padding-top: 0;
    --padding-bottom: 0;

    &:first-of-type {
      --min-height: 67px;
    }
  }

  ion-title {
    position: static;
    padding-inline-end: 0;
    padding-inline-start: 0;
    text-transform: uppercase;
    min-width: 1px;
    &.topPageTitle {
      padding-inline-start: 6px;
    }
  }

  ion-button,
  ion-menu-button {
    margin: 0;
    opacity: 1;
    position: relative;
    @include increaseTouchTargetSize();
    --padding-start: 0;
    --padding-end: 0;
  }

  // TODO should be 4 and 8 according to designs, leaving it like this to match older Header
  .backButton {
    padding-inline-start: 6px;
    padding-inline-end: 13.5px;
  }

  ion-menu-toggle {
    margin-inline-start: 8px;
  }

  // TODO custom button for both header actions and menu should help removing this
  .headerActions ion-button {
    margin-inline-start: 13.5px;
    margin-inline-end: 8px;
  }

  &.collapsed {
    .customerLink,
    .customerName {
      pointer-events: none;
      @include textEllipsis();
    }

    .corpIcon {
      color: var(--mipro-color-white);
    }
  }

  .cartButton {
    height: 30px;
    position: relative;
    margin-inline-start: 16px;
    margin-inline-end: 12px;
    --padding-start: 0;
    --padding-end: 0;
    --overflow: visible;

    svg {
      margin: 0;
      color: var(--mipro-color-medium-gray) !important;
    }

    .badge {
      margin: 0;
      padding: 4px;
      position: absolute;
      top: 0;
      right: -8px;
      border: 2px solid var(--mipro-color-dark-text);

      span {
        font-size: 7px;
      }
    }
  }

  .locationButton {
    span {
      padding-inline-start: 4px;
      color: var(--mipro-color-medium-gray) !important;
    }
  }
}

.eyebrow,
.title,
.subTitle,
.pageTitle,
.customerEyebrow {
  @include textEllipsis();
}

.customerName {
  white-space: pre-wrap;
  text-align: left;
  color: var(--mipro-color-primary);
}

.condensedButtons {
  position: absolute;
  right: 16px;
  top: 0;
}

.customerRow {
  align-items: center;
  flex-wrap: nowrap;
}

.badge {
  margin-left: 8px;
}

.favorite {
  color: var(--mipro-color-primary);
  font-size: 16px !important;
  margin-right: 4px;
}

.corpIcon {
  color: var(--mipro-color-purple);
  width: 13px;
  margin-right: 4px;
}
