@import 'theme/mixins';

.button {
  position: relative;
  --color: var(--ion-color-light) !important;
  @include increaseTouchTargetSize(50%, 0%, 50%);
}

.content {
  padding: 25px 0 0;

  .buttonWrapper {
    border-bottom: 1px solid var(--ion-border-color);
    --min-height: 52px;
    --padding-start: 0;

    .modalButton {
      width: 100%;
      height: 50px;
    }

    span {
      padding-left : 5px;
      font-weight: 500;
      white-space: break-spaces;
      text-align: left;
      width: 100%;
    }

    &:last-child {
      border-bottom: 0;
      --border-color: transparent;
    }
  }
}
