.documentRow {
  margin-left: -16px;
  margin-right: -16px;
  align-items: center;
  padding-bottom: 10px;

  &.titleRow {
    padding-bottom: 0;
    padding-top: 16px;
  }

  .staticImage {
    width: 100%;
    max-height: 200px;

    &::part(image) {
      max-height: 200px;
    }
  }
}

.documentCol {
  padding: 0;
  padding-left: 16px;
  padding-right: 16px;
}
.documentColHeader {
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: var(--ion-color-danger);

  span {
    color: var(--ion-color-danger-contrast);
  }
}

.inputRowWrap {
  .containerValue {
    &.textAreaValue {
      .textArea {
        width: 100%;
        text-transform: uppercase;
        ion-textarea {
          margin: 0;
          --padding-top: 16px;

          textarea {
            padding-left: 16px;
          }
        }
      }
    }
    ion-input {
      text-transform: uppercase;
    }
    .radio {
      --inner-border-width: 0;
      --min-height: 38px;

      ion-label {
        margin: 0;
        margin-left: 16px;
      }

      ion-radio {
        margin: 0;
      }
    }
  }
}

.imgDiv {
  display: flex;
  background-color: red;
  height: 142px;
  object-fit: cover;
}

.inputTitle {
  display: flex;
  align-items: center;

  .needSyncBadge {
    border-radius: 10px;
    margin-inline-start: 8px;
    padding: 0 8px;
    background: var(--ion-color-primary);
    color: var(--ion-text-color-reversed);
  }
}
