.validationWrapper {
  white-space: normal;
  display: flex;
  flex-flow: nowrap;
  gap: 8px;
  svg {
    width: 14px;
    height: 14px;
    margin: 0;
  }
  span {
    font-family: var(--ion-font-family);
    font-size: 14px;
    font-weight: 300;
  }
}
.warning {
  span,
  svg {
    color: var(--mipro-color-warning-shade);
  }
}
.success {
  span,
  svg {
   color: var(--mipro-color-dark-green);
  }
}
.danger {
  span {
   color: var(--mipro-color-light-red);
  }
  svg {
    color: var(--mipro-color-dark-red);
  }
}
.info {
  span {
    color: var(--mipro-color-dark-blue);
  }
  svg {
    color: var(--mipro-color-light-blue);
  }
}
.loading {
 span,
 svg {
  color: var(--mipro-color-dark-green);
 }
}

