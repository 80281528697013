@import 'theme/mixins';

.CardAvatar {
  @include cardAvatar(var(--ion-avatar-size));
}

.yellow {
  color: var(--ion-color-warning-shade) !important;
}

.green {
  color: var(--ion-color-success);
}

.red {
  color: var(--ion-color-warning);
}

.white {
  color: white;
}

.yellowBackground {
  background-color: var(--ion-color-warning-shade) !important;
}

.greenBackground {
  background-color: var(--ion-color-success);
}

.redBackground {
  background-color: var(--ion-color-warning);
}

.whiteBackground {
  background-color: white;
}

.greyBackground {
  background-color: var(--ion-background-color);
}

.whiteBackground {
  background-color: var(--ion-item-background);
}


// Incoming Orders & Sales
.color-upsell {
  color: var(--color-upsell) !important;
}
.color-largeOrder {
  color: var(--color-largeOrder) !important;
}
.color-largeQuote {
  color: var(--color-largeQuote) !important;
}
.color-crossSellSalesPlay {
  color: var(--color-crossSellSalesPlay) !important;
}

// Customer Contact
.color-webActivity {
  color: var(--color-webActivity) !important;
}
.color-customerVisit {
  color: var(--color-customerVisit) !important;
}
.color-reengagedCustomerAccount {
  color: var(--color-reengagedCustomerAccount) !important;
}
.color-newCustomerContact {
  color: var(--color-newCustomerContact) !important;
}

// Immediate Attention Required
.color-overdueSalesPlay {
  color: var(--color-overdueSalesPlay) !important;
}
.color-priceOverride {
  color: var(--color-priceOverride) !important;
}
.color-priceOverrideRejected {
  color: var(--color-priceOverrideRejected) !important;
}
.color-atRiskCustomerAccount {
  color: var(--color-atRiskCustomerAccount) !important;
}
.color-crmTask {
  color: var(--color-crmTask) !important;
}
.color-shopQuoteReady {
  color: var(--color-shopQuoteReady) !important;
}
.color-crmOpportunity, .color-salesOpportunity {
  color: var(--color-crmOpportunity) !important;
}
.color-lateOrder {
  color: var(--color-lateOrder) !important;
}
.color-decliningSalesPlay {
  color: var(--color-decliningSalesPlay) !important;
}
.color-sourcingOverride {
  color: var(--color-sourcingOverride) !important;
}
.color-priceOverrideApproved {
  color: var(--color-priceOverrideApproved) !important;
}
.color-sourcingOverrideRejected {
  color: var(--color-sourcingOverrideRejected) !important;
}
.color-sourcingOverrideAccepted {
  color: var(--color-sourcingOverrideAccepted) !important;
}

// Action Toggles - Not Selected
.color-snoozed {
  color: var(--color-snoozed) !important;
}
.color-followUp {
  color: var(--color-followUp) !important;
}
.color-delete {
  color: var(--color-delete) !important;
}
// Action Toggles - Selected
.color-snoozed-active {
  color: var(--color-snoozed-active) !important;
}
.color-followUp-active {
  color: var(--color-followUp-active) !important;
}
.color-delete-active {
  color: var(--color-delete-active) !important;
}

.color-commentNotification {
  color: var(--mipro-color-purple);
}
