.content {
  height: 100%;
  overflow-y: auto;
}

.list {
  margin-top: 16px;

  .tableHeader {
    --background: var(--ion-item-background);
  }
}
