@import 'theme/mixins';

.item {
  --padding-top: 8px;
  --padding-bottom: 8px;

  .addButton {
    width: 50px;
    min-width: 50px;
    margin-inline-end: 16px;
    text-decoration: none;

    span {
      font-size: 13px;
      text-transform: uppercase;
    }
  }

  ion-button::part(native):focus {
    text-decoration: none;
  }

  &.selectedItem {
    --action-row-background: rgba(var(--ion-color-success-rgb), 0.05);

    span {
      font-weight: 600;
    }

    ion-button {
      svg {
        color: var(--ion-color-success) !important;
      }
    }
  }

  .wrapper {
    width: 100%;

    ion-row {
      flex-wrap: nowrap;
    }
  }

  .title {
    font-size: 13px;
    color: var(--ion-text-color-dark);
  }

  .description {
    font-size: 11px;
  }

  .additionalInfo {
    font-size: 11px;
  }

  .descriptionRow {
    display: flex;
    justify-content: space-between;
  }

  .detailsButton {
    margin-inline-start: 66px;
  }

  .details {
    width: 100%;
    margin-top: 8px;
    margin-inline-start: 66px;
  }

  .detailRow {
    padding: 0;
    min-height: unset;

    span {
      font-size: 13px;
    }
  }
}

.list {
  // TODO: better calculate height
  height: calc(100vh - 150px);
}

.modal {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 0;
  --padding-end: 0;
  --background: var(--ion-background-color);
}

.header {
  padding: 16px;
  position: relative;
  border-bottom: 1px solid var(--ion-border-color);
  margin-top: var(--ion-safe-area-top);
}

.searchWrapper {
  margin-top: 16px;
  display: flex;

  .searchbar {
    --searchbar-border-color: var(--ion-text-color);
    flex: 1;
  }

  .clearButton {
    height: 42px;
    margin-inline-start: 8px;
    --padding-start: 12px;
    --padding-end: 12px;

    &.disabled {
      --border-color: var(--color-disabled-button);

      span {
        color: var(--color-disabled-button);
      }
    }
  }
}

.warningMessage {
  margin: 16px;
}

.footer {
  padding: 16px;
  border-top: 1px solid var(--ion-border-color);

  ion-button {
    width: 100%;
  }
}
