.header {
  ion-toolbar {
    --padding-start: 8px;
    --padding-end: 8px;
  }

  .input {
    margin: 0 16px 16px;
  }
}

.content {
  --padding-top: 0;
  --padding-bottom: 16px;
  --padding-start: 16px;
  --padding-end: 16px;

  .row {
    height: 100%;

    .column {
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }
}

.list {
  padding-bottom: 16px;
  flex: 1;
}

.footer {
  background: var(--ion-item-background);

  ion-toolbar {
    --padding-top: 16px;
    --padding-bottom: 16px;
    --padding-start: 16px;
    --padding-end: 16px;
    --background: transparent;
  }

  ion-button {
    flex: 1;
  }

  .saveButton {
    margin-inline-end: 16px;
  }
}

.editButton {
  margin: 0 -16px;

  span {
    color: var(--ion-color-primary);
  }
}

.corpIcon {
  width: 13px;
  height: 16px;
  margin-right: 4px;
  vertical-align: middle;
  text-align: center;
  position: relative;
  float: left;
  color: var(--mipro-color-purple);
}
