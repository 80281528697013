.message {
  margin-block-end: var(--wrapper-margin);
  margin-block-start: var(--wrapper-margin);
  ion-col {
    padding: 32px 44px;
    border-radius: 4px;
    align-items: center;
    text-align: center;
  }

  .title {
    display: block;
    margin-bottom: 8px;
  }

  .body {
    white-space: pre-wrap;
  }

  .icon {
    font-size: 30px;
    margin: 0 auto 8px;
    color: rgba(var(--ion-color-medium-rgb), 0.5);
  }
}

.default {
  border: dashed 2px var(--ion-border-color);
  background: transparent;
}

.warning {
  border: dashed 2px var(--mipro-color-dark-orange);
  background: var(--mipro-color-light-orange);
}

.alert {
  background: var(--mipro-color-pale-red);
  border: dashed 2px var(--mipro-color-danger);
}
