.toolbar {
  --padding-top: 0;
  --padding-bottom: 0;

  .searchRow {
    align-items: center;
    margin: 0 8px 16px;

    .searchBar {
      flex: 1;
      --searchbar-border-color: var(--ion-text-color);
    }
  }
}

.content {
  --padding-top: 16px;
}
