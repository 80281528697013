.returnInstructionModal {
  .row {
    margin-top: 16px;
    .input {
      width: 100%;
    }
  }
  .footer {
    ion-toolbar {
      box-shadow: none;
      --padding-start: 0px;
      --padding-end: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
  }
  .loader {
    margin-top: 16px;
  }
}
