@import 'theme/mixins';

.title {
  position: initial;
  text-transform: uppercase;
}

.eyebrow {
  display: block;
  height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.cartIcon {
  padding-right: 8px;
  height: 30px !important;
  svg {
    color: var(--mipro-color-medium-gray) !important;
    font-size: 20px;
  }
  .badge {
    justify-content: center;
    padding: 4px;
    margin-inline-start: -15px;
    margin-bottom: 15px;
    border: 2px solid var(--mipro-color-dark-text);
    span {
      font-size: 7px;
    }
  }
}



.title,
.eyebrow {
  text-align: left;
  padding-inline-end: 8px;
  padding-inline-start: 8px;
}

.header {
  &.dark {
    color: var(--ion-color-light);
    ion-button {
      --color: --ion-color-light;

      svg {
        color: var(--color);
      }

      &:hover {
        opacity: 0.9;
        --color-hover: --ion-color-light;

        svg {
          color: var(--color-hover);
        }
      }
    }

    .titleText,
    .eyebrow {
      color: var(--ion-color-light);
    }

    & :global(.header-background) {
      border-bottom: 1px solid var(--ion-color-dark);
      background-image: linear-gradient(
        to bottom,
        var(--ion-color-dark),
        var(--color-gray)
      );
    }
  }

  &.light {
    color: var(--ion-color-dark);

    ion-button {
      --color: --ion-color-dark;

      svg {
        color: var(--color);
      }

      &:hover {
        opacity: 0.9;
        --color-hover: --ion-color-dark;

        svg {
          color: var(--color-hover);
        }
      }
    }

    .titleText,
    .eyebrow {
      color: var(--ion-color-dark);
    }

    & :global(.header-background) {
      background-color: var(--ion-color-light);
      border-bottom: 1px solid var(--ion-border-color);
    }
  }

  ion-toolbar {
    --padding-start: 16px;
    --padding-end: 10px;
    --border-style: none;
    --background: transparent;
    --ion-color-base: transparent !important;
    --border-width: 0;

    &:first-of-type {
      --min-height: 67px;
    }
  }

  ion-button,
  ion-back-button {
    min-height: 20px;
    min-width: 20px;

    &::part(native) {
      margin: 0;
      outline: none;
      --background: transparent;
      --background-focused: transparent;
      --background-focused-opacity: 1;
      --background-activated: transparent;
      --background-activated-opacity: 1;
      --background-hover: transparent;
      --background-hover-opacity: 1;
      --border-radius: 0;
      --border-style: none;
      --box-shadow: none;
      --padding-top: 0;
      --padding-bottom: 0;
      --padding-start: 0;
      --padding-end: 0;
      --ripple-color: transparent;
      --opacity: 1;
      box-shadow: none;
    }

    &::part(icon) {
      font-size: 18px;
    }

    .ripple-effect {
      display: none;
    }
  }

  ion-back-button,
  .backButton {
    @include increaseTouchTargetSize();
  }

  .condendsedHeaderIcons {
    align-self: center;
    margin-bottom: 0;
  }

  .nonCondensedIcons {
    margin-bottom: 5px;
  }


  ion-buttons {
    margin-bottom: 4px;
    align-self: flex-end;
    margin-inline-end: 0;
    margin-inline-start: 0;
  }

  ion-icon {
    font-size: 18px;
  }
}

.closeBtn {
  position: relative;
  @include increaseTouchTargetSize();
}

.openMenuToggle {
  display: flex;
  align-self: end;
  margin-bottom: 9px;
  margin-inline-end: 0;
  margin-inline-start: 0;
  ion-avatar {
    width: 24px;
    height: 24px;
    display: flex;
    line-height: 16px;
    --border-radius: 50%;
  }

  .menuButton {
    color: var(--ion-text-color-reversed);
    opacity: 1;
  }
}

.logo {
  height: 24px;

  img {
    width: 91px;
    height: 26px;
  }

  &.servicesLogo {
    img {
      width: 275.5px;
    }
  }
}

.pick12Icon {
  margin-top: 2px;
}

.locationButton {
  &.condensedCartIcon {
    padding-right: 0;
  }
  .locationIcon {
    color: var(--mipro-color-medium-gray) !important;
  }
  span {
    color: var(--mipro-color-medium-gray) !important;
  }
}

