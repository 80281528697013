@import 'theme/mixins';

.container {
  .content {
    --padding-bottom: 16px;
    --padding-start: 0;
    --padding-end: 0;
    .header {
      padding: 16px 0px;
    }
  }
  .detailsContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 6px;
    padding-bottom: 0px;
    .rowWrapper {
      display: flex;
      width: 100%;
      border-top: 1px solid var(--ion-border-color);
      margin-top: 8px;
      padding-top: 8px;

      &:first-child {
        border-top: none;
        margin-top: 0;
        padding-top: 0;
      }

      .detailsWrapper {
        width: 50%;

        .title {
          display: inline-block;
          margin-bottom: 4px;
          text-transform: uppercase;
        }

        .contactName {
          white-space: break-spaces;
          text-align: left;
        }

        .mainContactWrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .mainContentText,
          .mainContentNumber {
            margin-bottom: 8px;
          }

          .mainContentEmail {
            position: relative;
            @include increaseTouchTargetSize(50%, 0, 50%);
          }

          .corpIcon {
            font-size: 14px;
            color: var(--mipro-color-purple);
          }
        }
      }
    }
  }

  .actionRowWrapper {
    margin-top: 10px;

    .actionRow:first-child {
      border-top: 1px solid var(--ion-border-color);
    }
  }

  .loader {
    margin-top: 16px;
  }
}

.miniCardWrapper {
  display: flex;
  padding: 0 16px;
  column-gap: 8px;

  > * {
    flex: 1 1;
    width: 50%;
  }
}

.externalLinks {
  .linkedCard:not(:last-child) {
    margin-block-end: 11px;
  }
}

.linkedCard {
  margin: 0;
}

.rowSeparator {
  border-bottom: 1px solid var(--ion-border-color);
}

.warningMessage {
  margin: 16px;
}

.salesPGCardHeader {
  padding-inline-end: 10px;
  padding-inline-start: 10px;
  display: flex;
  justify-content: space-between;

  .salesPGCardHeaderTitle {
    font-weight: 700;
    font-size: 18px;
    color: var(--ion-color-primary);
    margin-inline-end: 8px;
    font-family: var(--ion-font-family-alt);
  }

  .requestTypeWrapper {
    display: flex;
    align-items: center;

    .salesPGCBtn {
      margin-left: 12px;

      span {
        font-size: 13px;
      }

      svg {
        font-size: 12px;
      }
    }

    .requestDate {
      color: var(--ion-text-color-dark);
      font-family: var(--ion-font-family);
      font-size: 13px;
      font-weight: medium;
    }
  }

  svg {
    margin-inline-start: auto;
  }
}

.accountsReceivableCardHeader {
  width: 100%;

  svg {
    margin-inline-start: auto;
  }
}

.salesProductGroupCard {
  padding: 0;
}

.section {
  padding-top: 16px;
  width: 100%;
}

.cxManager {
  padding-top: 16px;
  span {
    text-transform: uppercase;
  }
}
