.podWrapper {
  display: flex;
  justify-content: space-between;
  .sendEmailBtn {
    svg {
      color: var(--mipro-color-light-blue);
      font-size: 14px;
    }
  }
  .podFile {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      white-space: break-spaces;
      word-break: break-all;
    }
  }
}
