.content {
  .listHeader {
    width: 100%;

    .quantityLabel {
      max-width: 80px;
    }

    span {
      flex: 1;
      text-align: center;
    }
  }
}

.warningMessage,
.loader {
  margin: 16px;
}

.footer {
  border-top: 1px solid var(--ion-border-color);
  background: var(--ion-item-background);

  ion-row {
    padding: 16px;
  }

  ion-button {
    flex: 1;
  }
}

.item {
  --padding-top: 16px;
  --padding-bottom: 16px;

  ion-col {
    padding: 0;
  }

  .dataRow {
    flex: 2;

    ion-row span {
      flex: 1;
      text-align: center;
    }
  }

  .itemError {
    display: block;
    margin-top: 8px;
    color: var(--ion-color-danger);
  }

  .input {
    flex: 1;
    max-width: 80px;
  }
}

.deleteButton {
  background: var(--ion-color-danger);

  span,
  svg {
    color: var(--ion-text-color-reversed) !important;
  }
}

.buttonInput {
  display: none;
}
