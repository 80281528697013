.modal {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;

  .contentWrapper {
    height: 100%;
  }

  .itemSlider {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .itemOption {
      width: 150px;
      background: var(--mipro-color-pale-blue);
      color: var(--mipro-color-primary);

      svg {
        position: absolute;
      }

      .prevOption {
        left: 16px;
      }

      .nextOption {
        right: 16px;
      }
    }
  }

  .item {
    flex: 1;
    height: 100%;
    background: var(--mipro-color-white);
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;

    &::part(native) {
      height: 100%;
    }

    .itemContent {
      width: 100%;
      height: 100%;
      padding: 0 16px 16px;
    }
  }
}

.checkbox {
  margin: 8px 8px 8px 0;
}

.buttonWrapper {
  padding: 20px 0px 0px 0px;
  &.buttonGap {
    display: flex;
    gap: 10px;
  }
}

.modalButton {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--mipro-color-primary);
}

.formItem {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --border-color: transparent;
  --background: transparent;
  --background-activated: transparent;
  --background-activated-opacity: 1;
  --background-focused: transparent;
  --background-focused-opacity: 1;
  --background-hover: transparent;
  --background-hover-opacity: 1;
  margin-top: 8px;
}

.modal {
  .beltingForm {
    padding: 0;

    .orderQuantityInput ion-col > span {
      display: none;
    }
  }

  .inputQuantityRow {
    border-radius: 6px;
    border: 1px solid var(--mipro-color-black);
  }

  .marginTop {
    margin-top: 20px;
  }

  // TODO create a readOnly style in Input component!
  .readOnlyInput {
    ion-row {
      border: 1px solid transparent !important;
    }

    ion-input input {
      opacity: 1 !important;
      color: var(--ion-text-color) !important;
    }

    span {
      color: var(--ion-text-color) !important;
    }
  }

  .orderQuantityInput {
    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

.modalHeader {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--mipro-color-light-gray);
  padding: 18px 0px;

  ion-button {
    top: 22px;
    right: calc(100% - 26px);
  }

  span {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    color: var(--mipro-color-dark-text);
  }
}

.itemGrid {
  --ion-grid-padding-xs: 0px;
  --ion-grid-padding: 0px;
  padding-bottom: 24px;

  .onOrderQty {
    color: var(--mipro-color-light-green);
    font-weight: 700;
  }

  .errorWrapper {
    padding-bottom: 4px;
  }

  .errMsg {
    color: var(--ion-color-danger);
  }
}

.itemCol {
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.itemText {
  &.itemTitle {
    padding: 15px 0px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;

    span {
      width: 100%;
    }
  }

  .text {
    width: 50%;
    padding-bottom: 2px;
    --text-default-color: var(--mipro-color-dark-text);
  }

  .onOrderQty {
    --text-default-color: var(--mipro-color-light-green);
    font-weight: 700;
  }

  .textCounter {
    width: 50%;
    padding-bottom: 2px;
    --text-default-color: var(--mipro-color-dark-text);
    font-weight: bold;
  }

  .editButton {
    display: flex;

    span {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
    }

    svg {
      font-size: 12px;
    }
  }
}

.confirmModal {
  --border-radius: 10px;

  .confirmContent {
    div {
      display: flex;
      flex-direction: column;
      align-items: center;

      .confirmTitle {
        width: 65%;
        margin-bottom: 16px;
      }
    }

    .confirmPrimaryButton {
      --background: var(--mipro-color-primary);
    }
  }
}

.hiddenButton {
  display: hidden;
  position: absolute;
  position: -999px;
}

.hideInput {
  display: none;
}

.stepIcon {
  width: 14px;
  height: 14px;
}
