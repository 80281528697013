.notificationSettings {
  .content {
    --padding-top: 8px;
    --padding-end: 16px;
    --ion-background-color: var(--ion-color-light);
    --label-micro-text-size: 13px;

    .ionGrid {
      padding: 0;
    }
    .scheduleGrid {
      padding-inline-start: 4px;
    }

    .ionPageItem {
      --background: var(--ion-background-color);
      --inner-padding-top: 24px;
      --inner-padding-bottom: 16px;
      --inner-border-width: 0px 0px 1px 0px;
      --border-color: var(--ion-border-separator);
    }
    .pushItem {
      --inner-padding-bottom: 0px;
      --inner-padding-end: 4px;
    }
    .snoozeItem {
      --inner-padding-bottom: 24px;
      --padding-start: 4px;
      --inner-padding-top: 24px;
      --inner-padding-end: 4px;
    }

    .pushTitle {
      font-size: 22px;
      text-transform: uppercase;
      line-height: 110%;
      color: var(--color-gray);
    }
    .scheduleTitle {
      line-height: 16px;
      color: var(--ion-text-color-dark);
    }
    .scheduleItem {
      color: rgb(0, 0, 0, 0.87);
      line-height: 24px;
    }
    .snoozeNote {
      --text-default-color: var(--ion-text-color-light);
    }

    .sectionPadding {
      padding: 16px 0 16px 0px;
    }
    .grouped {
      padding: 24px 0 16px 20px;
    }

    .textChoose {
      margin: 8px 0 16px 0;
      line-height: 16px;
    }
    .notificationTypeTitle {
      font-family: var(--ion-font-family);
      line-height: 120%;
      font-feature-settings: 'calt' off;
    }
    .toggleItem {
      --inner-border-width: 0px 0px 1px 0px;
      --border-color: var(--ion-border-separator);
      --inner-padding-bottom: 24px;
      --inner-padding-top: 24px;
    }
    .pushToggle {
      --inner-border-width: 0px 0px 0px 0px;
      --padding-start: 4px;
      --inner-padding-top: 24px;
      ion-toggle {
        top: auto;
      }
    }
    .darkLabelHeader {
      font-size: 14px;
      line-height: 17px;
      color: var(--ion-text-color-dark);
    }
    .lastItem {
      --inner-border-width: 0px 0px 0px 0px;
    }
    .sectionSeparator {
      margin-inline-end: -16px;
      height: 10px;
      --ion-item-background: var(--ion-background-separator);
    }
    .customizeSection {
      --inner-border-width: 0px;
      --inner-padding-bottom: 0px;
    }
    .activityName {
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.87);
      padding-inline-start: 4px;
    }
    .activityToggle {
      ion-toggle {
        padding-inline-end: 4px;
        top: auto;
      }
    }

    .icon {
      height: 14px;
      margin-left: 4px;
      vertical-align: middle;
    }

    .toggleSnooze {
      --inner-border-width: 0px;
      --inner-padding-top: 24px;
      --inner-padding-bottom: 16px;
      ion-col {
        padding-left: 0;
      }
      ion-toggle {
        top: 22px;
      }
    }
  }
}

.pushNotifDisabled {
  margin-bottom: 16px;
  margin-inline-start: 4px;

  svg {
    height: 14px;
    padding-inline-end: 4px;
  }

  span,
  svg {
    color: var(--mipro-color-warning);
  }

  ion-button {
    margin-inline-start: 4px;
    vertical-align: bottom;
  }
}

.title {
  display: block;
  margin-bottom: 16px;
}

.subtitle {
  display: block;
  font-size: 13px;
  margin-bottom: 4px;
}

.description {
  display: block;
  margin-bottom: 16px;
}

.modal {
  ion-toolbar {
    --min-height: 32px !important;
  }
  ion-content {
    --padding-top: 16px;
  }
}
