.bulletinList {
  .loader {
    margin-top: var(--wrapper-margin);
  }

  .wrapper {
    padding: var(--wrapper-margin) 0;
    flex-direction: column;
  }

  .title {
    display: block;
    margin-bottom: 8px;
  }
}

.warningMessage {
  margin: var(--wrapper-margin);
}

.bulletin {
  .date {
    margin-block-end: var(--wrapper-margin);
  }

  .content {
    font-size: var(--wrapper-margin);
    color: var(--ion-color-dark-tint);
    --padding-top: var(--wrapper-margin);
    --padding-bottom: var(--wrapper-margin);
    --padding-start: var(--wrapper-margin);
    --padding-end: var(--wrapper-margin);
  }
  .bulletinContent{
    padding-top: 20px;
  }

  .imageContainer {
    position: relative;
    width: 100%;
  }

  .thumbnail {
    width: 100%;
    object-fit: contain;
    margin: var(--wrapper-margin) 0;
    object-position: center;
    border-radius: 10px 10px 0px 0px;
  }

  .overlay {
    height: 100%;
    width: 100%;
    opacity: 0.3;
    transition: .3s ease;
    background-color: transparent;
    position: absolute;
    top: 34px;
    bottom: 0;
    left: -34px;
    right: 0;
    @media screen and (min-width: 768px) and (min-height: 1024px) {
      width: 40px;
      height: 40px;
    }
    @media screen and (min-width: 1024px) and (min-height: 1366px) {
      width: 45px;
      height: 45px;
    }
   }

  .icon {
    left: 50%;
    position: absolute;
    bottom: 50%;
    width: 68px;
    height: 68px;
    color: var(--icon-background-source);
    @media screen and (min-width: 768px) and (min-height: 1024px) {
      width: 80px;
      height: 80px;
    }
    @media screen and (min-width: 1024px) and (min-height: 1366px) {
      width: 90px;
      height: 90px;
    }
  }
  .callToAction {
    display: flex;
    justify-content: center;
    .callToActionBtn {
      --border-radius: 6px;
      --padding-start: 16px;
      --padding-end: 16px;
      word-wrap: break-word;
      white-space: pre-wrap;
      position: relative;
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

}
