.content {
  --padding-bottom: 16px;
}

.sectionTitle {
  padding: 16px 16px 8px 16px;
  display: inherit;
  text-transform: uppercase;
}

.sectionWrapper {
  border-top: 1px solid var(--ion-border-color);
  border-bottom: 1px solid var(--ion-border-color);

  div {
    margin: 16px;

    span:first-child {
      margin-right: 8px;
    }
  }
}

.loader {
  margin-top: 16px;
}

.warningMessage {
  margin: 16px;
}
