.modal {
  --padding-top: 0;
  --padding-bottom: 16px;
}

.buttons {
  padding: 16px;

  .customDateButton {
    width: 100%;
  }
}

.row {
  --min-height: unset;
  --padding-top: 13px;
  --padding-bottom: 13px;
  --padding-start: 0;
  --padding-end: 0;

  &:last-child {
    border: none;
  }
}
