.documentsLoader {
  padding-top: 16px;
}

.warningMessage {
  margin: 16px;
}

.footer {
  border-top: 1px solid var(--ion-border-color);
  background: var(--ion-item-background);

  ion-row {
    padding: 16px;
  }

  ion-button {
    flex: 1;
  }
}
