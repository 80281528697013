.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.tabs {
  margin: 0;
  position: sticky;
  top: 0;
  z-index: 2;
}

.loader,
.message {
  margin: 16px;
}

.tabContent {
  overflow: hidden;
  position: relative;
  flex: 1;

  .tabWrapper {
    overflow: auto;
    height: 100%;
  }
}

.formWrapper {
  margin: 16px 0;
  padding: 16px;
  background: var(--mipro-color-white);

  .input {
    margin-top: 16px;

    // needed for select component
    ion-col {
      padding: 0px;
    }
  }

  .dateInput {
    margin-top: 16px;

    ion-row {
      border-radius: 6px;
      border: 1px solid var(--mipro-color-dark);
    }
  }

  .labelRow {
    gap: 4px;
    margin-top: 16px;
  }

  .formRow {
    gap: 16px;
    margin-top: 16px;

    ion-col {
      padding: 0;
    }
  }
}

.error {
  margin-top: 4px;
}
