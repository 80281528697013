.content {
  --padding-top: 16px;
}

.ocnTitle {
  text-align: start;
  display: block;
  margin-top: -6px;
}

.list {
  padding: 0 16px;

  .card {
    padding: 16px 0;
  }

  .wrapper {
    padding-top: 16px;

    span {
      white-space: pre-wrap;
      text-transform: none;
    }

    .sendToText {
      text-transform: capitalize;

      .emailText,
      .onlyEmailText {
        text-transform: none;
      }

      .emailText {
        font-weight: 400;
      }
    }
  }

  .errorText {
    margin: 16px 16px 0;
  }

  .emptyResponse {
    padding: 16px 0;
  }
}
