@import './LeaderboardShape.mixin.scss';


.customerLists {
  @include leaderBoardGenericCard(var(--ion-color-primary-contrast), var(--ion-color-secondary-shade), 4px, 0px, 285px, 0px);
}

.leadeboardDetail {
  @include leaderBoardGenericCard(var(--ion-color-primary-contrast), var(--ion-color-secondary-shade), 4px, 20px, 285px, 8px);
  ion-skeleton-text {
    margin: 0;
    line-height: 20px;
  }
}

.closeButton{
  position: absolute;
  width: 27px;
  height: 27px;
  top: 9px;
  right: 9px;
  --border-radius: 13.5px;
  --background: var(--ion-color-primary-contrast);
  z-index:10;
  .closeIcon {
      color: var(--ion-text-color-red);
      font-size: 20px;
   }
}
