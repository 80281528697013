.list {
  background: transparent;

  [data-item-index]:last-child {
    & > *,
    & ion-item {
      border-bottom: none;
    }
  }
}

.header {
  padding: 0;
  --background: var(--mipro-color-background);
  --padding-top: 4px;
  --padding-bottom: 4px;
  --padding-end: 16px;
  --padding-start: 16px;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  z-index: 3;
}

.loader,
.message {
  margin: 0;
  padding: 16px;
}

.endOfList {
  width: 100%;
  padding: 16px 0;
  justify-content: center;

  span {
    line-height: 14px;
    color: var(--mipro-color-dark-gray);
  }
}
