.loader,
.message {
  padding: 0;
  margin: 16px;
}

.warningMessage {
  margin: 16px;
}

.tableContainer {
  --background: var(--mipro-color-white);
  --ion-color-primary: var(--mipro-color-light-blue);
  --color-table-header-font: var(--mipro-color-black);
  padding-inline-start: 0;

  .tableTH,
  .tableTD {
    border-right: 1px solid var(--mipro-color-light-gray);
  }
}

.header {
  padding-block: 24px;

  .mfgPartRow {
    padding-block-start: 8px;
    padding-block-end: 4px;
  }
}

.productName {
  color: var(--mipro-color-light-blue);
}

.tableItem {
  --ion-text-color-light: var(--mipro-color-dark-text);
  line-height: 13px;
  text-align: center;
}

.tableTH:first-child > div,
.tableTD:first-child > div {
  justify-content: flex-start;
}

.tableTH:first-child > div {
  margin-inline-start: 8px;

  svg {
    display: none;
  }
}
