.footer {
  ion-toolbar {
    --padding-top: 16px;
    --padding-bottom: 16px;
    --padding-start: 16px;
    --padding-end: 16px;
    --border-width: 0;
    --background: var(--mipro-color-white);
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.1);
  }

  ion-buttons {
    gap: 8px;
  }
  ion-button {
    flex: 1;
    --padding-top: 16px;
    --padding-bottom: 16px;
    height: 40px;

    &::part(native) {
      --border-radius: 6px;
    }
  }
}
