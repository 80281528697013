.fallback {
  width: 100%;
  height: 100%;
  font-family: var(--ion-font-family-alt);
  font-weight: 700;
  color: var(--ion-color-light-contrast);
  background: var(--ion-background-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.employeeAvatar {
  background-color: var(--ion-background-color);
}

.avatar {
  margin-right: 8px;
}

.employeeAvatar {
    .fallback {
      width: 80px;
      height: 80px;
    }
  }

