@import 'theme/mixins';

.svg-inline--fa {
  font-size: 20px;
}

ion-card {
  @include cardShadow;
}

ion-card-header {
  flex-direction: row;
}
