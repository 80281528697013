.title {
  display: flex;
  padding-top: 4px;

  .text {
    --text-default-color: var(--mipro-color-medium-gray);
    padding-top: 1px;
    line-height: 100%;
  }

  .icon {
    color: var(--mipro-color-medium-gray);
    width: 12px;
    height: 11.5px;
    padding-right: 4px;
  }
}
