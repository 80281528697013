.searchTabs {
  ion-segment-button span {
    color: var(--ion-text-color-reversed);
  }
}

.toolbar {
  --padding-top: 0;
  --padding-bottom: 0;

  .searchRow {
    align-items: center;
    margin: 8px;

    .searchBar {
      flex: 1;

      ion-row {
        border-radius: 8px;
      }
    }

    .searchInput {
      border-radius: 8px;
    }
  }
}
