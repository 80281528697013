.documentContainer {
  padding: 16px;
  padding-top: 0;
  padding-bottom: 6px;

  .documentRow {
    margin-left: -16px;
    margin-right: -16px;
    align-items: center;
    padding-bottom: 10px;

    &.titleRow {
      padding-bottom: 0;
      padding-top: 16px;
    }
  }

  .documentCol {
    padding: 0;
    padding-left: 16px;
    padding-right: 16px;
  }

  .containerTitle {
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: var(--ion-color-danger);

    .needSyncBadge {
      border-radius: 10px;
      margin-inline-start: 8px;
      padding: 0 8px;
      background: var(--ion-color-primary);
      color: var(--ion-text-color-reversed);
    }

    span,
    svg {
      color: var(--ion-color-danger-contrast);
    }
  }

  .inuptsWrap {
    padding-top: 16px;

    .inputRowWrap {
      .containerValue {
        &.textAreaValue {
          padding: 0 16px;

          .textArea {
            width: 100%;

            ion-textarea {
              margin: 0;
              --padding-top: 0;

              textarea {
                padding: 16px;
              }
            }
          }
        }

        .radio {
          --inner-border-width: 0;
          --min-height: 38px;

          ion-label {
            margin: 0;
            margin-left: 16px;
          }

          ion-radio {
            margin: 0;
          }
        }
      }
    }
  }
}

.genericContainer {
  border-radius: 1.5px;
  margin-bottom: 16px;
  padding-bottom: 0;
  background-color: var(--ion-color-light);
  border: 1px solid var(--ion-border-color);
}

.genericName {
  align-items: center;
  margin: -16px -16px 0;
  padding: 16px;
  padding-bottom: 16px;

  &.genericLoading {
    background-color: var(--ion-border-color);
  }

  &.expanded {
    margin-bottom: 16px;
  }

  & > ion-col {
    padding: 0;
  }

  .genericColumn {
    display: flex;
    align-items: center;
  }

  .genericTitle > div {
    margin: 0;
    padding: 0;
  }

  .sectionLoaderWrap {
    display: inline-block;
    padding-right: 16px;
  }

  ion-button {
    width: 20px;
    height: 20px;
  }

  .removeSection {
    margin-inline-start: 8px;
    border-radius: 8px;

    svg {
      color: var(--ion-color-danger) !important;
    }
  }
}

.genericAddSection {
  justify-content: center;
}

.resetRow {
  .button {
    margin-left: 2px;
    margin-right: 2px;

    span {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
