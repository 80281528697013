.employeeCard {
  --min-height: 72px;

  .employeeRow {
    flex-direction: column;
  }

  &:first-child {
    border-top: 1px solid var(--ion-border-color);
  }

  .withDescription {
    margin-bottom: 4px;
  }

  &.selected {
    --background: var(--mipro-color-pale-blue);
  }
}

.employeeListName {
  font-weight: 600;
  margin-inline-end: 4px;
}