@import 'theme/mixins';
.openQuotesPage {
  .filter {
    .filterButton {
      height: 30px;
    }
  }
  .headerClass {
    ion-buttons {
      ion-button {
        min-height: auto;
      }
    }
    .filter {
      .filterButton {
        &.filterNone {
          background-color: var(--ion-color-dark);
          border-color: var(--mipro-color-white);
          border: 1px solid var(--mipro-color-white);
          svg,
          span {
            color: var(--mipro-color-white) !important;
          }
        }
      }
    }
  }
  .openQuotesWrapper {
    --background: var(--mipro-color-white);
    .refresher {
      margin: -16px -16px 16px;
    }
    .table {
      padding-inline-start: 0px;
      .td,
      .th {
        border-right: 1px solid var(--mipro-color-light-gray);
        &:not(:nth-child(1)) > div {
          min-width: 130px !important;
        }
      }
      .th {
        color: var(--mipro-color-dark-text);
      }
      .th:first-child > div,
      .td:first-child > div {
        justify-content: left;
      }
      .td:first-child > div {
        margin-inline-start: 8px;
      }
      .td {
        span {
          white-space: break-spaces;
          word-break: break-word;
        }
      }

    }
    .column {
      text-align: center;
      font-size: 13px;
    }
    .poColumn {
      @include textEllipsis(2);
    }
    .warningMessage {
      margin: 16px;
    }
    .header {
      padding: 16px 0;
      .flex {
        display: flex;
      }
      .customHeader {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        gap: 4px;
        margin-top: 8px;
      }
    }
    .filterCol {
      position: absolute;
      top: 0;
    }
  }
  .reportBtn {
    span {
      font-size: 13px;
      font-weight: 400;
    }
  }
  .customerBtn {
    span {
     text-align:  left;
     line-height: 14px;
    }
  }
  .ocnButton {
    padding: 0px;
  }
  .filterCol {
    text-align: end;
  }
  .filterButton {
    padding: 8px 10px;
    border-radius: 25px;
    background-color: var(--ion-color-primary);
    &.hasFilters {
      background: var(--mipro-color-pale-blue);
      border: none;
      svg {
        color: var(--mipro-color-dark-text) !important;
      }
      &:hover svg {
        color: var(--mipro-color-dark-text);
      }

      span {
        border-radius: 50%;
        height: 14px;
        width: 14px;
        background: var(--mipro-color-primary);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.filterNone {
      background-color: var(--ion-item-background);
      border: 1px solid var(--ion-color-light-contrast);

      span,
      svg,
      img {
        color: var(--mipro-color-dark-text) !important;
      }
    }

    span,
    svg,
    img {
      font-weight: normal;
      font-size: 12px;
      color: var(--ion-color-primary-contrast) !important;
    }

    svg {
      font-size: 15px;
    }
  }
}
