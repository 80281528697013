.offlineBanner {
  --padding-top: 0;
  --padding-bottom: 0;
  background: var(--ion-item-background);
  border-bottom: 1px solid var(--ion-border-color);

  .wrapper {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
