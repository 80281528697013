.warningMessage,
.loader {
  margin: 16px;
}

.item {
  --padding-top: 8px;
  --padding-bottom: 8px;

  .addButton,
  .selectButton {
    text-decoration: none;

    span {
      font-size: 13px;
      text-transform: uppercase;
    }
  }

  .selectButton {
    height: 40px;
    width: 80px;
    margin-inline-start: 16px;
    --background: var(--ion-color-success);
  }

  .addButton {
    width: 30px;
    min-width: 30px;
    margin-inline-end: 16px;
  }

  .disableButton {
    &.selectButton {
      --background: var(--ion-text-color-unselected);
    }

    span {
      color: var(--ion-text-color) !important;
    }
  }

  ion-button::part(native):focus {
    text-decoration: none;
  }

  &.selectedItem {
    --action-row-background: rgba(var(--ion-color-success-rgb), 0.05);

    span {
      font-weight: 600;
    }

    ion-button {
      svg {
        color: var(--ion-color-success) !important;
      }
    }

    .selectButton {
      pointer-events: none;
      --background: none;
    }
  }

  .wrapper {
    width: 100%;

    .inner {
      flex: 1;
    }

    & > ion-row {
      flex-wrap: nowrap;
    }
  }

  .titleRow {
    padding: 0;
    display: flex;
    align-items: center;
  }

  .title {
    flex: 1;
    text-transform: uppercase;
    color: var(--ion-text-color-dark);
  }

  .detailsButton {
    margin-inline-start: 46px;
  }

  .details {
    width: 100%;
    margin-top: 8px;
    margin-inline-start: 46px;
  }

  .detailRow {
    padding: 0;
    min-height: unset;

    span {
      font-size: 13px;
    }
  }
}

// TODO: make this modal wrapper reusable
.modal {
  --backdrop-opacity: 0.7;
  --background: var(--ion-item-background);

  &::part(handle) {
    top: 18px;
    width: 80px;
    background: var(--color-modal-handle);
  }

  .title {
    font-family: var(--ion-font-family);
    color: var(--ion-text-color-dark);
  }

  .headerRow {
    padding: 40px 16px 0;
    align-items: center;
    justify-content: space-between;

    & > div {
      width: 40px;
      display: flex;
      align-items: center;
    }
  }

  .content {
    --background: var(--ion-item-background);
    --padding-start: 16px;
    --padding-end: 16px;
    --padding-top: 16px;
    --padding-bottom: 16px;
  }
}

.quantityWrapper {
  margin-top: 16px;

  .input {
    margin-top: 16px;
  }

  .errorMaxQuantity {
    color: var(--ion-color-danger);
  }
}

.quantityInputWrapper {
  width: auto;

  & > ion-item {
    width: 80px;
  }
}

.quantityInput {
  display: flex;
  align-items: center;
  justify-content: center;

  .button {
    margin: 0 16px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.buttonWrapper {
  margin-top: 16px;

  ion-button {
    width: 100%;
  }
}
