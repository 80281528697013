@import 'theme/mixins';

.item {
  border-bottom: 1px solid var(--ion-border-color);
  --action-row-background: var(--ion-item-background);
  --min-height: 58px;
  --background: var(--action-row-background);
  --background-focused: var(--action-row-background);
  --background-focused-opacity: 1;
  --background-hover: var(--action-row-background);
  --background-hover-opacity: 1;
  --ripple-color: transparent;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 16px;
  --padding-end: 16px;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;

  &.withAction {
    cursor: pointer;
  }

  .alerts {
    color: var(--ion-color-danger);
  }
}

.endRow {
  margin-inline-start: 16px;
}

.endRowWithAlerts {
  margin-inline-start: 8px;
}

.arrow {
  svg {
    color: var(--ion-color-primary);
  }
}

.leftButtonWrapper {
  margin-inline-end: 16px;
}

.rightButtonWrapper {
  margin-inline-start: 16px;
}

.leftButton,
.rightButton {
  position: relative;
  @include increaseTouchTargetSize(50%, 0, 50%);
}

.toggle {
  height: 24px;
  width: 32px;
  padding: 0;
  --handle-width: 20px;
  --handle-height: 20px;
  --background: var(--ion-color-medium);
}
