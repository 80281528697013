.formLoader {
  position: fixed;
}

.formWrapper {
  margin-top: 16px;
  padding: 16px;
  background: var(--mipro-color-white);

  .input,
  .attachments {
    margin-top: 16px;
  }

  .addRecipientButton {
    padding-inline-start: 4px;

    svg {
      font-size: 14px;
    }
  }
}

.content {
  .header {
    padding: 16px 0;
    background: var(--mipro-color-white);
  }
}
