.salesPlaysDetailPage {
  .content {
    --padding-top: 0;
    --padding-bottom: var(--wrapper-margin);
    --padding-end: var(--wrapper-margin);
    --padding-start: var(--wrapper-margin);
  }

  .positiveText {
    color: var(--ion-color-success) !important;
  }

  .negativeText {
    color: var(--ion-color-danger) !important;
  }

  .pick12Icon {
    width: 13px;
    height: 16px;
    margin-right: 4px;
  }
}

.headerRow {
  padding: 0 16px;
  margin-bottom: 16px;
  align-items: center;
}

.loader {
  margin-block-start: var(--wrapper-margin);
}

.card {
  margin-inline-start: 0;
  margin-inline-end: 0;

  ion-col {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  .imgRow {
    justify-content: center;
  }

  .section {
    border-top: 1px solid var(--ion-border-color);
    align-items: center;

    &:first-child {
      border: none;
    }
  }


  .lastRow {
    margin-bottom: 16px;
  }

  .rowSeparator {
    margin-bottom: 8px;
  }

  .cardContent {
    padding-top: 16px;
  }



}

.notActiveRow {
  align-items: center;

  .notActive {
    color: var(--ion-color-danger);
  }

  .notActiveText {
    color: var(--ion-color-danger);
    margin-inline-start: 4px;
  }
}

.blockHeading {
  display: block;
  margin-top: 16px;
  margin-block-start: var(--wrapper-margin);
}

.pick12Wrapper {
  display: flex;
}

.customerAddress {
  font-size: 12px;
  line-height: 16px;
  color: var(--ion-text-color-unselected);
}

.img {
  object-fit: contain;
  height: 150px;
}

.productName,
.companyName {
  text-align: left;
  white-space: normal;

  svg {
    display: flex;
    font-size: 12px;
  }
}

.companyName {
  font-size: 20px;
  font-family: var(--ion-font-family-alt);
  font-weight: 600;
  color: var(--mipro-color-primary);
}

.pick12Icon {
  top: 2px !important;
}

.addressText {
  font-size: 12px;
  font-weight: 500;
  color: var(--ion-text-color-unselected);
  text-transform: capitalize;
}

.companyInfo,
.salesPlay {
  ion-row {
    place-items: start start;
  }

  ion-col {
    display: flex;
    padding-block-start: 0;
    padding-block-end: 0;
  }
}

.footer {
  background: var(--ion-item-background);

  ion-toolbar {
    --padding-top: var(--wrapper-margin);
    --padding-bottom: var(--wrapper-margin);
    --padding-start: var(--wrapper-margin);
    --padding-end: var(--wrapper-margin);
    --background: transparent;
  }

  ion-button {
    flex: 1;
  }

  .acceptButton {
    margin-inline-end: var(--wrapper-margin);
    --border-color: var(--ion-color-secondary);
  }
  .declineButton {
    --border-color: var(--ion-color-secondary);
    --ion-color-primary: var(--ion-color-secondary);
  }
}

.industryCodes {
  padding-top: 16px;
}


