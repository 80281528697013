@import 'theme/mixins';

.inputDiv {
  scroll-margin-top: calc(
    71px + var(--ion-safe-area-top)
  ); // --min-height: 67px in Header.module.scss
  width: 100%;
}

.input {
  --min-height: auto;
  --highlight-height: 0;
  --background: transparent;
  --background-activated: transparent;
  --background-activated-opacity: 1;
  --background-focused: transparent;
  --background-focused-opacity: 1;
  --background-hover: transparent;
  --background-hover-opacity: 1;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;

  ion-col {
    --ion-grid-column-padding: 0;
  }

  ion-input {
    height: 40px;
    --padding-top: 11px;
    --padding-bottom: 11px;
    --padding-start: 16px !important;
    --padding-end: 16px;
    --placeholder-color: var(--ion-color-medium);
    --placeholder-opacity: 1;
  }

  .label {
    line-height: 18px;
    max-width: unset;
  }
  .requiredLabel {
    color: var(--mipro-color-light-red);
  }

  .withRightButton {
    ion-input {
      --padding-end: 0;
    }
  }

  .withLeftButton {
    ion-input {
      --padding-start: 0;
    }
  }

  .wrapper {
    margin-top: 8px;
    border: 1px solid var(--ion-text-color-light);
    align-items: center;
    background: var(--ion-item-background);
    flex-wrap: nowrap;
    ion-button {
      margin: 0 16px;
      z-index: 2;
    }

    ion-button svg {
      color: var(--ion-color-medium);
    }

    // handle focus of child input
    &:focus-within {
      border-color: var(--ion-color-primary);
    }
  }

  &.withoutLabel {
    .wrapper {
      margin-top: 0;
    }

    ion-row ion-input {
      --padding-start: 16px;
    }

    .withRightButton {
      ion-input {
        --padding-end: 0;
      }
    }

    .withLeftButton {
      ion-input {
        --padding-start: 0;
      }
    }
  }

  &.error {
    .label,
    ion-input {
      color: var(--ion-color-danger);
    }

    ion-button svg {
      color: var(--ion-color-danger);
    }

    .wrapper {
      margin-bottom: 4px;
    }

    ion-row {
      border-color: var(--ion-color-danger);
    }
  }

  &.disabled {
    .wrapper {
      border-color: var(--ion-border-color);
    }

    &.inputNotes {
      ion-input {
        --color: var(--ion-text-color);
        opacity: 0.8 !important;
      }

      ion-label {
        opacity: 0.8 !important;
        .label {
          opacity: 0.8 !important;
          color: var(--ion-text-color);
        }
      }
    }

    ion-label {
      opacity: 1 !important;

      .label {
        color: var(--ion-color-medium);
      }
    }

    ion-input {
      opacity: 1;
      --color: var(--ion-text-color);

      input {
        opacity: 0.6 !important;
      }
    }
  }

  .charCounter {
    justify-content: flex-end;
    margin-top: 8px;
    font-size: 14px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.errorMessage {
  color: var(--ion-color-danger);
}

.leftButton,
.rightButton {
  position: relative;
  @include increaseTouchTargetSize(50%, 0, 50%);
}
