@import 'theme/mixins';

.content {
  --padding-top: 16px;
  --padding-bottom: 16px;
}

.exceptionBtn {
  margin: 0 16px 16px;
  position: relative;
  @include increaseTouchTargetSize(50%, 0, 50%);
}

.sectionTitle {
  padding: 16px 16px 8px 16px;
  display: inherit;
}

.sectionWrapper {
  border-top: 1px solid var(--ion-border-color);
  border-bottom: 1px solid var(--ion-border-color);

  div {
    margin: 16px;

    span:first-child {
      margin-right: 8px;
    }
  }
}

.exceptions {
  .exceptionsTable {
    padding-top: 20px;
  }

  ion-searchbar {
    --border-radius: 0;
    --background: var(--ion-color-light);
    --color: var(--ion-color-light-shade);
    --placeholder-color: var(--ion-color-medium);
    --placeholder-font-style: italic;
    --icon-color: var(--ion-color-dark-tint);
    margin: 0 auto;
    flex-basis: 32px;
  }
}

.loader {
  margin-top: 16px;
}

.warningMessage {
  margin: 0px 16px;
}
