.contactModalWrapper {
  ion-item {
    ion-button {
      span {
        color: var(--mipro-color-dark-text) !important;
        margin-inline-start: 8px;
        font-weight: 500;
      }
      svg {
        font-size: 14px;
        width: 14px;
      }
    }
  }
  .emailRow {
    ion-button {
      white-space: pre-wrap;
      text-align: justify;
    }
  }
}

.actionRow {
  ion-item {
    --padding-end: 0;
    --padding-start: 0;
  }

  &:last-child ion-item {
    border: none;
  }
}

.customTitle {
  flex: 1 1;
  span {
    white-space: break-spaces;
    word-break: break-all;
  }
}
