.card {
  background-color: var(--mipro-color-white);
  margin-top: 10px;
  padding: 16px;
  .listWrapper {
    --padding-start: 0px;
    --pading-end: 0px;
    --padding-top: 16px;
    border-bottom:  none;
    .title span{
      color: var(--ion-text-color-light);
    }
    .nameBlock {
      display: flex;
      align-items: center;
      margin-top: -8px;
      .name {
        font-weight: 700;
        color: var(--ion-text-color-dark);
      }

    }
    .flex {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-right: 16px;
      span {
        font-size:  12px;
        color: var(--ion-text-color-light);
      }
    }
  }
}


