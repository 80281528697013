.action_card_decoration__wrapper {
  position: absolute;
  top: -16px;
  left: -16px;
}

.action_card_decoration__triangle {
  height: 0;
  width: 0;
  border-top: 28px solid;
  border-right: 28px solid transparent;
}

.action_card_decoration__icon {
  position: absolute;
  top: 3px;
  left: 3px;
  color: white;
  font-size: 9px !important;
}

.triangle_primary {
  border-top-color: var(--ion-color-primary);
}

.triangle_success {
  border-top-color: var(--ion-color-success);
}

.triangle_danger {
  border-top-color: var(--ion-color-danger);
}

.triangle_secondary {
  border-top-color: var(--ion-color-secondary);
}
