@import 'theme/mixins';

.container {
  .content {
    --padding-top: 0;
    --padding-bottom: 16px;
    --padding-start: 0;
    --padding-end: 0;
    background-color: var(--ion-color-secondary-contrast);
  }

  .detailsContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 16px;
    padding: 0px;

    .rowWrapper {
      display: flex;
      width: 100%;
      border-top: 1px solid var(--ion-border-color);
      margin-top: 8px;
      padding-top: 8px;
      &:first-child {
        border-top: none;
        margin-top: 0;
        padding-top: 0;
      }
      .detailsWrapper {
        width: 50%;

        .title {
          display: inline-block;
          margin-bottom: 4px;
          text-transform: uppercase;
        }

        .mainContactWrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .mainContentNumber {
            margin-bottom: 8px;
          }

          .mainContentEmail {
            position: relative;
            @include increaseTouchTargetSize(50%, 0, 50%);
          }
        }
      }
    }
  }

  .loader {
    margin-top: 16px;
  }
}

.reportStatus {
  font-size: 11px;
  margin: 8px 16px 0 16px;
  ion-col {
    padding-left: 0;
    padding-right: 0;
  }
}

.titleLine {
   margin: 4px 0 0 16px;
}

.externalLinks {
  --wrapper-margin--: 0;
  @include horizontalScroller();
  grid-auto-columns: auto;
}

.linkedCard {
  margin: 0;
}

.locationWrapper {
  margin: 16px;
}

