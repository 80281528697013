.inputDiv {
  scroll-margin-top: calc(71px + var(--ion-safe-area-top)); // --min-height: 67px in Header.module.scss
  width: 100%;
}

.textareaContainer {
  --highlight-height: 0;
  --background: transparent;
  --background-activated: transparent;
  --background-activated-opacity: 1;
  --background-focused: transparent;
  --background-focused-opacity: 1;
  --background-hover: transparent;
  --background-hover-opacity: 1;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;

  ion-col {
    --ion-grid-column-padding: 0;
  }

  ion-textarea {
    --padding-top: 11px;
    --padding-bottom: 11px;
    --padding-start: 16px;
    --padding-end: 16px;
    --placeholder-color: var(--ion-color-medium);
    --placeholder-opacity: 1;
    textarea {
      height: 175px;
    }
    white-space: pre-wrap;
  }

  .label {
    line-height: 18px;
  }

  .requiredLabel {
    color: var(--mipro-color-light-red);
  }

  &.withoutLabel {
    .textareaWrapper {
      margin-top: 0;
    }
  }

  &.error {
    .label,
    ion-input {
      color: var(--ion-color-danger);
    }

    .icon,
    ion-button svg {
      color: var(--ion-color-danger);
    }

    .textareaWrapper {
      margin-bottom: 4px;
    }

    ion-row {
      border-color: var(--ion-color-danger);
    }
  }


  &.disabled {
    .textareaWrapper {
      border-color: var(--ion-border-color);
    }

    &.opacityDisabled {
      opacity: 0.8 !important;
      .label {
        opacity: 0.8 !important;
        color: var(--ion-text-color);
      }
      textarea {
        opacity: 0.8;
        color: var(--ion-text-color);
      }
    }

    ion-label {
      opacity: 1 !important;

      .label {
        color: var(--ion-color-medium);
      }
    }

    .icon {
      opacity: 0.6;
    }

    ion-input {
      --color: var(--ion-color-medium);

      input {
        opacity: 1 !important;
      }
    }
  }

  .textareaWrapper {
    margin-top: 8px;
    border: 1px solid var(--ion-color-light-shade);
    background: var(--ion-item-background);

    // handle focus of child input
    &:focus-within {
      border-color: var(--ion-color-primary);
    }
  }

  .errorMessage {
    color: var(--ion-color-danger);
  }
}
