.isUpdating {
  background: var(--mipro-color-deselected) !important;
  --background: var(--mipro-color-deselected) !important;
  --action-row-background: var(--mipro-color-deselected) !important;
}

.commentCard {
  --padding-start: 16px;
  --padding-end: 16px;
  border: none;
}

.commentItem {
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid var(--ion-border-color);

  .commentInfo {
    justify-content: space-between;

    ion-row {
      gap: 4px;
      align-items: flex-end;

      .editedLabel {
        line-height: normal;
        color: var(--ion-text-color-light);
      }
    }

    .others {
      span {
        color: var(--mipro-color-light-blue);
      }
    }

    .owner {
      span {
        color: var(--mipro-color-dark-text);
      }
    }
  }

  .commentText {
    span {
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}

.timeStamp {
  display: flex;
  align-items: center;
}

.buttonWrapper {
  border-bottom: 1px solid var(--ion-border-color);
  --padding-start: 0;

  .editButton {
    svg {
      color: var(--mipro-color-light-blue);
    }
  }

  .deleteButton {
    svg {
      color: var(--mipro-color-dark-red);
    }
  }

  &:last-child {
    border-bottom: 0;
  }
}
