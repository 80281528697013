@import 'theme/mixins';

.formLine {
  --min-height: 26px;
  --padding-top: 0;
  --padding-bottom: 16px;
  --padding-start: 0;
  --padding-end: 0;
  --border-color: transparent;
  --background: transparent;
  --background-activated: transparent;
  --background-activated-opacity: 1;
  --background-focused: transparent;
  --background-focused-opacity: 1;
  --background-hover: transparent;
  --background-hover-opacity: 1;

  &:last-child {
    --padding-bottom: 0;
  }

  ion-label {
    margin: 0;
    z-index: 2;
  }

  .sameUser {
    color: var(--ion-color-success);
  }

  .radio {
    display: flex;
  }

  .labelWrapper {
    .label,
    .requiredLabel {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: var(--mipro-color-dark);
    }

    .requiredLabel {
      margin-inline-start: 4px;
      color: var(--mipro-color-secondary);
    }
  }

  .dropdownText {
    justify-content: flex-end;

    .separator {
      margin-inline-end: 4px;
    }
  }

  .contactButton {
    position: relative;
    @include increaseTouchTargetSize(50%, 0, 50%);
  
    span {
      text-decoration: underline;
    }
  
    &:first-child {
      margin: 0;
    }
  }
}
