@import 'theme/mixins';

.modalHeaderToolbar {
  display: flex;
  padding: 16px;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--ion-safe-area-top);
}

.modalHeaderIcons {
  display: grid;
  grid-gap: 24px;
  grid-auto-flow: column;
  flex-direction: row;
  margin-inline-start: auto;
}

.downloadButton,
.closeButton {
  position: relative;
  @include increaseTouchTargetSize();
}

.downloadIcon {
  color: var(--ion-text-color);
}

.modal {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --overflow: hidden !important;
}

.imageContainer {
  overflow: auto;
  max-width: 100vw;
  max-height: 100vh;
  background-color: var(--ion-backdrop-color);
}

.zoomWrapper {
  width: 100vw;
  height: calc(100vh - 46px) !important;
}

.loader {
  top: 50%;
  left: 50%;
  z-index: 1;
  position: absolute;
  padding: 18px 14px;
  border-radius: 10px;
  transform: translate3d(-50%, -50%, 0);
  background: var(--ion-background-color);
}
