@import 'theme/mixins';

.mainHeader {
  height: 85px;
  --background: var(--ion-color-light-tint);
  --padding-start: 16px;
  --padding-end: 16px;
  --inner-padding-end: 0;
  --inner-border-width: 0;
}

.subHeaderText {
  font-size: 20px;
  vertical-align: middle;
  text-transform: uppercase;
  text-decoration: none !important;
  opacity: 1;

  &.locationWithoutLink span {
    color: var(--mipro-color-dark-text) !important;
  }

  span {
    font-family: var(--ion-font-family-alt);
    font-size: 20px;
    text-transform: uppercase !important;
    margin-inline-end: 4px;
    text-decoration: none;
  }
}

.headerCol {
  flex-grow: 2;
  min-width: 60%;
  padding: 16px 0;
}


.headerWrapper {
  display: flex;
  align-items: center;
  .headerImage {
    width: 36px;
    padding-right: 5px;
  }
  .button {
    position: relative;
    @include increaseTouchTargetSize(50%, 0, 50%);
    .helpInfoIcon {
      padding-inline-start: 8px;
    }
  }
}

.withEmployeeName {
  height: 100px;
}
