.headerContainer {
  margin-bottom: 16px;

  .headerSection {
    width: 50%;
  }

  .headerWrapper {
    align-items: center;
  }

  .rightRow {
    justify-content: flex-end;
  }

  .headerText {
    text-transform: uppercase;
  }

  .change {
    margin-left: 4px;
    padding-inline-start: 12px;
  }

  .noChange {
    margin-left: 4px;
  }

  .skeletonRow {
    flex-flow: nowrap;
    align-items: center;
  }

  .valueSkeleton {
    width: 70px;
    margin: 0;
    height: 26px;
  }

  .changeSkeleton {
    width: 50px;
    margin: 0;
    margin-inline-start: 4px;
    height: 20px;
  }
}
