.content {
  --padding-start: 16px;
  --padding-end: 16px;

  .titleRow {
    margin-top: 120px;
    text-align: center;
  }

  .buttonWrapper {
    margin-top: 36px;

    .button {
      width: 100%;

      &.disabled {
        opacity: 1;
        --background: var(--mipro-color-disabled-tint);

        span {
          color: var(--mipro-color-white);
        }
      }
    }
  }
}
