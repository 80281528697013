.orderLineShippingSection {
  margin-top: 16px;
  padding: 16px;
  background-color: var(--mipro-color-white);

  .title {
    display: block;
    margin-bottom: 16px;
  }

  table {
    border: none;
    tr{
      border: none;
      td {
        padding: 16px;
        ion-button {
          white-space: pre-wrap;
          word-break: break-word;
          text-align: left;
        }
      }
    }
    tr:nth-of-type(2n) {
      background: var(--mipro-color-background);
    }
  }

  .tracking {
    justify-content: space-between;
    svg {
      color: var(--mipro-color-light-blue);
      width: 16px;
      height: 19px;
    }
    .noTrackingStatus {
      span {
        color: var(--mipro-color-dark-gray);
      }
    }
  }

  .podOrTracking {
    vertical-align: top;
    td:last-child {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    div {
      display: flex;
    }
  }

  .flex {
    display: flex;
    gap: 8px;
    align-items: center;
    img {
      min-height: 20px;
    }
  }
}
