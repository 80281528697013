.loader {
  margin: 16px 0;
}

.title {
  display: block;
  text-transform: uppercase;
  padding: 16px;
}

.seeMoreButton {
  margin: 12px 16px 0;
}

.contentMessage {
  margin-inline-start: 16px;
  margin-inline-end: 16px;
}
