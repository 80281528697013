.modelLabel {
  z-index: 30000;
  position: fixed;
  width: 200px;
  left: -75px;
  bottom: 10px;
  padding: 4px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  transform: rotate(45deg);
  background: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
  opacity: 60%;
  pointer-events: none;
}
