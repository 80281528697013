@import 'theme/mixins';

.documentList {
  .warningMessage {
    margin: 16px;
  }

  .documentSearchRow {
    padding: 0 16px;
    margin-bottom: 16px;

    .searchbar {
      flex: 1;
    }

    .filters {
      margin-inline-start: 16px;
    }
  }

  .documentListWrap {
    padding: 0;
  }
}

.addDocumentRow {
  width: 100%;
  border: none;

  span {
    color: var(--ion-color-primary);
  }
}

.filterWrapper {
  @include stickyScroller();
  padding: 0;
  top: 0;
}

.loaderWrap {
  padding-top: 16px;
}

.footer {
  border-top: 1px solid var(--ion-border-color);
  background: var(--ion-item-background);

  ion-row {
    padding: 16px;
  }

  ion-button {
    flex: 1;
  }
}
