.list > :nth-child(1) {
  z-index: 2 !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  ion-title {
    width: auto;
    text-align: left;
    position: relative;
  }
  .filter {
    ion-button {
      display: flex;
      align-items: center;
      min-height: 30px !important;
      height: 30px !important;
      padding-inline: 8px;
      border-radius: 24px;
      border: 1px solid var(--mipro-color-dark-text);
      svg {
        width: 14px;
        height: 14px;
      }
      span {
        font-size: 12px;
      }
    }
    svg,
    span {
      color: var(--mipro-color-dark-text) !important;
    }
  }
  .headerActions {
    padding-inline: 0;
    width: 18px;
    padding-block-start: 16px;
  }
}

.filterButton {
  min-height: auto;
  padding: 8px 10px;
  border-radius: 25px;
  background-color: var(--ion-color-primary);
  height: 30px;
  &.hasFilters {
    background: var(--mipro-color-pale-blue);
    border: none !important;
    span {
      align-items: center;
      background: var(--mipro-color-primary);
      border-radius: 50%;
      display: flex;
      height: 14px;
      justify-content: center;
      width: 14px;
      color: var(--mipro-color-white) !important;
    }
    svg {
      color: var(--mipro-color-dark-text) !important;
    }
    &:hover svg {
      color: var(--mipro-color-dark-text);
    }
  }

  &.filterNone {
    background-color: var(--ion-item-background);
    border: 1px solid var(--ion-color-light-contrast);

    span,
    svg,
    img {
      color: var(--mipro-color-dark-text) !important;
    }
  }

  span,
  svg,
  img {
    font-weight: normal;
    font-size: 12px;
    color: var(--ion-color-primary-contrast) !important;
  }

  svg {
    font-size: 15px;
  }
}
