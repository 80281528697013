@import 'theme/mixins';

.header {
  margin: 16px 0;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.pageHeader {
  .actionRowLink {
    margin: 0 -16px;
  }

  .filter {
    .filterButton {
      &.filterNone {
        background-color: var(--ion-color-dark);
        border-color: var(--mipro-color-white);
        border: 1px solid var(--mipro-color-white);
        svg,
        span {
          color: var(--mipro-color-white) !important;
        }
      }
    }
  }
}

.actionRowLink {
  --height: 40px;
  --min-height: 40px;
  opacity: 1;

  .customerName {
    margin: 8px 0;
  }

  .linkButton {
    height: 40px;
    padding-inline-start: 5px;
    --border-width: 0px;

    span {
      width: 100%;
      font-size: 16px;
      font-weight: 700;
      font-family: var(--ion-font-family-alt);
      padding-inline-start: 5px;
      text-align: start;
      text-transform: uppercase;
      @include textEllipsis();
    }
    svg {
      height: 15px;
      width: 18px;
    }
  }
}

.stickyHeader {
  background: var(--ion-item-background);
  position: sticky;
  top: 0;
  z-index: 5;
}

.loader,
.warningMessage {
  margin: 16px;
}


.drilldownTable {
  background: var(--mipro-color-white);
  padding-inline-start: 0px !important;
  --background: var(--mipro-color-white);

  .tableTH,
  .tableTD {
    border-right: 1px solid var(--mipro-color-light-gray);
    color: var(--mipro-color-dark-text);
  }

  .tableTH > div {
    min-width: 90px;
  }


  .tableTH:not(:first-child) > div {
    min-width: 130px;
  }

  .tableTD:not(:first-child) > div {
    min-width: 130px;
  }

  .tableTD:first-child > div {
    justify-content: left;
  }

  .rowLink {
    width: 90px;

    ion-button {
      width: 90px;
      font-size: 15px;
      font-weight: bold;
      font-family: var(--ion-font-family-alt);
      color: var(--ion-color-primary);

      span {
        white-space: break-spaces;
        word-break: break-word;
      }
    }

    span {
      width: 100%;
      text-align: left;
    }
  }

  .icon {
    height: 15px;
    color: var(--ion-color-primary);
  }
}


.filterCol {
  text-align: end;
}


.link {
  ion-button {
    span {
      color: var(--ion-color-primary) !important;
    }
  }
}

.filter {
  .filterButton {
    height: 30px;
    padding: 0px 10px;
    border-radius: 25px;
    background-color: var(--ion-color-primary);
    &.hasFilters {
      background: var(--mipro-color-pale-blue);
      border: none;
      svg {
        color: var(--mipro-color-dark-text) !important;
      }
      &:hover svg {
        color: var(--mipro-color-dark-text);
      }

      span {
        border-radius: 50%;
        height: 14px;
        width: 14px;
        background: var(--mipro-color-primary);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.filterNone {
      background-color: var(--ion-item-background);
      border: 1px solid var(--ion-color-light-contrast);

      span,
      svg,
      img {
        color: var(--mipro-color-dark-text) !important;
      }
    }

    span,
    svg,
    img {
      font-weight: normal;
      font-size: 12px;
      color: var(--ion-color-primary-contrast) !important;
    }

    svg {
      font-size: 15px;
    }
  }
}

.dropdown {
  display: flex;
  justify-content: flex-start;
  margin-top: 4px;
  .dropdownButton {
    span {
      font-size: 11px;
    }
    svg {
      font-size: 10px;
      margin-inline-start: 2px;
    }
  }
}


