.toast {
  --button-color: var(--ion-color-light);

  &::part(message) {
    white-space: pre-wrap;
    max-height: 50vh;
    overflow-y: auto;
  }
}

.mipro-toast {
  box-shadow: 0px 2px 26px 0px rgba(32, 32, 32, 0.2);
  --border-width: 1px;
  --border-style: solid;
  --border-radius: 6px;
  --color: var(--mipro-color-dark-text);

  &::part(button) {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color);
  }

  &.info {
    --button-color: var(--mipro-color-white);
    --background: var(--mipro-color-pale-blue);
    --border-color: var(--mipro-color-dark-blue);

    &::part(icon) {
      color: var(--mipro-color-light-blue);
      padding-top: 14px;
    }

    &::part(button cancel) {
      color: var(--mipro-color-primary-shade);
    }
  }

  &.alert {
    --button-color: var(--mipro-color-dark-orange);
    --background: var(--mipro-color-light-orange);
    --border-color: var(--mipro-color-dark-orange);

    &::part(button cancel) {
      color: var(--mipro-color-dark-orange);
    }
  }

  &.warn {
    --button-color: var(--mipro-color-dark-orange);
    --background: var(--mipro-color-light-orange);
    --border-color: var(--mipro-color-dark-orange);

    &::part(button cancel) {
      color: var(--mipro-color-dark-orange);
    }
  }

  &.error {
    --button-color: var(--mipro-color-dark-red);
    --background: var(--mipro-color-pale-red);
    --border-color: var(--mipro-color-dark-red);

    &::part(container) {
      margin-inline: 8px;
    }

    &::part(header) {
      color: var(--mipro-color-dark-text);
    }

    &::part(button) {
      padding-top: 14px;
      padding-bottom: 14px;
      padding-inline: 0;
    }

    &::part(button cancel) {
      color: var(--mipro-color-dark-red);
    }
  }

  &.success {
    --button-color: var(--ion-color-success);
    --background: #e0f2d8;
    --border-color: var(--ion-color-success);

    &::part(button cancel) {
      color: var(--ion-color-success);
    }
  }

  &::part(header) {
    font-family: var(--ion-font-family);
    font-size: 15px;
    font-weight: 700;
  }

  &::part(message) {
    font-family: var(--ion-font-family);
    font-size: 14px;
    font-weight: 400;
  }
}
