.partsListPart {
  .documentRow {
    align-items: center;
    padding-bottom: 10px;

    &.titleRow {
      padding-bottom: 0;
      padding-top: 10px;
    }
  }

  .documentCol {
    padding: 0;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 5px;
  }

  .textArea {
    textarea {
      padding-left: 16px;
    }
  }
}

.partTitle {
  display: flex;
  align-items: center;

  .needSyncBadge {
    border-radius: 10px;
    margin-inline-start: 8px;
    padding: 0 8px;
    background: var(--ion-color-primary);
    color: var(--ion-text-color-reversed);
  }
}
