.container {
  .content {
    --padding-top: 0;
    --padding-bottom: 16px;
    --padding-start: 0;
    --padding-end: 0;
    background-color: var(--ion-color-secondary-contrast);
  }

  .detailsContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
    background-color: var(--ion-color-secondary-contrast);

    .firstRowWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 1px solid var(--ion-border-color);
    }

    .snapShotLink {
      justify-content: center;
      padding: 16px;
      border-bottom: 1px solid var(--ion-border-color);
      width: 100%;
      --action-row-background: var(--ion-item-background);
      min-height: 58px;
    }

    .text {
      color: var(--ion-color-medium);
      padding-left: 16px;
    }

    .rowWrapper {
      width: 100%;

      &:first-child {
        padding: 32px 0;
      }

      &:last-child {
        padding-bottom: 8px;
      }

      .buttonWrapper {
        padding-left: 16px;
      }

      .rowItem {
        margin: 6px;
      }

      .contactAvatar {
        width: 80px;
        height: 80px;
        align-self: center;
      }

      .contactFallback {
        background-color: var(--ion-color-tertiary);
        align-self: center;
        font-size: 36px;
        color: var(--ion-color-primary-contrast);
      }

      .contactInfo {
        align-self: center;

        &:first-of-type {
          padding-top: 16px;
        }
      }

      .mainContact {
        color: var(--ion-color-success);
      }

      .titleUpper {
        text-transform: uppercase;
      }

      .buttonTextWrapper {
        display: flex;
        text-align: start;
        white-space: normal;

        .buttonText {
          white-space: pre-line;
          word-break: break-word;
          color: var(--ion-color-primary);
        }
      }
    }

    .contactWrapper {
      color: #0c6cd4;
      margin: 8px;
    }
  }

  .loader {
    margin-top: 16px;
  }
}

.warningMessage {
  margin: 16px;
}
