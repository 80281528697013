.sectionTitle {
  text-transform: uppercase;
}

.data {
  background-color: var(--ion-text-color-reversed);
  border-top: 1px solid var(--ion-border-color);
  border-bottom: 1px solid var(--ion-border-color);

  .title {
    text-transform: uppercase;
  }
}
