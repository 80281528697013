.stockToggle {
  --background: none;
  --inner-padding-bottom: 0;
  --padding-start: 0;

  .toggleWrapper {
    display: flex;
    align-items: center;
  }

  ion-toggle {
    order: -1;
    position: unset;
    padding: 0 16px 0 0;
  }
}
