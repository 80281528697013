.notificationListPage {
  .header {
    display: flex;
  }
  .loader {
    margin: 0;
    padding: 16px;
  }
  .endOfList {
    width: 100%;
    padding: 16px 0;
    justify-content: center;

    span {
      line-height: 14px;
      color: var(--mipro-color-dark-gray);
    }
  }
  .warningMessage {
    padding: 16px;
  }
  .list > :nth-child(1) {
    z-index: 2 !important;
  }
  .divider {
    display: flex;
    padding: 16px 8px 8px;
    background: var(--ion-background-color);
  }
}
