:root {
  // #region design system theme
  --mipro-color-white: #ffffff;
  --mipro-color-black: #000000;

  --mipro-color-light-blue: #1b85f8;
  --mipro-color-dark-blue: #0c6cd4;
  --mipro-color-pale-blue: #dceafd;
  --mipro-color-primary: var(--mipro-color-dark-blue);
  --mipro-color-primary-shade: #0c54a1;
  --mipro-color-primary-tint: var(--mipro-color-light-blue);

  --mipro-color-light-red: #f51900;
  --mipro-color-darker-red: #8f0303;
  --mipro-color-dark-red: #bf1300;
  --mipro-color-pale-red: #ffe5e7;
  --mipro-color-secondary: var(--mipro-color-light-red);
  --mipro-color-secondary-shade: var(--mipro-color-darker-red);
  --mipro-color-secondary-tint: var(--mipro-color-dark-red);

  --mipro-color-dark-green: #1d8747;
  --mipro-color-darker-green: #025f27;
  --mipro-color-light-green: #0aa840;
  --mipro-color-pale-green: #e0f2d8;
  --mipro-color-tertiary: var(--mipro-color-dark-green);
  --mipro-color-tertiary-shade: var(--mipro-color-darker-green);
  --mipro-color-tertiary-tint: var(--mipro-color-light-green);

  --mipro-color-medium-green: #109d3f;
  --mipro-color-success: var(--mipro-color-medium-green);
  --mipro-color-success-shade: #28ba62;
  --mipro-color-success-tint: #42d77d;

  --mipro-color-orange: #ff8a00;
  --mipro-color-dark-orange: #e37b00;
  --mipro-color-light-orange: #fff7ea;
  --mipro-color-warning: var(--mipro-color-orange);
  --mipro-color-warning-shade: var(--mipro-color-dark-orange);
  --mipro-color-warning-tint: var(--mipro-color-light-orange);

  --mipro-color-danger: var(--mipro-color-dark-red);
  --mipro-color-danger-shade: var(--mipro-color-darker-red);
  --mipro-color-danger-tint: var(--mipro-color-pale-red);

  --mipro-color-warning-activity: #FFCA22;

  --mipro-color-dark-text: #202020;
  --mipro-color-dark-text-rgb: 32, 32, 32;
  --mipro-color-dark-gray: #666666;
  --mipro-color-deselected: #c4c4c4;
  --mipro-color-dark: var(--mipro-color-dark-text);
  --mipro-color-dark-shade: var(--mipro-color-dark-gray);
  --mipro-color-dark-tint: var(--mipro-color-deselected);

  --mipro-color-medium-gray: #979797;
  --mipro-color-medium: var(--mipro-color-medium-gray);
  --mipro-color-medium-shade: #808289;
  --mipro-color-medium-tint: #9d9fa6;

  --mipro-color-disabled: #ededed;
  --mipro-color-disabled-shade: #a5a5a5;
  --mipro-color-disabled-tint: #d2d2d2;

  --mipro-color-light-gray: #dadada;
  --mipro-color-background: #f5f7f9;
  --mipro-color-light: var(--mipro-color-light-gray);
  --mipro-color-light-shade: var(--mipro-color-disabled-tint);
  --mipro-color-light-tint: var(--mipro-color-background);

  --mipro-color-purple: #5200ff;
  --mipro-color-unread: #1e76d6;

  // #endregion design system theme

  // #region ionic theme
  // primary action
  --ion-color-primary: var(--mipro-color-primary);
  --ion-color-primary-rgb: 12, 108, 212;
  --ion-color-primary-contrast: var(--mipro-color-white);
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: var(--mipro-color-primary-shade);
  --ion-color-primary-tint: var(--mipro-color-primary-tint);

  // brand accent color
  --ion-color-secondary: var(--mipro-color-secondary);
  --ion-color-secondary-rgb: 245, 25, 0;
  --ion-color-secondary-contrast: var(--mipro-color-white);
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: var(--mipro-color-secondary-shade);
  --ion-color-secondary-tint: var(--mipro-color-secondary-tint);

  --ion-color-tertiary: var(--mipro-color-tertiary);
  --ion-color-tertiary-rgb: 29, 135, 71;
  --ion-color-tertiary-contrast: var(--mipro-color-white);
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: var(--mipro-color-tertiary-shade);
  --ion-color-tertiary-tint: var(--mipro-color-tertiary-tint);

  // Status: positive
  --ion-color-success: var(--mipro-color-success);
  --ion-color-success-rgb: 16, 157, 63;
  --ion-color-success-contrast: var(--mipro-color-white);
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: var(--mipro-color-success-shade);
  --ion-color-success-tint: var(--mipro-color-success-tint);

  // Status: warning
  --ion-color-warning: var(--mipro-color-warning);
  --ion-color-warning-rgb: 255, 138, 0;
  --ion-color-warning-contrast: var(--mipro-color-white);
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: var(--mipro-color-warning-shade);
  --ion-color-warning-tint: var(--mipro-color-warning-tint);

  // Status: negative
  --ion-color-danger: var(--mipro-color-danger);
  --ion-color-danger-rgb: 191, 19, 0;
  --ion-color-danger-contrast: var(--mipro-color-white);
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: var(--mipro-color-danger-shade);
  --ion-color-danger-tint: var(--mipro-color-danger-tint);

  // TODO: can't use mipro-color, ionic uses this for internal settings
  // Text: default content
  --ion-color-dark: #333333;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: var(--mipro-color-white);
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #333333;

  // TODO: can't use mipro-color, ionic uses this for internal settings
  // UI: supporting shapes and labels
  --ion-color-medium: #999999;
  --ion-color-medium-rgb: 153, 153, 153;
  --ion-color-medium-contrast: var(--mipro-color-white);
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  // TODO: can't use mipro-color, ionic uses this for internal settings
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: var(--mipro-color-black);
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #666666;
  --ion-color-light-tint: #f5f6f9;
  // #endregion ionic theme

  --ion-font-family: 'Inter', SansSerif, serif;
  --ion-text-color: #333333; // Text: Default content
  --ion-text-color-reversed: #ffffff;
  --ion-text-color-light: var(--mipro-color-dark-shade);
  --ion-text-color-dark: #000000;
  --ion-text-color-red: var(--mipro-color-secondary-tint);
  --ion-text-color-unselected: var(--mipro-color-medium);
  --icon-background-source: #ffffff;

  --ion-background-color: #f5f5f5;
  --ion-backdrop-color: rgba(0, 0, 0, 0.4);
  --ion-background-separator: #f5f7fa;

  --ion-input-background: #434343;
  --ion-item-background: #ffffff;
  --ion-row-background: #eeeeee;
  --ion-border-color: #d8d8d8;
  --ion-border-separator: #d1cfd7;

  --bar-chart-color: #7a91a9;
  --label-micro-text-size: 12px;
  --refresher-height: 32px;
  --text-default-color: var(--ion-text-color);
  --color-positive: var(--ion-color-success);
  --color-negative: var(--ion-color-danger);
  --color-gray: var(--mipro-color-dark);
  --ion-font-family-alt: 'D-DINCondensed', SansSerif, serif;
  --ion-avatar-size: 40px;
  --message-background: var(--ion-item-background);
  --wrapper-margin: 16px;
  --wrapper-margin--: calc(var(--wrapper-margin) * -1);
  --bulletins-z-index: 20050; // Docs: this should be a higher index than modals (20026)
  --color-approved: #009632;
  --color-rejected: #f80e0e;
  --color-positive-yoy: var(--mipro-color-tertiary-tint);
  --color-negative-yoy: var(--mipro-color-secondary);
  --color-modal-handle: #2c3042;
  --color-help-modal-handle: #d9d9d9;
  --color-annoucement-subtitle: #9aa7ae;

  //
  // Activities: Colors & Icons
  //

  // Incoming Orders & Sales
  --color-upsell: #009632;
  --color-largeOrder: #1d7862;
  --color-largeQuote: #13c263;
  --color-crossSellSalesPlay: #15b7ae;

  // Customer Contact
  --color-webActivity: #0265d2;
  --color-customerVisit: #7306af;
  --color-reengagedCustomerAccount: #00a3ff;
  --color-newCustomerContact: #3633ca;

  // Immediate Attention Required
  --color-overdueSalesPlay: #e4b92f;
  --color-priceOverride: #f80e0e;
  --color-priceOverrideApproved: var(--mipro-color-success);
  --color-priceOverrideRejected: #f80e0e;
  --color-atRiskCustomerAccount: #f8540e;
  --color-lateOrder: var(--mipro-color-warning);
  --color-decliningSalesPlay: #cc0d18;
  --color-sourcingOverride: #e9a31c;
  --color-sourcingOverrideAccepted: var(--mipro-color-success);
  --color-sourcingOverrideRejected: #f80e0e;
  --color-crmOpportunity: #1bb499;
  --color-crmTask: #6d6aff;
  --color-shopQuoteReady: var(--mipro-color-dark-text);

  // Action Toggles - Not Selected
  --color-snoozed: var(--mipro-color-warning-shade);
  --color-followUp: #009632;
  --color-delete: #f80e0e;
  // Action Toggles - Selected
  --color-snoozed-active: #c76f07;
  --color-followUp-active: #05752b;
  --color-delete-active: #d21313;
  // Calendar
  --color-calendar-border: var(--mipro-color-light);
  --color-calendar-button: #6e6e6e;
  --color-disabled-button: #8e8e8e;
  // Table
  --color-table-header-font: #9a99a2;
  --color-table-header-background: #f4f8fa;
  --color-table-header-sorted-background: rgb(217, 235, 255);
  --color-table-sorted-background: rgb(236, 245, 255);

  // Bulletins
  --mipro-color-backdrop: rgb(0, 0, 0, 0.8);
  --mipro-color-gray: #02050f;
  --mipro-color-light-yellow: #ffd469;
  --mipro-color-online-green: #00a556;

  // warning message cost savings
  --mipro-color-alert-warning: var(--mipro-color-warning);

  // list colors
  --mipro-list-background-activated: rgba(220, 234, 253, 0.5);
  --mipro-list-green: var(--mipro-color-success);
  --mipro-list-error: var(--mipro-color-secondary);

  // product list colors
  --mipro-sss-green: #05752b;
  --mipro-sss-blue: var(--mipro-color-primary);
  --mipro-supplier-connect: #edaa3c;

  --mipro-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}
