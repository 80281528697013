.DocumentPartsList {
  display: block;
  margin-bottom: 16px;
  background-color: var(--ion-color-light);

  .p0 {
    padding: 0;
  }

  .containerTitle {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: var(--ion-color-danger);

    span {
      color: var(--ion-color-danger-contrast);
    }
  }

  .panelWrap {
    border-top: 0;
    padding-bottom: 6px;
    background-color: var(--ion-color-light);
  }

  .accordionHeader {
    margin-right: 16px;
    padding: 0;
    padding-bottom: 14px;
    background-color: var(--ion-color-light);

    &:focus {
      outline: none;
    }
  }

  .Header {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 16px;
  }

  .buttonRow {
    justify-content: center;
    margin-bottom: 16px;

    .partsButton {
      margin-left: 2px;
      margin-right: 2px;

      span {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}
