.documentContainer {
  padding: 16px;
  padding-top: 0;
  padding-bottom: 6px;

  .documentRow {
    margin-left: -16px;
    margin-right: -16px;
    align-items: center;
    padding-bottom: 10px;

    &.titleRow {
      padding-bottom: 0;
      padding-top: 16px;
    }
  }

  .documentCol {
    padding: 0;
    padding-left: 16px;
    padding-right: 16px;
  }

  .containerTitle {
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: var(--ion-color-danger);

    span {
      color: var(--ion-color-danger-contrast);
    }
  }

  .inuptsWrap {
    padding-top: 16px;

    .rowWrapper {
      display: flex;

      & > div {
        padding: 0 8px;

        &:first-child {
          padding-inline-start: 0;
        }

        &:last-child {
          padding-inline-end: 0;
        }
      }
    }

    .inputRowWrap {
      .containerValue {
        &.textAreaValue {
          padding: 0 16px;

          .textArea {
            width: 100%;

            ion-textarea {
              margin: 0;
              --padding-top: 0;

              textarea {
                padding: 16px;
              }
            }
          }
        }

        .radio {
          --inner-border-width: 0;
          --min-height: 38px;

          ion-label {
            margin: 0;
            margin-left: 16px;
          }

          ion-radio {
            margin: 0;
          }
        }
      }
    }
  }
}
.resetRow {
  .button {
    margin-left: 2px;
    margin-right: 2px;
    span {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
