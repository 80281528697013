@import 'theme/mixins';

.button {
  position: relative;
  @include increaseTouchTargetSize(50%, 0, 50%);
  .icon {
    color: var(--mipro-color-primary)
  }
}

.contentRow {
  p {
    &:not(:first-child) {
      padding-top: 20px;
    }
    line-height: 21px;
    color: var(--mipro-color-black);
  }
}
