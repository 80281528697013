.checkbox {
  margin: 0;
  --size: 11px;
  --checkmark-width: 2px;
  --border-radius: 4px;
  --size: 18px;
  --border-width: 1.5px;
  --checkmark-width: 3px;
  --border-color: var(--mipro-color-medium-gray);
  --checkmark-color: var(--mipro-color-white);
  --checkbox-background-checked: var(--ion-color-primary);

  &::part(container) {
    margin-inline-end: -8px;
    padding: 3px;
  }

  &::part(label) {
    margin-top: 0px;
    margin-bottom: 0px;
  }  
}

.text {
  white-space: pre-wrap;
}

.readOnly {
  span {
    color: rgba(var(--mipro-color-dark-text-rgb),0.5);
  }
  &::part(container) {
    opacity: .5;
  }
}
