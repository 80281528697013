.content {
  --padding-top: 0;
  --padding-bottom: var(--wrapper-margin);
  --padding-end: var(--wrapper-margin);
  --padding-start: var(--wrapper-margin);

  .salesPlayHeadline {
    height: 40px;
  }
}

.wrapper {
  margin-bottom: 16px;
  padding-top: 16px;
}

.headerRow {
  padding: 0 16px;
  margin-bottom: 16px;
  align-items: center;
}

.loader {
  margin-block-start: var(--wrapper-margin);
}

.card {
  margin-inline-start: 0;
  margin-inline-end: 0;

  ion-col {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  .imgRow {
    justify-content: center;
  }

  .section {
    border-top: 1px solid var(--ion-border-color);
    align-items: center;

    &:first-child {
      border: none;
    }
  }

  .lastRow {
    margin-bottom: 16px;
  }

  .rowSeparator {
    margin-bottom: 8px;
  }

  .cardContent {
    padding-top: 0px;

    .details {
      text-transform: none;
    }

    .cardContentTop {
      border-bottom: 1px solid var(--ion-border-color);
      padding: 4px 0;

      span {
        color: var(--ion-text-color-dark);
      }
    }
  }
}

.notActiveRow {
  align-items: center;

  .notActive {
    color: var(--ion-color-danger);
  }

  .notActiveText {
    color: var(--ion-color-danger);
    margin-inline-start: 4px;
  }
}

.blockHeading {
  display: block;
  margin-top: 16px;
  text-transform: uppercase;
  margin-block-start: var(--wrapper-margin);
}

.img {
  object-fit: contain;
  height: 150px;
}

.productName,
.companyName {
  text-align: left;
  white-space: normal;

  svg {
    display: flex;
    font-size: 12px;
  }
}

.companyInfo,
.salesPlayData {
  ion-row {
    place-items: start start;
  }

  ion-col {
    display: flex;
    padding-block-start: 0;
    padding-block-end: 0;
  }
}

.footer {
  background: var(--ion-item-background);

  ion-toolbar {
    --padding-top: var(--wrapper-margin);
    --padding-bottom: var(--wrapper-margin);
    --padding-start: var(--wrapper-margin);
    --padding-end: var(--wrapper-margin);
    --background: transparent;
  }

  ion-button {
    flex: 1;
  }

  .acceptButton {
    margin-inline-end: var(--wrapper-margin);
  }
}

.positiveText {
  color: var(--ion-color-success);
}

.negativeText {
  color: var(--ion-color-danger);
}

.won {
  --border-color: var(--ion-color-success);
  margin-inline-end: var(--wrapper-margin);

  &::part(native):hover {
    border-color: var(--ion-color-success);
  }

  &.active {
    --background: var(--ion-color-success);

    span,
    svg {
      color: var(--ion-text-color-reversed);
    }
  }

  span,
  svg {
    color: var(--ion-color-success);
  }
}

.lost {
  --border-color: var(--ion-color-danger);
  margin-inline-end: var(--wrapper-margin);

  &::part(native):hover {
    border-color: var(--ion-color-danger);
  }

  &.active {
    --background: var(--ion-color-danger);

    span,
    svg {
      color: var(--ion-text-color-reversed);
    }
  }

  span,
  svg {
    color: var(--ion-color-danger);
  }
}

.showButton {
  ion-button {
    border-radius: 4px;
    --border-radius: 4px;
    flex: 1;

    &:first-child {
      margin-inline-end: 8px;
    }
  }

  .won {
    --border-color: var(--ion-color-success);
    margin-inline-end: var(--wrapper-margin);

    &::part(native):hover {
      border-color: var(--ion-color-success);
    }

    &.active {
      --background: var(--ion-color-success);

      span,
      svg {
        color: var(--ion-text-color-reversed);
      }
    }

    span,
    svg {
      color: var(--ion-color-success);
    }
  }

  .lost {
    --border-color: var(--ion-color-danger);
    margin-inline-end: var(--wrapper-margin);

    &::part(native):hover {
      border-color: var(--ion-color-danger);
    }

    &.active {
      --background: var(--ion-color-danger);

      span,
      svg {
        color: var(--ion-text-color-reversed);
      }
    }

    span,
    svg {
      color: var(--ion-color-danger);
    }
  }
}

.button {
  width: 100%;
}

.industryCodes {
  padding-top: 16px;
}
