.listHeader {
  padding: 8px 16px;

  span {
    flex: 1;
    text-align: center;
  }
}

.issueInput {
  margin: 16px;
}

.warningMessage,
.loader {
  margin: 16px;
}

// TODO: make this css reusable across footers
.footer {
  border-top: 1px solid var(--ion-border-color);
  background: var(--ion-item-background);

  ion-toolbar {
    // remove extra safe area whitespace
    --ion-safe-area-bottom: 0;
    --padding-top: 16px;
    --padding-bottom: 16px;
    --padding-start: 16px;
    --padding-end: 16px;
    --background: transparent;
  }

  ion-button {
    flex: 1;
  }

  .saveButton {
    margin-inline-end: 16px;
  }
}

.toolbar {
  --padding-top: 0;
  --padding-bottom: 0;
}

.addAllButton {
  height: 40px !important;
  width: 120px;
  margin-inline-start: 8px;

  &::part(native) {
    background: var(--ion-color-primary);
    border-radius: 6px;
  }
}
