@import 'theme/mixins';

.resultItem {
  --min-height: 78px;
}

.container {
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.wrapper {
  padding: 16px 0;
  flex-direction: column;
}

.avatarTitle {
  color: var(--ion-text-color-reversed);
}

.title {
  color: var(--ion-text-color-dark);
}

.avatar {
  margin-inline-end: 8px;
  @include cardAvatar(var(--ion-avatar-size));
}

.withMargin {
  margin-bottom: 4px;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-inline-end: 4px;
}

.address {
  color: var(--ion-text-color-dark);
}

.cardAvatar {
  margin-inline-start: 4px;
  min-width: 20px;
  width: 20px;
  height: 20px;

  svg {
    width: 10px;
    height: 10px;
    padding-right: 2px;
  }
}

.cardAvatarRegistered {
  @extend .cardAvatar;
  background-color: var(--ion-color-success);
}

.contentWrapper {
  width: calc(100% - 8px - var(--ion-avatar-size));
}

.container {
  .wrapper {
    display: flex;
    flex-direction: row;
    padding-block-end: 3px;
    padding-block-start: 3px;
    justify-content: space-between;

    .registered {
      padding-left: 8px;
    }

    &:first-of-type {
      padding-block-start: 16px;
    }

    &:last-of-type {
      padding-block-end: 16px;
    }
  }

  .label {
    --text-default-color: var(--ion-text-color-light);
    font-size: 13px;
    line-height: 18px;
  }

  .col {
    padding-block-end: 0;
    padding-block-start: 0;
  }

  .firstCol {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .lastCol {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;

    &.lastSignOn {
      max-width: fit-content;
      padding-inline-start: 4px;
    }
  }
}

.mainContact {
  font-size: 9px;
  text-transform: uppercase;
  --text-default-color: var(--ion-color-success);
}
