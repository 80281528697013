.content {
  --padding-top: 16px;
  --padding-bottom: 16px;

  ion-row {
    justify-content: center;
    margin: 0 16px 16px;

    .disabledText {
      margin-top: 8px;
      color: var(--mipro-color-dark-gray)
    }

    .linkButton {
      margin-top: 8px;
    }
  }
}

.toolbar {
  --padding-top: 0;
  --padding-bottom: 0;

  .searchRow {
    align-items: center;
    margin: 8px;

    .searchBar {
      flex: 1;

      ion-row {
        border-radius: 8px;
      }
    }

    .searchInput {
      border-radius: 8px;
    }
  }
}

.warningMessage {
  margin: 0 16px;
}

.button {
  width: 100%;

  &.disabled {
    opacity: 0.6;
  }
}
