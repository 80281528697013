@font-face {
  font-family: 'D-DINCondensed';
  src: url('../fonts/D-DINCondensed.woff2') format('woff2'),
    url('../fonts/D-DINCondensed.woff') format('woff'),
    url('../fonts/D-DINCondensed.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
}

@font-face {
  font-family: 'D-DINCondensed';
  src: url('../fonts/D-DINCondensed-Bold.woff2') format('woff2'),
    url('../fonts/D-DINCondensed-Bold.woff') format('woff'),
    url('../fonts/D-DINCondensed-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  font-stretch: condensed;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/SourceSansPro-SemiBoldItalic.woff') format('woff'),
    url('../fonts/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-Regular.woff2') format('woff2'),
    url('../fonts/SourceSansPro-Regular.woff') format('woff'),
    url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-LightItalic.woff2') format('woff2'),
    url('../fonts/SourceSansPro-LightItalic.woff') format('woff'),
    url('../fonts/SourceSansPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
    url('../fonts/SourceSansPro-SemiBold.woff') format('woff'),
    url('../fonts/SourceSansPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-Light.woff2') format('woff2'),
    url('../fonts/SourceSansPro-Light.woff') format('woff'),
    url('../fonts/SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-Italic.woff2') format('woff2'),
    url('../fonts/SourceSansPro-Italic.woff') format('woff'),
    url('../fonts/SourceSansPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-BoldItalic.woff2') format('woff2'),
    url('../fonts/SourceSansPro-BoldItalic.woff') format('woff'),
    url('../fonts/SourceSansPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-ExtraLight.woff2') format('woff2'),
    url('../fonts/SourceSansPro-ExtraLight.woff') format('woff'),
    url('../fonts/SourceSansPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
    url('../fonts/SourceSansPro-ExtraLightItalic.woff') format('woff'),
    url('../fonts/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-Bold.woff2') format('woff2'),
    url('../fonts/SourceSansPro-Bold.woff') format('woff'),
    url('../fonts/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-BlackItalic.woff2') format('woff2'),
    url('../fonts/SourceSansPro-BlackItalic.woff') format('woff'),
    url('../fonts/SourceSansPro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-Black.woff2') format('woff2'),
    url('../fonts/SourceSansPro-Black.woff') format('woff'),
    url('../fonts/SourceSansPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

// #region Inter
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
// #endregion
