.addNote {
  --background: var(--ion-item-background);
  --padding-top: 16px;
  --padding-bottom: 16px;


  .customTitleWrap {
    display: flex;
    justify-content: space-between;
  }
  .input,
  .textarea {
    margin: 16px;

    span {
      text-transform: capitalize;
    }

    ion-row {
      border-radius: 6px;
    }

    textarea {
      height: unset;
    }
  }

  .publicToggle {
    --background: none;
    --inner-padding-top: 8px;
    --inner-padding-bottom: 0;
    --padding-start: 16px;

    .toggleWrapper {
      display: flex;
      align-items: center;
    }

    ion-button {
      svg {
        color: var(--mipro-color-dark-blue);
        height: 14px;
        width: 14px;
      }
    }

    ion-toggle {
      order: -1;
      position: unset;
      padding: 0 16px 0 0;
    }
  }

  .attachmentWrapper {
    margin: 24px 16px 0;

    .attachmentsTitle {
      text-transform: capitalize;
    }

    .attachmentsOnly {
      text-transform: capitalize;
      font-size: 20px;
      font-weight: normal;
    }

    &.isViewOnly {
      opacity: 0.8;
    }

    ion-row {
      margin-top: 3px;
    }
  }

  .updateDate {
    margin: 8px 16px;
    font-size: 13px !important;
    color: var(--ion-color-light-shade);
  }
}

.publicViewTitle {
  text-transform: capitalize;
}

.publicViewContent {
  padding: 16px 0;
}

.delete {
  svg , span {
    color: var(--mipro-color-light-red) !important;
  }
}

