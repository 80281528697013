.list {
  background-color: var(--mipro-color-white);

  .emptyList {
    padding: 16px;
    margin: 0;

    svg {
      display: none;
    }

    span {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: var(--mipro-color-dark-gray);
    }
  }

  [data-item-index]:last-child {
    & ion-item > div {
      border-bottom: none;
    }
  }
}

.commentsHeader {
  margin-top: 16px;
  background: var(--mipro-color-white);
  padding: 16px;

  .titleWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .followersButton {
    margin-top: -8px;
  }

  .subscribe, .followersButton {
    svg,
    span {
      color: var(--mipro-color-light-blue);
      font-size: 14px;
    }
  }

  .textLabel {
    svg,
    span {
      color: var(--mipro-color-black);
    }
  }
}
.commentsWrapper {
  .commentContainer:last-of-type {
    ion-item > div {
      border-bottom: none;
    }
  }
}
