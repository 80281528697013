.pgc2Container {
  margin-left: 24px !important;
}
.tableHeaderWrapper {
  --padding-end: 0;
  --padding-start: 0;
  --inner-padding-end: 2px;
  --inner-padding-start: 2px;
  background-color: var(--ion-background-color);

  .tableHeader {
    font-size: 13px;
    font-weight: 700;
    text-align: right;
    margin-inline-start: 8px;
    text-transform: uppercase;
    max-width: max-content;
    &:first-of-type {
      max-width: initial;
      text-align: left;
    }
  }
}

.pgc1Cont {
  --padding-end: 7px;
  --padding-start: 7px;
  *[class~='ion-accordion-toggle-icon'] {
    font-size: 15px;
    margin-inline-end: 7px;
  }
  .title {
    color: var(--ion-color-primary) !important;
    font-weight: 700;
  }
}
.accordionItem {
  flex: 1;
  text-align: right;
  max-width: max-content;
  &.title {
    max-width: initial;
    text-align: left;
  }
  &.change {
    display: flex;
    min-width: 5rem;
    justify-content: flex-end;
     .div {
       padding-inline-start: 4px;
    }
  }
}

.accordionLabel {
  font-size: 13px !important;
  overflow: hidden;
  white-space: nowrap;

  a {
    text-decoration: none;
  }
}
.accordionCont {
  background-color: var(--ion-color-primary-contrast);
}

.pgc2Item {
  --min-height: 36px;
  --padding-end: 7px;
  --padding-start: 7px;
  --inner-border-width: 0;
  --border-color: transparent;
}

.sortIcon {
  padding: 0;
  margin-inline-end: 2px;
  margin-inline-start: 2px;
  font-size: 14px !important;
  color: var(--ion-text-color-dark);
}

.tableFooter {
  --inner-padding-end: 2px;
  --inner-padding-start: 2px;
  font-weight: 700;
  text-transform: uppercase;
  .amount {
    color: var(--ion-color-success);
  }
  .change span {
    font-weight: 700;
  }
}
.salesChange {
  padding-inline-start: 10px;
}

ion-accordion-group > ion-accordion:last-of-type ion-item[slot=header] {
  --border-width: 0px 0px 0.55px 0px !important;
}
