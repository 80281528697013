@import 'theme/mixins';

.customTitleWrapper {
  text-align: left;
  svg {
    font-size: 16px;
  }
  .onlineIcon {
    color: var(--mipro-color-online-green);
  }

  .offlineIcon {
    color: var(--ion-color-secondary);
  }
  .offlineBtn {
    vertical-align: middle;
    svg {
      color: var(--ion-color-primary) !important;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      @include textEllipsis();
    }
  }
}

.blockPd {
  padding-top: 20px;
}

.subtitle {
  color: var(--mipro-color-white);
}
