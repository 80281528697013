.customerNameText {
  text-align: left;
  white-space: normal;
}
.pick12Icon {
  top: 0 !important;
}
.flex {
  display: flex;
  padding-inline-start: 0px;
  svg {
    font-size: 16px;
    color: var( --mipro-color-primary);
    padding-right: 2px;
  }
  .corpIcon {
    color: var(--mipro-color-purple);
    font-size: 20px;
    width: 13px;
    margin-right: 2px;
  }
  padding-bottom: 0px;
  padding-top: 0px;
}

.removePdBottom {
  padding-bottom: 0;
}
