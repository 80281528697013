.teamList{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.teamMemberTitle{
  margin-right: 5px;
}

.teamMemberName{
  span {
    color: var(--mipro-color-light-blue);
  }
}
