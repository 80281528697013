.eomContent {
  --background: var(--ion-color-primary-contrast);
  .headerCondense {
    ion-toolbar {
      --background: var(--ion-color-light-tint);
      .customTitleWrapper {
        text-align: left;
        padding: 12px 0;
        .flex {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
}

.overlayInfoBar {
  height: 45px;
  background-color: var(--mipro-color-light-red);
  margin: 0 -16px;
}

.contentRow {
  padding: 14px 0;
  &:first-child {
    padding-top: 0;
  }
  p {
    &:not(:first-child) {
      padding-top: 20px;
    }
    line-height: 21px;
    color: var(--mipro-color-black);
  }
  &:last-child{
    border-bottom: none;
  }
}

.loader {
  padding-top: 16px;
}

.content {
  padding: 16px;
}

.helpContentRow {
  p {
    &:not(:first-child) {
      padding-top: 20px;
    }
    line-height: 21px;
    color: var(--mipro-color-black);
  }
}

.warningMessage {
  padding: 0 16px;
}

.datetoolbar {
  padding: 0;
  margin: 0 0 16px;
}
