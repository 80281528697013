@import 'theme/mixins';

.csHeader {
  padding-block-start: 20px;
}

.csRow {
  padding-inline-start: 20px;
}

.locRow {
  padding-block-start: 4px;
}

.costSavings {
  background-color: var(--ion-color-light);
  border-radius: 4px;
}

.costSavingsWrapper {
  margin: 0 16px;
}

.icon {
  padding-inline-start: 8px;
  color: var(--mipro-color-primary);
  width: 15px;
  height: 24px;
  align-self: flex-end;
}

.inlineRows {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline-end: 20px;
  padding-block-start: 11px;

  &.csBottom {
    padding-block-end: 20px;
    padding-block-start: 40px;
  }
}

.approvedCircle {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: var(--color-positive-yoy);
}

.remainingCircle {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: var(--mipro-color-light-yellow);
}

.inlineDetails {
  display: flex;
  align-items: center;
  --text-default-color: var(--mipro-color-medium-gray);
  line-height: 15px;

  &.inlineRowText {
    padding-inline-start: 4px;
  }
}

.separator {
  --padding-end: 20px;
  --min-height: 22px;
}

.guranteed {
  color: var(--mipro-color-dark-text);
  font-size: 16px;
}

.pending {
  --text-default-color: var(--mipro-color-medium-gray);
  --label-micro-text-size: 13px;
  font-weight: 500;
}

.pillsRow {
  padding-inline-end: 20px;
  display: flex;
  justify-content: space-between;
}

.overGoalPendingDetails {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
  align-items: center;
}

.wrapperCap {
  flex-wrap: nowrap;
  position: relative;
  overflow: hidden;
  height: 20px;
  padding: 0;
  margin: 20px 20px 0;
}

.approvedCap {
  height: 20px;
  background-color: var(--mipro-color-light-green);
  border-radius: 10px;
  z-index: 2;
  min-width: 20px;
  outline: 4px solid var(--ion-item-background);
}

.remainingCap {
  width: 100%;
  height: 20px;
  background-color: var(--mipro-color-light-yellow);
  border-radius: 10px;
  min-width: 30px;
  position: absolute;
  right: 0;
}

.viewFullReport {
  --border-radius: 6px;
  --ion-color-primary: var(--mipro-color-primary);
  width: 100%;
  margin-bottom: 10px;
}

.costSavingsButton {
  svg {
    width: 18px;
    height: 15px;
  }

  span {
    padding-inline-start: 5px;
  }
}

.helpContent {
  line-height: 21px;
}

.dropdownButton {
  span {
    font-size: 11px;
  }
  svg {
    font-size: 10px;
    margin-inline-start: 2px;
  }
}
