  .statusTitle {
    line-height: 1.5;
    display: block;
    text-transform: uppercase;
    color: var(--ion-text-color-dark);
  }

  .subtitle {
    width: 100%;
    height: 12px;
    margin-left: 8px;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
  }

  .logoCol {
    width: 50px;
    max-width: 50px;
  }

  .typeLogo {
    width: var(--ion-avatar-size);
    height: var(--ion-avatar-size);
    margin: 0 8px 0 0;
    background-color: var(--ion-background-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    width: 24px;
    height: 24px;
    font-size: 20px;
    color: var(--ion-color-secondary);
  }

  .iconRead {
    color: var(--ion-color-medium);
  }

  .wrapper {
    width: 100%;
    max-height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-inline-start: 6px;
  }

  .content {
    width: 100%;
  }

  .card {
    background-color: var(--ion-text-color-reversed);
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 0px;
    --inner-padding-top: 0;
    --inner-padding-start: 0;
    --border-style: none;
  }

  .description {
    min-height: 38px;
    text-align: left;
    line-height: 1em;
    overflow: hidden;
    padding-top: 5px;
    padding-left: 12px;
    color: var(--ion-color-light-contrast);
    .title {
      display: block;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
    }
    .titleRead {
      font-weight: 500;

    }
  }

  .warningMessage {
    margin: 16px;
  }

  .readSpacing {
    height: 10px;
    width: 10px;
    min-width: 10px;
    border-radius: 50%;
    display: inline-block;
  }

  .readDot {
    @extend .readSpacing;
    background-color: var(--mipro-color-unread);
  }

  .readCol {
    min-width: 10px;
    max-width: 10px;
    display: flex;
    padding-top: 20px;
    padding-right: 10px;
  }
