.container {
  width: 100%;
  height: 100%;
  overflow: auto;

  .calendarIcon {
    color: var(--mipro-color-light-blue);
    width: 14px;
    height: 14px;
  }

  .changeDate {
    span {
      color: var(--mipro-color-light-blue);
    }
  }

  .expectedDateRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--mipro-color-light-gray);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 16px;
    gap: 10px;
  }

  .changeDateCol {
    display: flex;
  }

  .shippingHeader {
    background: var(--mipro-color-white);
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

.loader,
.warningMessage {
  margin: 16px;
}

.itemSlider {
  display: flex;
  flex-direction: column;
  height: 100%;

  .itemOption {
    width: 150px;
    background: var(--mipro-color-pale-blue);
    color: var(--mipro-color-primary);

    svg {
      position: absolute;
    }

    .prevOption {
      left: 16px;
    }

    .nextOption {
      right: 16px;
    }
  }

  .item {
    flex: 1;
    height: 100%;
    --background: var(--ion-background-color);
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;

    &::part(native) {
      height: 100%;
    }
  }
}
