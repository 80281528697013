.infoContainer {
  align-items: flex-end;
  width: 100%;

  .costNumber {
    color: var(--mipro-color-medium-gray);
  }

  .priceAndCostWrapper {
    align-items: flex-end;

    .priceWrapper {
      display: flex;
      align-items: baseline;
      margin-right: 8px;

      .priceNumber {
        font-size: 22px;
        margin-right: 4px;
      }

      .priceLabel {
        color: var(--mipro-color-black);
        text-transform: lowercase;
      }
    }

    .costWrapper {
      display: flex;
      align-items: baseline;
      margin-right: 16px;

      .costNumber {
        font-size: 18px;
        margin-right: 4px;
        color: var(--mipro-color-medium-gray);
      }

    }
  }

  .gpAndSSSWrapper {
    align-items: flex-end;

    .gpWrapper {
      display: flex;
      align-items: baseline;
      margin-right: 8px;

      .costTitle {
        color: var(--mipro-color-black);
      }

      .costNumber {
        font-size: 18px;
        color: var(--mipro-list-green);
        margin-left: 4px;
      }

      .negativeCostNumber {
        color: var(--mipro-color-light-red);
      }
    }
  }
}
