.modal {
  --padding-top: 0;
  --padding-bottom: 16px;
  &::part(handle) {
    width: 50px;
    background: var(--color-help-modal-handle);
  }


  .title {
    font-size: 22px;
  }
}

.detail {
  --min-height: unset;
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 0;
  --padding-end: 0;

  .label {
    display: block;
    margin-inline-start: auto;
    font-size: 16px;
    
    span {
      font-size: 16px;
    }
  }
  .text {
    color: var(--mipro-color-primary);
    font-weight: 600;
    font-size: 16px;
  }


  &:last-child {
    --border-width: 0px;
    border-bottom: none;
  }
}

.dateInput {
  padding-top: 16px;
}

.buttons {
  padding: 16px;

  .customDateButton {
    width: 100%;
  }
}

.snapshotLink {
  margin-bottom: 8px;
}

.snoozeContainer {
  margin: 4px 0 12px;
}
