@import 'theme/mixins';

.DateToolbar {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  padding-bottom: 16px;
  width: 100%;

  .DateArrows {
    min-width: 24px;
    position: relative;
    @include increaseTouchTargetSize();
    color: var(--mipro-color-primary);

    svg {
      color: var(--mipro-color-primary);
    }

    &.disabled,
    &.disabled:hover {
      opacity: 0.6;
    }
  }

  .DateText {
    min-width: 85px;
    margin-inline-end: 3px;
    margin-inline-start: 3px;
    .Text {
      color: var(--ion-color-light);
      font-family: var(--ion-font-family-alt);
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

.DateNav {
  width: 45%;
  display: flex;
  justify-content: space-between;
  color: var(--ion-color-light);
}

.eomNav {
  justify-content: center;
}

.DateSegments {
  width: 50%;
  border-radius: 0;
  margin: 0;
  --background: transparent;
}

.DateSegment {
  min-width: auto;
  margin-top: 0;
  margin-bottom: 0;
  --padding-start: 0;
  --color: var(--mipro-color-dark-gray);
  --indicator-color: transparent;
  --border-color: none;
  border: 0 none;
  border-radius: 0;
  font-family: var(--ion-font-family-alt);
  font-size: 14px;
  font-weight: 600;

  &[class~='segment-button-checked'] {
    &::part(native) {
      background: var(--indicator-color);
      color: var(--mipro-color-primary);
    }
  }

  &::part(indicator) {
    --border-radius: 0;
    padding-inline: 0;
  }
}

.DateSegmentDivider {
  display: block;
  width: 1px;
  height: 24px;
  background: var(--ion-color-medium);
  opacity: 1;
  margin: 2px 0;
}
