.vendorIncreaseLettersContainer {
  .content {
    --padding-top: 16px;
    --background: var(--ion-item-background);
  }

  .loader {
    margin-top: 16px;
  }

  .warningMessage {
    margin: 16px;
  }

  .button {
    vertical-align: middle;
  }
}
