.ErrorFallback {
  .title {
    font-family: var(--ion-font-family-alt);
    font-size: 90px;
    font-weight: bold;
    text-align: center;
    color: var(--ion-color-danger);
    margin-top: 20px;
    text-transform: uppercase;
  }
  .text {
    font-family: var(--ion-font-family-alt);
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: var(--ion-color-dark);
    text-transform: uppercase;
    margin-top: 200px;
  }
  .icon  {
    display: block;
    margin: auto;
    font-size: 110px;
    color: var(--ion-color-medium);
    top: 80px;
    position: relative;
  }
  .bigCircle {
    width: 254px;
    height: 254px;
    opacity: 0.15;
    background-color: #bcc3cd;
    margin: -108px auto;
    border-radius: 50%;
  }
  .smallCircle {
    width: 198px;
    height: 198px;
    background-color: white;
    margin:  -226px auto;
    border-radius: 50%;
  }
  .errorWave {
    text-align: center;
    img {
      margin-top: -24px;
    }
  }
  .footer {
    ion-toolbar {
      --padding-top: 16px;
      --padding-bottom: 16px;
      --padding-start: 16px;
      --padding-end: 16px;
      --background: transparent;
      --border-width: 0
    }
    ion-button {
      flex: 1;
    }
    .tryAgainButton {
      margin-bottom: 16px;
    }
  }

}
