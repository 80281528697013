.primaryButton {
  width: 100%;
  margin-top: 24px;
}

.secondaryButton {
  @extend .primaryButton;
  margin-top: 16px;
}

.wrapper {
  padding: 24px 16px;
}

.title {
  display: block;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.withText {
  margin-bottom: 16px;
}
