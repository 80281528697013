.badge {
  display: flex;
  align-items: center;
  height: 16px;
  border-radius: 16px;
  padding-left: 8px;
  padding-right: 8px;

  svg {
    margin: 0;
    width: 9px;
    height: 9px;
    margin-inline-end: 4px;
  }

  span {
    font-family: var(--ion-font-family);
    font-size: 10px;
    font-weight: 600;
  }

  span,
  svg {
    color: var(--mipro-color-white);
  }
}

.warning {
  background: var(--mipro-color-alert-warning);
}

.info {
  background: var(--ion-color-primary);
}

.green {
  background: var(--mipro-list-green);
}

.error {
  background: var(--mipro-list-error);
}

.notification {
  background: var(--ion-color-secondary);
}

.circle {
  width: 16px;
  margin: 0px;
  padding: 0px;
  align-self: center;
  justify-content: center;
}
