.popover {
  background-color: black;
  display: flex;
  padding: 4px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  color: white;
  text-align: center;
}

ion-popover::part(arrow)::after {
  background-color: transparent !important;
}
