.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.productDetail {
  .productHeaderAndTabsWrapper {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

.footer {
  ion-toolbar {
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.1);
    --padding-top: 16px;
    --padding-bottom: 16px;
    --padding-start: 16px;
    --padding-end: 16px;
    --border-width: 0;
    --background: var(--mipro-color-white);
  }

  .inputWrapper {
    display: flex;
    input {
      text-align: center;
    }
  }

  .addToOcn {
    width: 100%;
  }
}

.tabs {
  margin: 0;
}

.loader,
.warningMessage {
  margin: 16px;
}

.banner {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  color: var(--mipro-color-white);
}

.spinner {
  position: fixed;
}
