.content {
  --padding-start: 0;
  --padding-end: 0;
  --background: var(--mipro-color-white);
  .header {
      padding: 16px 0 8px;
      background: var(--mipro-color-white);
  }
  .contentWrapper {
    padding: 0 16px;
    ion-col {
      padding-inline-start: 0px;
      padding-inline-end: 0px;
      padding-bottom: 0px;
    }
    .pd16 {
      padding-top: 16px;
    }
  }
}
