.card {
  border-radius: 1.5px;

  &.disabled {
    opacity: 1;

    .title {
      color: var(--mipro-color-disabled-tint);
    }

    svg {
      display: none;
    }
  }

  .title {
    line-height: normal;
  }
}

.header{
  padding: 0 16px;
  align-items: center;
  height: 50px;
  svg {
    top: 0px;
  }
}
