.header {
  ion-toolbar {
    --background: var(--mipro-color-white);
    .title {
      padding-top: 8px;
    }
    .indicator {
      padding: 0px;
    }
  }
}

.formInput {
  border-radius: 6px;
  border: 1px solid var(--mipro-color-black);

  &:focus-within {
    border: 1px solid var(--ion-color-primary);
  }
}

.signatureModal {
  .signatureModalContent {
    --padding-start: 27px !important;
    --padding-end: 27px !important;
    --padding-top: 16px !important;

    .radioFlex {
      display: flex;
      width: 200px;
      justify-content: space-between;

      .col {
        display: flex;
        align-items: center;

        .label {
          margin-inline-start: 8px;
        }
      }
    }

    .btnWrapper {
      margin-top: 20px;
      .fullWidth {
        width: 100%;
      }
    }
  }
}

.costSavings {
  &.viewMode {
    --background: var(--mipro-color-white);
  }

  ion-input {
    font-size: 14px;
  }

  .spacing {
    padding: 0 16px 10px 5px;
    margin-top: -10px;
    background-color: var(--mipro-color-white);
  }

  .costSavingsForm {
    margin-top: 12px;
    padding: 16px 0;
    background-color: var(--mipro-color-white);

    .textColor {
      --text-default-color: var(--mipro-color-dark);
    }
  }

  .rowHeading {
    padding-left: 16px;

    .inputWrapper {
      padding-inline-start: calc(
        2 *
          var(--ion-grid-column-padding-sm, var(--ion-grid-column-padding, 5px))
      );
      padding-inline-end: calc(
        2 *
          var(--ion-grid-column-padding-sm, var(--ion-grid-column-padding, 5px))
      );
    }
  }

  .lineHeading {
    padding-top: 10px;
    ion-label {
      font-size: 14px;
      font-weight: 500;
      padding-left: 6px;
    }
  }

  .vasHeading {
    margin-right: 15px;

    ion-label {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .vasMessage {
    padding-left: 6px;

    .customerLabel {
      margin-left: -6px;
    }
  }

  .vasWarning {
    color: var(--mipro-color-dark-orange);

    svg,
    img {
      font-size: 14px;
      padding-right: 6px;
    }
  }

  .vasRadio {
    display: flex;
    align-items: center;

    & > ion-col {
      padding: 0;
    }

    ion-radio-group {
      display: flex;
      align-items: center;

      ion-label {
        margin-inline-start: 14px;
      }

      ion-col {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          padding-inline-end: 30px;
        }
      }
    }

    .checkboxLabel {
      margin-top: 10px;
    }

    .checkboxRow {
      align-items: center;
      margin-top: 10px;

      .checkboxWrapper {
        flex: 1;
        display: flex;

        ion-checkbox {
          margin-inline-end: 10px;
          --border-radius: 5px;
          --border-width: 1.5px;
          --border-color: var(--mipro-color-medium-gray);
        }
      }

      span {
        font-weight: 400;
        line-height: 12px;
      }
    }
  }

  .errorMessage {
    color: var(--ion-color-danger);
  }

  .vasDisplay {
    padding-top: 10px;

    span:first-child {
      flex: 1;
    }
  }

  .selectCustInput {
    @extend .formInput;
  }

  .customerRows {
    display: flex;
    ion-col {
      padding: 0px;
    }

    .customerCols {
      max-width: 100%;
      text-align: left;
      .snapshotLink {
        margin-bottom: -16px;

        .customerName {
          font-family: var(--ion-font-family-alt);
          font-weight: 700 !important;
          font-size: 20px;
          line-height: 22px;
          color: var(--mipro-color-primary) !important;
        }
      }

      .customerNoClass {
        font-family: var(--ion-font-family);
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        color: var(--mipro-color-dark-text) !important;
      }

      .editButtonWrapper {
        justify-content: space-between;
      }

      .editButton {
        font-size: 12px;

        span,
        img,
        svg {
          font-size: 12px;
          text-transform: capitalize;
          font-weight: 500;
        }
      }

      .editButtonSnapshot {
        @extend .editButton;
        margin-top: 0;

        span,
        img,
        svg {
          transform: translateY(15%);
        }
      }
    }
    .corpCol {
      padding-top: 8px;
    }
  }

  .magnifier {
    svg {
      font-size: 14px;
      color: var(--mipro-color-primary) !important;
    }
  }
}

.formWrapper {
  margin-bottom: 16px;
}

.loader,
.warningMessage {
  padding: 16px;
}

.footer {
  padding: 16px;
  border-top: 1px solid var(--ion-border-color);

  ion-button {
    width: 100%;
  }
}

.discardModal {
  .discardTitle {
    width: 60%;
    font-size: 24px;
    line-height: 110%;
  }

  .discardContent {
    --padding-start: 27px;
    --padding-end: 27px;
  }

  .msgRow {
    margin-top: 16px;
  }

  .signatureNameWrapper {
    margin-top: 8px;
  }

  .discardMsg {
    line-height: 21px;
    font-weight: 500;
    --text-default-color: var(--mipro-color-black);
  }

  .discardButton {
    margin-top: 16px;
    width: 100%;
    --border-radius: 6px;
  }

  .primaryButton {
    --background: var(--mipro-color-primary);
  }
}

.reminderWrapper {
  padding-top: 20px;

  .dateInput {
    @extend .formInput;
  }
}

.reminderFooter {
  padding-top: 20px;
  background: var(--ion-item-background);

  ion-button {
    flex: 1;
    margin-inline-end: 16px;
    --border-radius: 6px;

    &:last-child {
      margin: 0;
    }
  }
}

.signature {
  height: 60px;
}
