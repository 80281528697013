.primaryButton {
  width: 100%;
  margin-top: 32px;
}

.usernameText {
  display: block;
  margin-bottom: 8px;
}

.password {
  margin-top: 24px;
}
