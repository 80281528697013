.wrapper .ratingButtons {
  margin: 0;

  ion-button {
    --background: transparent;
    flex: 1;

    &:first-child {
      margin-inline-end: 8px;
    }

    &.active {
      span,
      svg {
        color: var(--ion-text-color-reversed);
      }
    }
  }

  svg {
    margin-inline-end: 8px;
  }

  .positiveButton {
    --border-color: var(--color-approved);

    &::part(native):hover {
      border-color: var(--color-approved);
    }

    &.active {
      --background: var(--color-approved);
    }

    span,
    svg {
      color: var(--color-approved);
    }
  }

  .negativeButton {
    --border-color: var(--color-rejected);

    &::part(native):hover {
      border-color: var(--color-rejected);
    }

    &.active {
      --background: var(--color-rejected);
    }

    span,
    svg {
      color: var(--color-rejected);
    }
  }
}
