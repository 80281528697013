.RefreshBanner {
  min-height: var(--refresher-height);
  padding: 8px 0;
  background-color: var(--ion-item-background);

  .wrapper {
    justify-content: center;
    align-items: center;
    margin: 0 10px;

    svg,
    ion-spinner {
      width: 14px;
      height: 14px;
      margin-inline-end: 4px;
    }
  }
}

.Refresher {
  z-index: 9999;
  ion-spinner {
    --color: var(--ion-color-secondary);
  }
}
