/* Core CSS required for Ionic components to work properly */
@import '~@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/react/css/normalize.css';
@import '~@ionic/react/css/structure.css';
@import '~@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/react/css/padding.css';
@import '~@ionic/react/css/float-elements.css';
@import '~@ionic/react/css/text-alignment.css';
@import '~@ionic/react/css/text-transformation.css';
@import '~@ionic/react/css/flex-utils.css';
@import '~@ionic/react/css/display.css';

/* Theme variables */
@import 'theme/variables.scss';

/* Ionic overrides */
@import 'theme/overrides.scss';

/* MI fonts */
@import 'theme/fonts.scss';

/* disable error overlay on mobile */
@media (hover: none) {
  body > iframe {
    display: none;
  }
}

:root {
  --keyboard-animation: 0.3s cubic-bezier(0.1, 0.7, 0.2, 1);
}

ion-app {
  // fix overflow in Reports Table and after closing Modals
  overflow: visible !important;
  // fix for ion-footer animation when keyboard rerenders
  height: 100%;
  transition: height var(--keyboard-animation);
  will-change: height;
}

ion-footer ion-toolbar:last-of-type {
  transition: padding var(--keyboard-animation);
  will-change: padding;
}
