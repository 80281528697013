.csInProgressItem {
  opacity: 1;

  .label {
    flex: 1 1 75%;
    color: var(--mipro-color-black);
    white-space: pre;
    padding-top: 4px;
    padding-bottom: 6px;
    .text {
      font-style: italic;
      margin-left: 4px;
      color: var(--mipro-color-black);
    }

    p {
      color: var(--mipro-color-dark-gray);
      font-size: 14px;
      padding-top: 2px;
    }
  }
}
