@import 'theme/mixins';

.activityFilters {
  @include horizontalScroller();
}

.selectedFilterWrapper {
  justify-content: space-between;
  min-width: calc(100vw - calc(var(--wrapper-margin) * 2));
}

.filter {
  span {
    color: var(--ion-text-color-reversed);
  }
}

.filterBtn {
  --background: var(--ion-background-color);
}

.count {
  order: 1;
  margin-inline-start: 4px;
}
