@import 'theme/mixins';

.content {
  display: flex;
  align-items: center;

  .dropdownButton {
    position: relative;
    @include increaseTouchTargetSize(100%, 80%, 250%);

    span {
      font-size: 11px;
      text-transform: uppercase;
      font-family: var(--ion-font-family);
    }
    svg {
      font-size: 10px;
      margin-inline-start: 2px;
    }
  }
}
