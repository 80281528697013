@import 'theme/mixins';

@mixin leaderBoardGenericCard ($cardBackground,  $cardBorder,  $borderWidth, $rankFontSize, $width, $padding) {
  $indentHeight: $rankFontSize * 1.1;
  width: $width;
  height: 100%;
  background: $cardBackground;
  overflow: hidden;
  .rank {
    position: relative;
    font-size: $rankFontSize;
    line-height: $rankFontSize;
    margin-bottom: 0 - $rankFontSize;
    font-family: var(--ion-font-family-alt);
    font-weight: 700;
    color: var(--ion-text-color-dark);
    text-transform: uppercase;
    text-align: center;
  }

  .cardBorder {
    @include leaderBoardShape(0px, $indentHeight);
    background-color: $cardBorder;
    height: 100%;
    width: 100%;
  }

  .cardContent {
    @include leaderBoardShape($borderWidth, $indentHeight);
    display: block;
    position: relative;
    background-color: $cardBackground;
    width: calc(100% - $borderWidth * 2);
    height: calc(100% - $borderWidth * 2);
    top: $borderWidth;
    left: $borderWidth;
    padding: $padding;
    padding-top: $indentHeight + 8;
    text-align: center;
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}
