.mainTabBar {
  ion-tab-bar {
    $minVerticalMargin: 8px;
    --background: var(--ion-color-light);
    --border: 1px solid var(--ion-border-color);
    padding-top: $minVerticalMargin;
    padding-bottom: calc(
      var(--ion-safe-area-bottom) * 0.5 + #{$minVerticalMargin}
    );

    ion-tab-button {
      --color: var(--ion-color-dark-tint);
      --color-selected: var(--ion-color-secondary);

      .notification {
        padding: 4px;
        margin-inline-start: -18px;
        margin-bottom: 24px;
        border: 1px solid var(--mipro-color-white);
        z-index: 1;
      }

      ion-label {
        font-size: 11px;
        letter-spacing: normal;
      }
    }
  }
}

.tabWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  ion-label {
    margin: 2px 0px;
    line-height: 1.1;
    white-space: pre-wrap;
  }
}

.tabButton {
  width: 100%;
  height: 100%;
  --color: var(--ion-color-dark-tint);
  --color-hover: var(--ion-color-secondary);

  .activeTab {
    color: var(--ion-color-secondary);
  }
}

// Bell animation

.icon {
  transform-origin: 50% 4px;
  will-change: transform;
}

.bell {
  animation: ring 4s 0.7s ease-in-out infinite;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
