.barChart {
  width: 100%;
}

.chartWrapper {
  min-height: 200px;
  padding: 16px;
  position: relative;
  margin-bottom: 16px;
  border-radius: 1.5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

.LineChartWrapper {
  width: 100%;
  height: 120px;
}

.warningMessage {
  margin: 16px;
}
