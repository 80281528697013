.content {
  --background: var(--mipro-color-white);
}

.isDeletedLabel {
  position: relative;
  padding: 8px 16px;
  background: var(--mipro-color-dark-orange);
  span,
  svg {
    color: var(--mipro-color-white) !important;
  }
}

.countPlanTabs {
  min-height: unset;

  ion-segment-button span {
    color: var(--mipro-color-medium-gray);
  }

  .selectedTab span {
    color: var(--ion-text-color-reversed);
  }
}

.searchRow {
  align-items: center;
  padding: 16px;
  background: var(--ion-background-color);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .scanButton > ion-button {
    height: 40px;
    width: 80px;
    margin-inline-start: 8px;
  }

  .searchBar {
    flex: 1;

    .searchInput {
      --border-width: 1px;
      --border-radius: 6px;
      --searchbar-border-color: var(--mipro-color-dark-text);

      ion-row {
        height: 40px;
        border-radius: 6px;
      }
    }
  }
}

.item {
  --background-activated: var(--mipro-list-background-activated);
  --background-activated-opacity: 1;
  ion-grid {
    padding: 16px 0;
    --ion-grid-padding-xs: 0px;
    --ion-grid-padding: 0px;
  }

  &.selectedItem {
    --padding-start: 0;
    --padding-end: 0;

    ion-grid {
      padding: 16px;
      background: var(--mipro-list-background-activated);
    }
  }

  &.deletedItem {
    --action-row-background: var(--mipro-color-disabled) !important;
    opacity: 0.8;
  }
}

.itemText {
  &.itemTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .itemIcons {
      display: flex;
    }

    .itemName {
      flex: 1;
    }

    .itemCheckCount {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .itemCircle,
    .itemMemoIcon,
    .itemDisabledIcon {
      margin-inline-end: 4px;
    }

    .itemCircle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 14px;
        color: var(--ion-color-primary-contrast);
      }

      &.itemDisabledIcon {
        background: var(--text-default-color);
      }
    }

    .itemCheckIcon {
      background: var(--mipro-color-light-green);
    }

    .itemSliderIcon {
      background: var(--color-overdueSalesPlay);
    }

    .itemMemoIcon {
      color: var(--mipro-color-primary);
      font-size: 18px;
    }
  }

  &.firstRow {
    padding-bottom: 10px;
  }

  .text {
    width: 50%;
    padding-bottom: 2px;
    --text-default-color: var(--mipro-color-dark-text);
  }

  .onOrderQty {
    --text-default-color: var(--mipro-color-light-green);
    font-weight: 600;
  }
}

.itemTitleIcon {
  font-size: 14px;
  color: var(--mipro-color-primary);
}

.options {
  width: 90px;

  span,
  svg {
    color: var(--mipro-color-white);
  }

  span {
    margin-inline-start: 8px;
    font-weight: 500;
  }
}

.footer {
  .footerButtons {
    background: var(--mipro-color-white);
    border-top: 1px solid var(--mipro-color-light-gray);
    padding: 16px;
  }

  .reviewButton {
    height: 44px;
    width: 100%;
    --background: var(--mipro-color-primary);

    &.disabledReview {
      opacity: 1;
      --background: var(--mipro-color-disabled-tint);

      span {
        color: var(--mipro-color-white);
      }
    }
  }
}

.warningMessage {
  margin: 20px;
}

.confirmModal {
  .modalContent {
    --padding-top: 20px;
  }
  .btnWrapper {
    margin-top: 20px;
    .fullWidth {
      width: 100%;
    }
  }
}

.loader {
  margin-top: 32px;
}

.defaultSortButton {
  margin-top: 16px;
}

.sortModal {
  & > ion-button {
    display: none;
  }
}

.scanBleButton {
  --background: var(--mipro-color-orange);
  height: 40px;
  margin-inline-start: 8px !important;
  padding: 0 8px !important;
  max-width: 50%;

  &.isConnected {
    --background: var(--mipro-color-success);

    svg {
      transform: rotate(-90deg);
    }
  }
}

.scanHelpModal {
  padding: 16px 0;
  white-space: pre-line;

  .qrConfigure {
    text-align: center;
    padding: 16px;
  }

  .scannedList {
    margin-bottom: 16px;
  }

  .bleLoader {
    margin: 16px;
  }

  .scannedDevice {
    width: 100%;
  }

  .opticonfigure {
    ion-button {
      vertical-align: bottom;
    }
  }
}

.scanBattery {
  display: flex;
  align-items: center;
  justify-content: center;
  order: 1;
  padding-inline-start: 8px;

  svg {
    margin: 0 4px;
  }
}

.scanModeToggle {
  order: 2;
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .toggle {
    width: 42px;
    --background: transparent;
    --min-height: 38px;
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-top: 0;
    --inner-padding-bottom: 0;

    [checked='false'] {
      &::part(track) {
        background: var(--mipro-color-orange);
      }
    }

    &.isConnected [checked='false'] {
      &::part(track) {
        background: var(--mipro-color-success);
      }
    }
  }
}

.sortBadge {
  order: 2;
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 8px;
  min-height: 24px;
  border-radius: 24px;
  background: var(--mipro-color-pale-blue);

  div:last-child span {
    white-space: nowrap;
  }

  span {
    white-space: unset !important;
    font-size: 12px;
    font-weight: 600 !important;
    color: var(--mipro-color-dark-text);
    font-family: var(--ion-font-family);
  }

  svg {
    font-size: 13px;
    margin-inline-start: 8px;
    margin-inline-end: 4px;
    color: var(--mipro-color-dark-text) !important;
  }
}

.warningText {
  margin-top: 4px;
}

.header {
  ion-toolbar {
    --padding-start: 0px;
    --padding-end: 0px;
    contain: layout style;

    &::part(container) {
      overflow: visible;
      contain: layout style;
    }
  }

  .headerAction {
    padding-right: 16px;
  }
  .backButton {
    padding-left: 16px;
  }
}
