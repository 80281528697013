.CurrencyFormat {}

.block {
  display: block;
}

.inline {
  display: inline;
}

.inlineBlock {
  display: inline-block;
}
