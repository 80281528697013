.modal {
  --padding-top: 0;
  --padding-bottom: 0;
}

.checkbox {
  margin: 8px 8px 8px 0;
}

.buttonWrapper {
  padding: 16px;
}

.saveButton {
  width: 100%;
}

.formItem {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --border-color: transparent;
  --background: transparent;
  --background-activated: transparent;
  --background-activated-opacity: 1;
  --background-focused: transparent;
  --background-focused-opacity: 1;
  --background-hover: transparent;
  --background-hover-opacity: 1;
  margin-top: 8px;
}
