.cardMini {
  width: calc((100vw - (32px)) / 2.333);

  @media screen and (min-width: 768px) {
    width: calc((100vw - (32px)) / 4.333);
  }

  padding: 8px;
  margin: 0;
}

.wrapper {
  display: grid;
  grid-column-gap: 4px;
  grid-template-columns: auto max-content;
  margin-block-end: 4px;
}

.title {
  color: var(--ion-text-color-dark);
}

.aging {
  margin-inline-end: 0;
  margin-inline-start: auto;
}
