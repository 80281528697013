.reportCard {
  --backdrop: var(--ion-color-light);
  margin: 16px 0;

  .cardHeader {
    padding-bottom: 8px;
  }

  .customerCardHeader {
    padding-bottom: 0;
  }

  .subtitle {
    display: block;
    margin-bottom: 8px;
  }
}

.cardRows {
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 18px;
  grid-template-columns: 100px minmax(80px, auto) minmax(60px, auto);

  &:last-of-type {
    margin: 0;
  }
}

.amountLabel {
  text-transform: uppercase;

  @media (max-width: 320px) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}


.changeFormat {
  padding-inline-start: 0;
  margin-left: 0;
}

.positive {
  span {
    color: var(--mipro-color-darker-green)!important;
    font-size: 13px;
  }
}

.negative {
  span {
    color: var(--mipro-color-darker-red)!important;
    font-size: 13px;
  }
}
