.item {
  --padding-top: 16px;
  --padding-bottom: 16px;

  .itemRow {
    width: 100%;
  }

  .dataRow {
    flex: 2;

    ion-row span {
      flex: 1;
      text-align: center;
    }

    .itemError {
      color: var(--ion-color-danger);
    }

    .wrapper {
      width: 100%;

      .inner {
        flex: 1;
      }

      & > ion-row {
        flex-wrap: nowrap;

        span {
          flex: unset;
          text-align: unset;
        }
      }

      .titleRow {
        padding: 0;
        display: flex;
        align-items: center;
      }

      .title {
        flex: 1;
        text-transform: uppercase;
        color: var(--ion-text-color-dark);
      }
    }
  }

  .input {
    flex: 1;
    flex-wrap: nowrap;
    width: auto;

    & > ion-item {
      width: 80px;
    }
  }

  .buttonInput {
    margin: 0 8px;
  }
}

.deleteButton {
  background: var(--ion-color-danger);

  span,
  svg {
    color: var(--ion-text-color-reversed) !important;
  }
}
