@import 'theme/mixins';

.content {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 16px;
  --padding-end: 16px;

  .titleRow {
    margin-bottom: 8px;

    .title {
      display: block;
      text-transform: uppercase;
    }

    .clearButton,
    .mFButton {
      position: relative;
      text-decoration: none;
      margin-inline-start: auto;
      @include increaseTouchTargetSize(50%, 0, 50%);
    }
  }
}

.recentlyViewedWrapper {
  @include horizontalScroller(8px, 24px);
}
