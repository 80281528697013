@import 'theme/mixins';

.homePage {
  .homeHeader {
    & :global(.header-background) {
      border: none;
      background: transparent;
      backdrop-filter: none;
    }

    &.productSearchHeader {
      ion-buttons[slot='end'] {
        ion-button:last-child {
          margin-inline-end: 36px;
        }
      }
      .customerData {
        svg {
          color: var(--mipro-color-white);
        }
      }
      .eyebrow {
        display: none;
      }
    }
    .customerData {
      span {
        color: var(--mipro-color-white);
        pointer-events: none;
        @include textEllipsis();
      }
    }

    .headerActions {
      display: block;
      margin-bottom: 9px;
      margin-inline-end: 0;
      margin-inline-start: 0;
      position: absolute;
      top: 24px;
      right: 16px;
      z-index: 1000;
    }

    .searchBarWrapper {
      padding-inline: 16px;
      padding-bottom: 16px;
      align-items: center;

      .selectBtn {
        z-index: 2;
        height: 46px;
        --border-radius: 0px;
        border-radius: 0px;
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
        padding-left: 8px;
        padding-right: 8px;

        span,
        svg {
          font-size: 12px;
        }
      }
      .searchBar {
        flex: 1 1;

        .inputRow {
          padding-left: 8px;
          border-bottom-right-radius: 6px;
          border-top-right-radius: 6px;
        }
      }
    }
  }
}

.content {
  --padding-top: 8px;
  --padding-bottom: 16px;
  --padding-start: 16px;
  --padding-end: 16px;
  --background: transparent;
}

.productSearch {
  --padding-bottom: 16px;
  --background: transparent;
}

.titleRow {
  align-items: center;
  justify-content: space-between;
}
.bottomSectionWrapper {
  background-color: var(--ion-background-color);
  z-index: 2;
  margin-left: -16px;
  margin-right: -16px;
  padding-top: 16px;
}

.background {
  position: fixed;
  height: 412px;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
  background: linear-gradient(
      150.19deg,
      rgba(203, 203, 203, 0.2) 10.35%,
      rgba(179, 179, 179, 0) 67.02%
    ),
    linear-gradient(180deg, #252525 0%, rgba(51, 51, 51, 0.4) 98%),
    linear-gradient(0deg, #333333, #333333),
    linear-gradient(0deg, #202020, #202020), #202020;
}

.title {
  text-transform: uppercase;
  color: var(--ion-text-color-reversed);
}

.slides {
  margin: 0 -16px;
  padding-bottom: 16px;
  // override default swiper styles
  &:global(.swiper .swiper-slide) {
    $minCardSpacing: 20px;
    $minCardWidth: 280px;
    $maxCardWidth: $minCardWidth * 1.25;

    width: calc(70vw + $minCardSpacing);
    max-width: $maxCardWidth;
    min-width: $minCardWidth;
    padding: 0 $minCardSpacing * 0.5;
    margin: 0 auto;
    font-size: unset;
  }
}

.tabs {
  margin: 20px 0 8px;
  ion-segment-button {
    height: 15px;

    &::part(indicator) {
      display: none;
    }
  }

  span {
    opacity: 0.35;
    font-family: var(--ion-font-family);
    font-size: 11px;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    transition: opacity 300ms linear;
    color: var(--ion-text-color-reversed);
  }

  .selectedTab {
    span {
      opacity: 1;
    }
  }
}

.tabsRow {
  height: 1px;
  width: 100%;
  background: var(--ion-text-color-dark);
  margin-bottom: 24px;

  .selectedRow {
    height: 1px;
    background: var(--ion-color-secondary);
    transition: transform 300ms linear;
  }
}

.refreshHomeBanner {
  min-height: var(--refresher-height);
  padding: 8px 0;
  background-color: var(--ion-item-background-reversed);

  ion-row {
    justify-content: center;
    align-items: center;

    svg,
    ion-spinner {
      width: 12px;
      height: 12px;
      font-weight: 900;
      color: #7c7a7a;
    }
    span {
      font-size: 12px;
      color: #7c7a7a;
    }
  }
}

.forbiddenSummariesWarning {
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  .iconRow {
    padding-block-start: 20px;
    .icon {
      font-size: 38px;
      color: var(--ion-color-primary);
    }
  }
  .warningRow {
    display: flex;
    justify-content: center;
  }
  .warningHeaderRow {
    width: 60%;
    padding-block-start: 32px;
  }
  .warningMsgRow {
    width: 70%;
    padding-block-start: 32px;
    padding-block-end: 20px;
  }
  .warningText {
    font-family: var(--ion-font-family);
    --text-default-color: var(--ion-color-primary-contrast);
    text-align: center;
  }
  .warningHeaderText {
    font-weight: bold;
    font-size: 15px;
  }
  .warningMsgText {
    font-size: 14px;
  }
  .dismissButton {
    width: 175px;
    height: 40px;
    --border-radius: 4px;
    --border-width: 2px;
    --border-color: var(--ion-color-primary);
  }
}

.spinner {
  position: fixed;
}
