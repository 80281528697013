.sheetModal {
  &::part(handle) {
    width: 50px;
    background: var(--color-help-modal-handle);
  }

  .content {
    --padding-bottom: 0px;
    --padding-start: 0;
    --border-radius: 15px;
    --backdrop-opacity: 0.5;
    --width: auto;
  }
}

.picker {
  --wheel-fade-background-rgb: 255, 255, 255;
  min-width: 100%;
}

.buttons {
  margin-bottom: 8px;
  margin-top: 24px;
  padding: 16px 16px 0px 16px;
  text-align: center;
  display: flex;
  border-top: 1px solid var(--ion-border-color);
}

.datePickButton {
  flex: 1;
  height: 42px;
  width: 150px;
  border-radius: 4px;

  &:first-child {
    margin-inline-end: 16px;
  }
}

.alert {
  margin-inline: 16px;
}
