.filterHeader {
  .filterButton {
    height: 30px;
    padding: 0px 10px;
    border-radius: 25px;
    background-color: var(--ion-color-primary);
    &.hasFilters {
      background: var(--mipro-color-pale-blue);
      border: none;
      svg {
        color: var(--mipro-color-dark-text) !important;
      }
      &:hover svg {
        color: var(--mipro-color-dark-text);
      }

      span {
        border-radius: 50%;
        height: 14px;
        width: 14px;
        background: var(--mipro-color-primary);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &.filterNone {
      background-color: var(--ion-color-dark);
      border-color: var(--mipro-color-white);
      border: 1px solid var(--mipro-color-white);
      svg,
      span {
        color: var(--mipro-color-white) !important;
      }
    }

    span,
    svg,
    img {
      font-weight: normal;
      font-size: 12px;
      color: var(--ion-color-primary-contrast) !important;
    }

    svg {
      font-size: 15px;
    }
  }
}

.filter {
  .filterButton {
    height: 30px;
    padding: 0px 10px;
    border-radius: 25px;
    background-color: var(--ion-color-primary);
    &.hasFilters {
      background: var(--mipro-color-pale-blue);
      border: none;
      svg {
        color: var(--mipro-color-dark-text) !important;
      }
      &:hover svg {
        color: var(--mipro-color-dark-text);
      }

      span {
        border-radius: 50%;
        height: 14px;
        width: 14px;
        background: var(--mipro-color-primary);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.filterNone {
      background-color: var(--ion-item-background);
      border: 1px solid var(--ion-color-light-contrast);

      span,
      svg,
      img {
        color: var(--mipro-color-dark-text) !important;
      }
    }

    span,
    svg,
    img {
      font-weight: normal;
      font-size: 12px;
      color: var(--ion-color-primary-contrast) !important;
    }

    svg {
      font-size: 15px;
    }
  }
}
