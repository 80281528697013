.content {
  height: 100%;
  overflow-y: auto;
}

.accordion {
  margin-top: 16px;
}

.section {
  padding: 0 16px 16px;

  ul {
    margin-top: 0px;
    li {
      span {
        font-weight: 400;
        line-height: 120%;
      }
    }
  }
}
