.segment {
  --background: transparent;
  --text-color: var(--text-default-color);

  .button {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --margin-top: 0;
    --margin-bottom: 0;
    --margin-start: 0;
    --margin-end: 0;

    ion-row {
      align-items: center;
    }

    .total {
      margin-inline-start: 4px;
      color: var(--ion-color-medium);
    }
  }
}

.whiteTabs {
  --text-color: var(--text-default-color);

  ion-segment-button {
    height: 36px;
    min-height: unset;
    --background: var(--ion-item-background);
    --checkbox-background-checked: var(--ion-color-primary);
    --background-focused: var(--ion-item-background);
    --background-focused-opacity: 0;
    --background-hover: var(--ion-item-background);
    --indicator-color: none;
    border: 1px solid var(--ion-color-medium);

    &:not(:last-child) {
      border-right: none;
    }
  }

  span {
    text-transform: initial;
    color: var(--text-color);
  }

  .selectedTab {
    color: var(--ion-text-color-reversed);
  }
}

.transparentTabs {
  ion-segment-button {
    height: 42px;
    min-height: unset;
    background: transparent;
    --checkbox-background-checked: transparent;
    --background-focused: transparent;
    --background-focused-opacity: transparent;
    --background-hover: transparent;
    --background-hover-opacity: transparent;
    --indicator-color: var(--ion-color-secondary);
    --indicator-height: 4px;

    &::part(native) {
      --ripple-color: transparent;
    }
  }
}

.roundedTabs {
  ion-segment-button {
    min-width: unset;
    padding: 0 10px;
    --border-radius: 25px;
    border: 1px solid var(--ion-color-light-contrast);
    &::before{
      opacity: 0;
    }
  }

}

.miproWhite {
  --background: var(--ion-color-primary-contrast);
  border-top: 1px solid var(--mipro-color-light-gray);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.10);
  margin-bottom: 16px;

  ion-segment-button {
    --indicator-color: var(--mipro-color-light-red);
    --indicator-height: 4px;
    min-height: 45px;

    span {
      color: var(--mipro-color-dark-gray);
      text-transform: uppercase;
      letter-spacing: 0.51px;
    }
  }

  .selectedTab {
    color: var(--mipro-color-dark-text);
  }

}
