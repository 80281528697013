@import 'theme/mixins';

.modal {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --background: var(--ion-background-color);
}

.modalWrapper {
  .fa-plus-circle {
    height: 24px !important;
    width: 24px !important;
  }

  .sendLoader {
    ion-spinner {
      color: var(--ion-color-secondary-contrast);
    }
  }
}

.header {
  border-bottom: 1px solid var(--ion-border-color);
}

.errWarning {
  color: var(--ion-color-danger);
  font-style: normal;
}

.titleRow {
  margin-bottom: 8px;

  .addRecipientButton {
    position: relative;
    margin-inline-start: 8px;
    @include increaseTouchTargetSize();

    .plusCircle {
      height: 24px;
      width: 24px;
    }
  }
}

.recipientsWrapperInverted,
.recipientsWrapper {
  .recipientButtonInverted,
  .recipientButton {
    margin: 0 8px 8px 0;
    padding: 4px 8px 4px 16px;
    border-radius: var(--ion-avatar-size);
    align-items: center;
    background: var(--ion-item-background);

    ion-button {
      position: relative;
      margin-inline-start: 8px;
      @include increaseTouchTargetSize();

      svg {
        color: var(--ion-color-medium);
      }
    }

    &.withoutButton {
      padding: 0 16px;
    }
  }

  .recipientButtonInverted {
    background: var(--ion-background-color);
    padding: 4px 8px;
  }
}

.selectedContacts {
  margin: -16px -16px 16px;
  padding: 16px 16px 8px;
  background: var(--ion-item-background);
  border-bottom: 1px solid var(--ion-border-color);
}

.recipientsWrapperInverted {
  margin-top: 0;
  margin-bottom: 0;
}

.searchWrapper {
  margin-top: 16px;
}

.searchbar {
  --searchbar-border-color: var(--ion-text-color);
}

.contactsList {
  margin: 0 -16px;
}

.contactName {
  margin-inline-end: 4px;
}

.contactCard {
  --min-height: 72px;

  .contactRow {
    flex-direction: column;
  }

  &:first-child {
    border-top: 1px solid var(--ion-border-color);
  }

  .withDescription {
    margin-bottom: 4px;
  }

  &.selected {
    --action-row-background: rgba(var(--ion-color-success-rgb), 0.05);

    ion-button {
      svg {
        color: var(--ion-color-success);
      }
    }
  }
}

.subjectInput {
  margin: 16px 0;
}

.buttonsWrapper {
  padding: 16px;
  border-top: 1px solid var(--ion-border-color);

  ion-button {
    flex: 1;
  }

  .loader {
    ion-spinner {
      width: 16px;
      height: 16px;
    }
  }

  ion-button:first-child {
    margin-inline-end: 16px;
  }
}

.warningMessage {
  margin: 16px;
}
