.productHeader {
  padding: 16px;
  background: white;
  .row {
    justify-content: space-between;
    .mfrName {
      flex: 1;
    }
  }
  .badge {
    margin-inline-end: 4px;
  }

  .lineNumberCol {
    display: flex;
    .lineNumber {
      width: 20px;
    }
  }

  .imgCol {
    flex-grow: 0;
  }

  .img {
    width: 80px;
    height: 80px;
    margin-inline-end: 8px;
    border-radius: 10px;
    border: 1px solid var(--ion-border-color);
    &::part(image) {
      border-radius: inherit;
    }
  }

  .itemWrapper {
    padding-top: 8px;
  }

  .secondaryText {
    padding: 0 4px;
    line-height: 14px;
    display:inline-block;
  }

  .secondaryText:first-child {
    padding-left: 0;
  }

  .secondaryText:not(:last-child) {
    border-right: 1px solid var(--ion-border-color);
  }

  .secondaryTextGreen {
    font-weight: 700;
    color: var(--mipro-sss-green);
  }

  .mfgPartNumber {
    span {
      text-align: left;
      text-wrap: wrap;
    }
  }

  .mfgPartNumberLabel {
    word-break: break-word;
  }

  .infoText {
    padding-inline-end: 6px;
  }
  .shopModalBtn {
    display: flex;

    span {
      margin-right: auto;
    }
  }
.showRepairDetails{
  .ionCol{
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .infoText {
    display: inline-flex;
    gap: 3px;
  }

  .labelAndField{
display: inline-flex;
padding-right: 4px;
  }
}
}
