[text-anchor='start'] {
  font-family: var(--ion-font-family) !important;
  fill: var(--bar-chart-color) !important;
  font-size: 11px !important;
}
[text-anchor='end'] {
  font-family: var(--ion-font-family) !important;
  fill: var(--ion-color-dark) !important;
  font-size: 11px !important;
}

[text-anchor='middle'] {
  font-family: var(--ion-font-family) !important;
  font-size: 11px !important;
}
