@import 'theme/mixins';

.content {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 16px;
  --padding-end: 16px;

  &.tableReport {
    --background: var(--ion-item-background);
    --padding-start: 0px;
    --padding-end: 0px;
    --padding-top: 0px;
    --padding-bottom: 0px;
  }

  .actionRowLink {
    --height: 40px;
    --min-height: 40px;

    .linkButton {
      height: 40px;
      padding-inline-start: 5px;
      --border-width: 0px;

      svg {
        width: 18px;
        height: 15px;
      }

      span {
        font-size: 16px;
        font-weight: 700;
        font-family: var(--ion-font-family-alt);
        padding-inline-start: 5px;
        text-transform: uppercase;
      }
    }
  }
}


.overlayInfoBar {
  padding: 8px 16px;
  margin-inline: -16px;
  height: 52px !important;
}


.reportHeaderRow {
  position: sticky;
  top: -16px;
  z-index: 2;
  margin: -16px -16px 16px;
  align-items: center;
  flex-wrap: nowrap;
  background: var(--mipro-color-white);
  border-bottom: 1px solid var(--ion-border-color);
}

.actionRowEOM {
  border: none;
  width: 100%;

  .linkButton {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    span {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: start;
    }
  }
}

.newReportToggle {
  padding-bottom: 8px;
  padding-inline-end: 16px;

  ion-item {
    --background: var(--mipro-color-white);
  }
}
