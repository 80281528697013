.badgeSecondary {
  background: var(--mipro-color-dark-gray);
}

.badgeWarning {
  background: var(--mipro-color-warning-activity);

  span {
    color: var(--mipro-color-dark-text);
  }
}

.clear {
  background: transparent;
  border : 1px solid var(--mipro-color-black);
  span {
    color:  var(--mipro-color-black);
  }
}

.cancelledWo {
  background: var(--mipro-color-dark-gray);
}
