.card {
  width: calc((100vw - (32px)) / 2.333);

  @media screen and (min-width: 768px) {
    width: calc((100vw - (32px)) / 4.333);
  }

  padding: 8px;
  margin: 0;
}

.corpIcon {
  width: 13px;
  height: 16px;
  margin-right: 4px;
  vertical-align: middle;
  text-align: center;
  position: relative;
  float: left;
  color: var(--mipro-color-purple);
}

.title {
  margin-bottom: 4px;
  color: var(--ion-text-color-dark);
}
