.signatureWrapper {
  position: relative;

  .signaturePlaceholder {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  span {
    color: var(--mipro-color-disabled-tint);
    font-family: var(--ion-font-family);
    font-size: 24px;
    font-weight: 400;
    line-height: 16px;
  }

  .signatureCanvas {
    width: calc(100vw - 40px);
    height: 200px;
    margin: 16px 20px;
    background: var(--mipro-color-background);
    border: 1px solid var(--ion-border-color);
  }
}

.rowHeading {
  padding-left: 16px;
  justify-content: space-between;

  .textColor {
    --text-default-color: var(--mipro-color-dark);
    padding-inline-start: 4px;
  }

  .clearButton {
    text-decoration: none;
    padding-inline-end: 22px;
  }
}
