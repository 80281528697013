.wrapper {
  --searchbar-border-color: var(--ion-border-color);

  .input {
    ion-row {
      border-color: var(--searchbar-border-color) !important;

      & svg,
      ion-button svg {
        color: var(--ion-text-color);
      }

      & svg,
      ion-button {
        margin: 0 8px;
      }

      ion-input {
        --padding-top: 8px;
        --padding-bottom: 8px;
        --padding-start: 0px !important;
        --padding-end: 8px;
      }
    }
    .focusedSearch {
      --searchbar-border-color: var(--mipro-color-primary);
      background: var(--mipro-color-pale-blue);
      border: 2px solid;
    }
  }

  &.dark {
    .input {
      --background: var(--ion-input-background);

      ion-row {
        background: var(--ion-input-background);
        border-color: var(--ion-input-background) !important;

        & svg,
        ion-button,
        ion-input {
          color: var(--ion-text-color-reversed);
        }
      }
    }
  }
}
