.announcementList {
  .loader {
    margin-top: 16px;
  }

  .list > :nth-child(1) {
    z-index: 2 !important;
  }

  .warningMessage {
    margin: 16px;
  }

  .wrapper {
    width: 100%;
    max-height: 59px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .warningMessage {
    margin: 16px;
  }
}

.toolbar {
  --padding-top: 0;
  --padding-bottom: 0;
  --min-height: 20px;
  height: 46px !important;
  min-height: 30px !important;
  padding-bottom: 16px;
}

.headerActions {
  margin-inline-end: 8px;
}

.announcement {
  .line {
    margin-left: 0;
    width: 51px;
    height: 4px;
    background-image: linear-gradient(to top, #bf1300, #f51900);
  }

  .content {
    font-size: 15px;
    color: var(--ion-color-dark-tint);
    --padding-top: 16px;
    --padding-bottom: 16px;
    --padding-start: 16px;
    --padding-end: 16px;
  }

  .warningMessage {
    margin: 16px;
  }

  .loader {
    top: 50%;
    left: 50%;
    z-index: 1;
    position: absolute;
    padding: 18px 14px;
    border-radius: 10px;
    transform: translate3d(-50%, -50%, 0);
    background: white;
  }
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 16px 8px 8px;
  font-weight: 600;
  background: var(--ion-background-color);
}

.divider::after,
.divider::before {
  content: '';
  border: 1px solid var(--ion-border-color);
  flex: 1;
}

.divider:not(:empty)::before {
  margin-right: 8px;
}

.divider:not(:empty)::after {
  margin-left: 8px;
}

.hidden {
  max-height: 1px;
  visibility: hidden;
}
.cardOverwrite {
  border-bottom: 1px solid var(--ion-border-color);
  --padding-end: 10px;
  --padding-start: 10px;
}
