.changeFormat {
  text-align: right;
  margin-left: auto;
  position: relative;
  padding-inline-start: 14px;

  &.positive {
    color: var(--color-positive);
    .value {
      --text-default-color: var(--color-positive);
    }
  }
  &.negative {
    color: var(--color-negative);
    .value {
      --text-default-color: var(--color-negative);
    }
  }

  .icon {
    font-size: 11px;
    transform: translateY(-44%);
    position: absolute;
    top: 44%;
    left: 0;
  }

  .value {
    line-height: 1.4;
  }
}
