@import 'theme/mixins';

.fullWidthModal {
  --width: 95%;

  ion-img {
    height: calc(100vh - 300px);
  }
}

.imageUploadContainer {
  --padding-top: 0;
  background-color: var(--ion-color-light);
  padding: 0 16px;
  width: 100%;

  & > div,
  .fullEditorRow {
    height: 100%;
  }

  ion-col {
    padding: 0;
  }
}

.inputRow {
  align-items: center;
}

.captionInput {
  margin-bottom: 16px;
}

.uploadBtn {
  @include increaseTouchTargetSize(50%, 0, 50%);
}

.uploadCol {
  text-align: center;
}

.buttons {
  padding: 0 16px 16px 16px;
}

.button {
  width: 100%;
  height: 50px;
}

.openModal {
  width: 72px;
  height: 72px;
  cursor: pointer;
  box-sizing: content-box;
  --background: var(--mipro-color-light-gray);
  --border-width: 0px;
}
