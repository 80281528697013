.modal {
  --padding-top: 0;
  --padding-bottom: 0;

  .minoRow {
    margin-block-start: 16px;

    .text {
      width: 50%;
      --text-default-color: var(--mipro-color-dark-text);
    }
  }

  .formInput {
    margin-block-start: 16px;
    width: 100%;
    &.firstInput {
      margin-block-start: 24px;
    }
  }

  .inputItem {
    border-radius: 6px;
    border: 1px solid var(--mipro-color-black);
  }

  .quantityRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .lengthInput ion-col > span {
      display: none;
    }
  }

  .errMsg {
    color: var(--ion-color-danger);
  }

  .errorWrapper {
    padding-block-end: 4px;
  }

  .buttonRow {
    padding: 20px 0px 10px 0px;

    .addButtonWrapper {
      width: 50%;
    }

    .anotherButtonWrapper {
      width: 50%;
      padding-inline-end: 20px;
    }

    .addItemButton {
      width: 100%;
    }
  }
}

.modalHeader {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--mipro-color-light-gray);
  padding: 18px 0px;

  ion-button {
    top: 22px;
    right: calc(100% - 26px);
  }

  span {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    color: var(--mipro-color-dark-text);
  }
}
