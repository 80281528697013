.FIE_topbar-save-wrapper,
.FIE_topbar-reset-button,
.FIE_tool-options-wrapper,
.FIE_text-tool-button,
.FIE_image-tool-button,
.FIE_polygon-tool-button,
.FIE_pen-tool-button,
.FIE_line-tool-button,
.FIE_rotate-tool-button,
.FIE_crop-tool,
.FIE_flip-x-tool-button,
.FIE_flip-y-tool-button {
  display: none !important;
}

.FIE_annotation-controls-overlay {
  z-index: 100 !important;
}

/* phone override */
.FIE_phone-tools-tabs-wrapper {
  align-items: flex-start;
  position: absolute;
  top: 30px;
  z-index: 1;
}

.FIE_phone-tools-tabs-wrapper .FIE_tabs {
  order: 0;
}

.FIE_phone-tools-tabs-wrapper .FIE_tools-bar-wrapper {
  margin: 0 !important;
}

/* tablet override */
.FIE_main-container {
  display: flex;
  flex-direction: column;
}

.FIE_main-container .FIE_tabs {
  width: 100%;
  display: flex;
  padding: 0;
  overflow: unset;
}

.FIE_main-container .FIE_tools-bar-wrapper {
  background: rgba(248, 250, 251, 1);
  order: 1;
  padding: 0;
  margin: 0;
}

.FIE_main-container .FIE_canvas-container {
  order: 2;
}

.FIE_main-container .FIE_editor-content {
  width: 100%;
}

.FIE_tabs .FIE_tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px !important;
  padding: 8px;
  margin: 0;
  border-radius: 0;
}

.FIE_tabs .FIE_tab svg {
  height: 16px;
  width: 16px;
}

.FIE_tabs .FIE_tab label {
  margin: 0;
  margin-left: 6px;
  font-size: 12px;
  line-height: 14px;
}
