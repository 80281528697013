@import 'theme/mixins';

.detail {
  min-height: 34px;
  padding: 8px 0;
  border-top: 1px solid var(--ion-border-color);
  align-items: center;

  &:first-child {
    border: none;
  }

  .label {
    width: 150px;
    display: block;
    line-height: 18px;
    text-transform: uppercase;
  }

  .value {
    line-height: 18px;
    white-space: pre-wrap;
    text-align: left;
    color: var(--ion-text-color-light);

    &.positiveText {
      color: var(--ion-color-success);
    }

    &.negativeText {
      color: var(--ion-color-danger);
    }
  }

  .linkValue {
    position: relative;
    @include increaseTouchTargetSize(50%, 0%, 50%);
  }
}

.searchButton {
  display: flex;
  width: 220px;
}

.loader {
  margin-top: 16px;
  padding-bottom: 16px;
}

.activityDataWrapper {
  margin-top: 16px;
}

.ocnLinesAccordion {
  margin-top: 16px;
  border-bottom: 1px solid var(--ion-border-color);
  ion-item {
    border: 1px solid var(--ion-border-color);
    border-bottom: none;
    position: relative;
  }

  .count {
    margin-inline-start: 8px;
  }
}
