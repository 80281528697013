.cardHeader {
  flex-direction: row;
  .pick12Icon {
    top: 4px;
  }
}

.prefix {
  margin-inline-end: 4px;
}

.title {
  text-transform: uppercase;
}

.suffix {
  align-self: flex-end;
  line-height: 22px;
  margin-inline-start: 4px;
  --text-default-color: var(--ion-color-medium);
}

.icon {
  top: 2px;
  display: block;
  position: relative;
  color: var(--ion-color-primary);
  margin-inline-start: auto;
}
