@import 'theme/mixins';

.workOrderList {
  .warningMessage {
    margin: 16px;
  }

  .content {
    --padding-top: 16px;
    --padding-bottom: 16px;
    --padding-start: 16px;
    --padding-end: 16px;
    .recentlyViewedWrapper {
      @include horizontalScroller(8px, 24px);
    }
    .suggestionCard {
      margin: 0 -16px;
    }
  
    .card,
    .loader {
      margin-top: 16px;
    }

    .titleRow {
      margin-bottom: 8px;

      .title {
        display: block;
        text-transform: uppercase;
      }
  
      .clearButton,
      .mFButton {
        position: relative;
        text-decoration: none;
        margin-inline-start: auto;
        @include increaseTouchTargetSize(50%, 0, 50%);
      }
    }
  }

  .workOrdersSearchRow {
    padding: 0 16px;
    margin-bottom: 16px;

    .searchbar {
      flex: 1;
    }

  }
}

.subHeading {
  display: block;
  padding-bottom: 16px;
  text-transform: uppercase;
}



.refresher {
  margin: 0 0 16px;
}

.OfflineBanner {
  min-height: var(--refresher-height);
  padding: 8px 0;
  background-color: var(--ion-item-background);
  .wrapper {
    justify-content: center;
    align-items: center;
  }
}

.loaderWrap {
  padding-top: 16px;
}
