$datepicker__background-color: #fff !default;
$datepicker__border-color: #fff !default;
$datepicker__highlighted-color: #f51900 !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #0c6cd4 !default;
$datepicker__text-color: #333333 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;
$datepicker__border-radius: 20px !default;
$datepicker__day-margin: 0px 0px !default;
$datepicker__font-size: 16px !default;
$datepicker__font-family: var(--ion-font-family), SansSerif, serif;
$datepicker__item-size: 40px !default;
$datepicker__margin: 12px !default;
$datepicker__navigation-size: 16px !default;
$datepicker__triangle-size: 8px !default;

/* Datepicker */
@import '~react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker {
  width: 100%;
  border-radius: 0;

  .react-datepicker__month--disabled,
  .react-datepicker__quarter--disabled {
    color: $datepicker__muted-color;
    background: $datepicker__background-color;
  }
}

.react-datepicker__month-container {
  width: 100%;
  border-radius: 0;
}

.react-datepicker__time-container {
  width: 100%;
}

.react-datepicker__time-box {
  width: 100% !important;
}

.react-datepicker-time__header {
  font-weight: 600;
  line-height: 40px;
}

.react-datepicker__day--outside-month {
  color: var(--ion-color-medium);
}

.react-datepicker__month-text--keyboard-selected {
  background: $datepicker__background-color;
  color: $datepicker__header-color;
}

.react-datepicker__day--selected,
.react-datepicker__day--today {
  &:focus {
    outline: 0;
  }
}

.react-datepicker__month-text {
  border-radius: 10px;

  &:hover {
    border-radius: 10px;
  }
}

.react-datepicker__monthPicker {
  width: 300px;
}

.react-datepicker__month {
  margin: 0 auto;
  text-align: center;

  .react-datepicker__month-wrapper {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    margin: 41px 0;
  }

  .react-datepicker__month-text {
    font-weight: 600;
    line-height: 36px;
    height: 36px;
  }
}

.react-datepicker__quarter {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-inline: 24px;
    margin-block: 24px;
    gap: 16px;
  }

  &-text {
    padding-block: 1rem;
    flex: 1 1 45%;
    margin: 0 !important;
    color: $datepicker__selected-color;
    border-radius: 8px !important;
    border: 1px solid var(--mipro-color-background);

    &--disabled {
      color: var(--mipro-color-medium-shade) !important;
      background-color: var(--mipro-color-disabled) !important;
    }

    &--selected {
      border-color: var(--mipro-color-background) !important;
    }
  }
}

.react-datepicker__month,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
  &--selected {
    background: $datepicker__selected-color;
    color: var(--ion-color-light);
    outline: 0 none;

    &:hover {
      background: $datepicker__selected-color;
      color: var(--ion-color-light);
    }
  }
}

.react-datepicker__day {
  outline: none;
  &--today {
    color: $datepicker__selected-color;
  }

  &--selected {
    background: none;
    outline: 0 none;

    &:hover {
      background: none;
    }
  }
}

.react-datepicker__day-names {
  border-bottom: 1px solid var(--ion-border-color);
  margin-bottom: 0;
}

.react-datepicker__header {
  padding: 0;
}
