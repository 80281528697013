@import 'theme/mixins';

.filterRow {
  justify-content: space-between;
  align-items: center;
}

.tabsRow {
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: auto;
  position: relative;

  .roleTabs {
    border-radius: 0px;
    padding: 16px;

    ion-segment-button {
      min-width: unset;
      text-transform: capitalize;
      white-space: nowrap;
      margin: 0 3px;
    }

    ion-segment-button:first-of-type {
      margin-left: 0px;
    }

    span {
      font-family: var(--ion-font-family);
      font-weight: 500;
      font-size: 12px;
      color: var(--ion-text-color-dark);
    }

    .selectedTab {
      border: none;
      --indicator-color: var(--mipro-color-dark-blue);

      span {
        font-weight: 500;
        color: var(--ion-color-light);
      }
    }
  }

  .endShadow {
    @include endShadow();
  }
}

.drilldownTable {
  padding-inline-start: 0px !important;

  .tableTH,
  .tableTD {
    border-right: 1px solid var(--mipro-color-light-gray);
  }

  .tableTH > div {
    min-width: 90px;
  }

  .tableTD:not(:first-child) > div {
    min-width: 90px;
  }

  .rowLink {
    width: 90px;
    ion-button {
      width: 90px;
      font-size: 15px;
      font-weight: bold;
      font-family: var(--ion-font-family-alt);
      color: var(--ion-color-primary);
      span {
        white-space: break-spaces;
        word-break: break-word;
      }
    }
    span {
      width: 100%;
      text-align: left;
    }
  }
  .secondaryText {
    line-height: normal;
    word-wrap: normal;
    word-break: normal;
    font-size: 11px;
  }

  .icon {
    height: 15px;
    color: var(--ion-color-primary);
  }
}

.loader {
  margin-top: 16px;
}

.warningMessage {
  margin: 16px;

  .changeLocBtn {
    margin-top: 20px;
    --padding-start: 12px;
    --padding-end: 12px;
  }
}

.refresher {
  margin: -16px -16px 16px;
}

.pick12Refresher {
  margin: 0;
}

.sharedBusinessDayLabel {
  display: block;
  margin-top: 16px;
  color: var(--ion-color-warning);
}

.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 5;
  background: var(--ion-item-background);
}

.pick12Cont {
  .pick12Dots {
    margin-inline: 0;
    padding-inline-end: 4px;
    text-align: end;
  }
}

.pick12Menu {
  --offset-x: -5px;
  --offset-y: 20px;

  span {
    font-size: 11px;
    font-family: var(--ion-font-family);
    font-weight: 600;
    color: var(--ion-color-primary);
  }
}

.unbilledTH {
  color: var(--mipro-color-dark-text) !important;
}

.unbilledTD,
.unbilledTH {
  &:not(:nth-child(1)) > div {
    min-width: 130px !important;
  }
}

.infoIcon {
  padding-inline-start: 8px;
}

.reportHeader {
  min-height: 90px;
  height: auto !important;
}

.newReportToggle {
  ion-button {
    padding-block-start: 4px;
  }
}

.reportToggle {
  display: flex;
  justify-content: flex-end;
}

.filterCol {
  text-align: end;
}

.filter {
  .filterButton {
    height: 30px;
    padding: 0px 10px;
    border-radius: 25px;
    background-color: var(--ion-color-primary);
    &.hasFilters {
      background: var(--mipro-color-pale-blue);
      border: none;
      svg {
        color: var(--mipro-color-dark-text) !important;
      }
      &:hover svg {
        color: var(--mipro-color-dark-text);
      }

      span {
        border-radius: 50%;
        height: 14px;
        width: 14px;
        background: var(--mipro-color-primary);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.filterNone {
      background-color: var(--ion-item-background);
      border: 1px solid var(--ion-color-light-contrast);

      span,
      svg,
      img {
        color: var(--mipro-color-dark-text) !important;
      }
    }

    span,
    svg,
    img {
      font-weight: normal;
      font-size: 12px;
      color: var(--ion-color-primary-contrast) !important;
    }

    svg {
      font-size: 15px;
    }
  }
}
