.toolbar {
  --padding-top: 0;
  --padding-bottom: 0;

  .searchRow {
    align-items: center;
    margin: 0 6px 16px;
    .searchBar {
      flex: 1;

      ion-row {
        max-height: 42px;
        background: var(--mipro-color-background);
      }
      svg {
        color: var(--mipro-color-dark-text) !important;
      }
    }

    .searchInput {
      border-radius: 6px;
      input {
        text-overflow: ellipsis;
      }
    }
  }
  .filterRow {
    margin: 0 6px 16px;
  }
}

.employeesIconFilter {
  width: 20px;
  font-size: 18px;
  margin-inline-end: 8px;
}
