@import 'theme/mixins';

.recalculateButton {
  width: 100%;
  margin-top: 16px;
}

.content {
  overflow: hidden;
  position: relative;
  flex: 1;

  .wrapper {
    overflow: auto;
    height: 100%;
  }
}

.formSection {
  margin: 16px 0;
  padding: 16px;
  background: var(--mipro-color-white);
}

.input {
  margin-top: 16px;

  &.priceInput {
    margin-top: 0;
  }

  ion-row {
    border-radius: 6px;
  }

  ion-col {
    padding: 0px;
  }
}

.checkbox {
  margin-top: 16px;
  margin-inline-end: 10px;
  --border-radius: 5px;
  --border-width: 1.5px;
  --border-color: var(--mipro-color-medium-gray);
}

.priceOverrideLabel {
  margin: 16px 0 4px;
}

.editCostRow {
  margin-top: 8px;
  display: flex;
  align-items: center;

  .editCostButton {
    display: flex;
    align-items: center;
    margin-inline-start: 8px;

    span,
    svg {
      color: var(--mipro-color-primary);
    }
  }
}

.cancelButton {
  position: absolute;
  right: 0;
}

.editIcon {
  width: 12px;
  height: 12px;
  padding-inline-start: 4px;
}


.checkboxWrapper {
  margin-top: 16px;
  display: flex;
  .btnWrapper {
    position: relative;
    @include increaseTouchTargetSize();
    padding-inline-start: 8px;
    svg {
      font-size: 14px;
      color: var(--mipro-color-primary);

    }
  }
}
