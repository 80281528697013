.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
  .footer {
    border-top: 1px solid var(--ion-border-color);
    padding-top: 1rem;

    .buttonBar {
      display: flex;
      gap: 1rem;

      .fullWidth {
        width: 100%;
      }
    }
  }
}
