.dateSlider {
  position: relative;
  background: var(--ion-item-background);

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .slide {
    height: 83px;
    min-height: 83px;
    text-align: center;
    color: var(--ion-color-medium);
    font-size: 12px;

    &.disable {
      background: rgba(0, 0, 0, 0.15);

      .vLine {
        border-color: var(--ion-color-medium);
      }
    }
  }

  .iconsWrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 53px;
  }

  .vLine {
    height: 16px;
    margin-top: 30px;
    border-left: 1px solid var(--ion-border-color);
  }

  .vLineDark {
    border-left: 1px solid var(--ion-color-medium);
  }

  .iconGray {
    font-size: 12px;
    padding-bottom: 2px;
    color: var(--ion-color-medium);
  }

  .iconGreen {
    font-size: 12px;
    padding-bottom: 2px;
    color: var(--ion-color-success);
  }

  .day {
    color: var(--ion-color-medium);
  }

  .date {
    font-size: 12px;
    color: var(--ion-color-medium);
    text-transform: uppercase;
    padding-left: 47px;
    position: relative;
    margin-top: -20px;
    margin-bottom: 8px;
  }

  .last {
    position: absolute;
    width: calc(100% / 21);
    right: calc(100% / 21);
    height: 100%;
    background-color: var(--ion-color-primary);
    opacity: 0.12;
  }

  .monthBeginning {
    position: relative;
    left: 50px;
    text-align: left;
  }

  .monthLabel {
    width: 100px;
    margin-top: 4px;
  }
}
