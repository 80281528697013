.list > :nth-child(1) {
  z-index: 2 !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  ion-title {
    width: auto;
    text-align: left;
    position: relative;
    padding-block-start: 16px;
    align-self: flex-start !important;
  }
  .filter {
    padding-inline-end: 34px;
    &.filterSelected {
      ion-button {
        border: none;
      }
      svg,
      span {
        color: var(--mipro-color-white) !important;
      }
    }
    ion-button {
      height: 30px !important;
      border-radius: 24px;
      border: 1px solid var(--mipro-color-dark-text);
    }
    svg,
    span {
      color: var(--mipro-color-dark-text) !important;
    }
  }
  .headerActions {
    padding-inline: 0;
    width: 18px;
    padding-block-start: 16px;
  }
}

.multiSelectHeader {
  opacity: 0.8;
  .headerEyebrowBack {
    visibility: hidden;
  }
}
