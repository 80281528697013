@import 'theme/mixins';

.listItemWrapper {
  --background-activated: var(--mipro-list-background-activated);
  --background-activated-opacity: 1;

  .mainIonRow {
    padding-bottom: 8px;
    padding-top: 8px;
    width: 100%;
  }
  .leftText {
    align-self: baseline;
    padding: 8px 16px;
    span {
      line-height: normal;
    }
  }

  .desc {
    line-height: 12px;
  }
}

.disabled {
  --action-row-background: var(--mipro-color-disabled) !important;
  opacity: 0.8;
}

.subTitleRow {
  width: 100%;
  margin-top: -4px;
  margin-bottom: 4px;

  ion-col {
    padding: 0px;
  }
}

.overlayRow {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  span {
    @include textEllipsis(2);
  }
}

.titleRow {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding-bottom: 8px;
  width: 100%;

  .titleWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    @include textEllipsis();
  }
}

.titleRowExtraMargin {
  justify-content: space-between;
}

.secondaryText {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  line-height: 14px;
  width: 100%;
}

.imagecircle {
  width: 45px;
  min-width: 40px;
  height: 45px;
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;

  ion-img::part(image) {
    height: 40px;
    object-fit: contain;
    border: 1px solid var(--mipro-color-light-gray);
    background: var(--mipro-color-white);
    padding: 1px;
    border-radius: 50%;
  }
}

.circle {
  width: 50px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  align-self: flex-start;
  margin-top: 10px;
  background-color: var(--mipro-color-background);

  svg {
    font-size: 16px;
    margin: 0;
  }
}

.shrinkText {
  width: 70%;
}

.title {
  width: 100%;
  span {
    width: 100%;
    text-align: start;
    @include textEllipsis(2);
  }
}

.badge {
  position: absolute;
  right: 0px;
  top: 8px;
}

.arrow {
  color: var(--mipro-color-primary);
}

.iconRow {
  margin-bottom: 8px;
  align-self: flex-end;
}
