.content {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 0;
  --padding-end: 0;

  .card,
  .loader {
    margin-top: 16px;
  }
}

.title {
  display: block;
  text-transform: uppercase;
  padding: 16px;
}

.warningMessage {
  margin: 0 16px;
}
