.modal {
  --padding-top: 0px;
  --padding-bottom: 16px;
  --background: var(--mipro-color-background);
  height: 100%;
}

.searchbar {
  --searchbar-border-color: var(--ion-text-color);
  margin: 16px 0;

  ion-row {
    border-radius: 6px;
  }
}


.followersList {
  margin: 16px -16px 0px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow: auto;

  ion-item {
    --background: var(--ion-item-background);
  }
}
