@import 'theme/mixins';

.modalHeaderToolbar {
  display: flex;
  padding: 16px;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--ion-safe-area-top);
  padding-block-end: 16px;
}

.modal {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --overflow: hidden !important;
}

.imageContainer {
  overflow: auto;
  max-width: 100vw;
  max-height: 100vh;
  background-color: var(--mipro-color-background);
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.zoomWrapper {
  width: 100vw;
  height: 100vh !important;
}

.loader {
  top: 50%;
  left: 50%;
  z-index: 1;
  position: absolute;
  padding: 18px 14px;
  border-radius: 10px;
  transform: translate3d(-50%, -50%, 0);
  background: var(--ion-background-color);
}

.removeConfirmMsg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 24px;
  padding-inline: 16px;
}

.fileIconRow {
  display: flex;
  align-items: center;
  justify-content: center;
  .fileCont {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--mipro-color-white);
    width: 70px;
    height: 70px;
    background-color: var(--ion-color-primary-shade);
    border-radius: 6px;
  }
}

.fileNameRow {
  display: flex;
  justify-content: center;
  padding-block: 16px;
  .fileName {
    max-width: 70%;
  }
}

.removeButton {
  --background: var(--mipro-color-light-red);
}


