@import 'theme/mixins';

.input {
  ion-row {
    border-color: var(--mipro-color-dark) !important;
  }

  ion-input,
  ion-textarea {
    color: var(--mipro-color-dark) !important;
  }

  &.viewonly {
    ion-input,
    ion-textarea {
      display: none;
    }
  }
}

.modal {
  --padding-top: 0px;
  --padding-bottom: 16px;
  --background: var(--mipro-color-background);
}

.modalHeader {
  padding: 16px 16px 0;
  border-bottom: 1px solid var(--ion-border-color);
}

.modalWrapper {
  ion-toolbar {
    padding-bottom: 0px !important;
    border-bottom: 1px solid var(--ion-border-color);
  }
}

.searchWrapper {
  margin-top: 16px;

  .searchbar {
    --searchbar-border-color: var(--ion-text-color);
    margin-bottom: 16px;

    ion-row {
      border-radius: 6px;
    }
  }
}

.list {
  margin: 0 -16px;
  padding-top: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
}

.warningMessage {
  margin: 16px;
}

.selectedItems {
  margin: 0px -16px;
  padding: 16px;
  background: var(--ion-item-background);
  border-top: 1px solid var(--ion-border-color);
  border-left: 0px;
  border-right: 0px;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;

  .itemsWrapper {
    display: flex;
    gap: 8px;
  }

  .selectedItem {
    padding: 4px 8px;
    align-items: center;
    flex-wrap: nowrap;
    flex: 1 0 auto;
    border-radius: var(--ion-avatar-size);
    background: var(--mipro-color-pale-blue);

    ion-button {
      position: relative;
      margin-inline-start: 8px;
      @include increaseTouchTargetSize();

      svg {
        height: 14px;
        width: 14px;
        color: var(----mipro-color-dark-text);
      }
    }

    .itemName {
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.itemCard {
  --min-height: unset;
  --padding-top: 8px;
  --padding-bottom: 8px;

  &:first-child {
    border-top: 1px solid var(--ion-border-color);
  }

  &.selected {
    --action-row-background: var(--mipro-color-pale-blue);
  }

  .iconWrapper {
    align-self: flex-start;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.itemRow {
  flex-direction: column;

  .itemName {
    font-weight: 600;
    margin-inline-end: 4px;
  }
}
