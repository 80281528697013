@import 'theme/mixins';

.content {
  .dataInput {
    flex: 1;
  }

  .pricingLabel {
    font-weight: normal;
    color: #46a25e;
  }

  .marginGPData {
    margin-bottom: -16px;
    border-bottom: 1px solid var(--ion-border-color);
  }

  .priceOverrideData {
    margin: 16px 0 8px;
  }

  .moreInfoWrapper,
  .marginGPData {
    margin-top: 16px;
  }

  .moreInfoWrapper {
    margin-bottom: 16px;
  }

  .moreInfoWrapper,
  .marginGPData,
  .priceOverrideData {
    .moreInfoLabel {
      min-width: 175px;
      width: 50%;
    }
  }
}

.form {
  .rateButtons {
    margin-bottom: 16px;
  }

  .formItem {
    --min-height: 26px;
    --padding-top: 0;
    --padding-bottom: 16px;
    --padding-start: 0;
    --padding-end: 0;
    --border-color: transparent;
    --background: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 1;
    --background-focused: transparent;
    --background-focused-opacity: 1;
    --background-hover: transparent;
    --background-hover-opacity: 1;

    ion-label {
      margin: 0;
      z-index: 2;
    }

    &:last-child {
      --padding-bottom: 0;
    }
  }

  .reasonText {
    font-weight: normal;
  }

  .sectionTitle {
    display: block;
    font-size: 18px;
    padding-bottom: 16px;
  }

  .marginRow {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 8px;

    .marginLabel {
      line-height: 18px;
      margin-inline-end: 16px;
    }

    .marginInput {
      flex: 1;
    }
  }
}

.button {
  width: 100%;
}

.lastSoldMessage {
  display: block;
  margin-bottom: 16px;
  color: var(--ion-color-danger);
}

.notebook {
  .notebookRow {
    min-height: 34px;
    padding: 8px 0;
    border-top: 1px solid var(--ion-border-color);
    align-items: center;
  }

  .notebookLabel {
    min-width: 175px;
    width: 50%;
    display: block;
    line-height: 18px;
    text-transform: uppercase;
  }

  .notebookText {
    display: block;
    line-height: 18px;
    white-space: pre-wrap;
    text-align: left;
    color: var(--ion-text-color-light);

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.approvedBy {
  display: block;
  margin-bottom: 8px;
}

.message {
  span {
    display: block;
  }

  .listStyle {
    display: list-item;
    font-weight: 700;
    margin: 0;
    margin-inline-start: 24px;
    line-height: 24px;
  }
}

.loader {
  margin-top: 16px;
  padding-bottom: 32px;
}

.feedbackButton {
  width: 200px;
  margin-top: 16px;
}

.value {
  line-height: 18px;
  white-space: pre-wrap;
  text-align: left;
  color: var(--ion-text-color-light);
}

.arWidgetCard {
  margin-inline: 0px;
  margin-top: 16px;
  box-shadow: none;
  margin-bottom: 0px;
}
