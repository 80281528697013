@import 'theme/mixins';

.tableContainer {
  margin: 0;
  padding-inline-start: 16px;
}

.tableWrapper {
  width: 100%;
  margin: 0;
  line-height: 1.13;
  text-align: left;
  color: var(--mipro-color-black);
}

.tableWrapper .table {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;

  &.tableDisplay {
    display: table;
  }

  .thead {
    position: sticky;
    top: 0;
    z-index: 3;
    overflow-x: auto;
    overscroll-behavior-x: none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.tableDisplay {
      display: table-row-group;
    }
  }

  .tbody {
    overflow-x: auto;
    overscroll-behavior-x: none;

    &.tableDisplay {
      display: table-row-group;
    }
  }

  .tr {
    display: table-row;
  }
}

.tableWrapper .th,
.tableWrapper .td {
  padding: 16px 8px;
  font-size: 13px;
  vertical-align: middle;
  font-weight: normal;
  min-width: 115px;
  display: table-cell;
  scroll-snap-align: end;
}

.tableWrapper .th:first-child,
.tableWrapper .td:first-child {
  box-shadow: 4px 0px 4px 0px #0000001A;
}

.tableWrapper .th {
  padding: 8px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-table-header-font);
  font-family: var(--ion-font-family-alt);
  border-bottom: 1px solid var(--ion-border-color);
  background: var(--color-table-header-background);
}

.tableWrapper .th.sum {
  color: var(--mipro-color-black);
  background: var(--mipro-color-background);

  &:first-child .thWrapper {
    justify-content: flex-start;
  }

  .thWrapper {
    left: 0;
  }

  span {
    font-weight: 700;
    line-height: 20px;
    font-family: var(--ion-font-family);
    color: var(--mipro-color-black);
  }
}

.tableWrapper {
  .th:first-child,
  .td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }
}

.tableWrapper .th.sorted {
  background: var(--color-table-header-sorted-background);

  .sortedIcon {
    opacity: 1;
  }
}

.thWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  left: -8px;
}

.tdWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sortDownIcon {
  font-size: 14px !important;
  padding: 0;
  margin: 0 4px;
  opacity: 0;
}

.sortUpIcon {
  font-size: 14px !important;
  padding: 0;
  margin: 0 4px;
  opacity: 0;
}

.endSpacing {
  width: 16px;
  padding: 8px;
  display: table-cell;
  background: var(--ion-item-background);
}

.endShadow {
  @include endShadow();
}

.row1Color {
  background: var(--color-table-header-background);

  &.sorted {
    background: var(--color-table-header-sorted-background);
  }
}

.row2Color {
  background: var(--background);

  &.sorted {
    background: var(--color-table-sorted-background);
  }
}
