@import 'theme/mixins';

.jobWarning {
  padding: 16px;
}

.labelText {
  font-family: var(--ion-font-family);
  font-size: 11px;
}

.card {
  width: 100%;
  border-radius: 3px;
  border: 1px solid transparent;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2);
  background: var(--ion-item-background);
  position: relative;
}

.header,
.footer {
  padding: 16px 16px 10px 16px;
  background: var(--ion-background-color);

  ion-row {
    align-items: center;
    justify-content: space-between;
  }

  .footerRowTimeOnly {
    justify-content: center;
  }

  .titleRow {
    text-transform: uppercase;
    flex-wrap: nowrap;

    .iconWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      .infoButton {
        svg {
          margin-left: 4px;
          font-size: 14px;
        }
      }
    }
  }

  .subtitleRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: nowrap;
    line-height: 11px;

    .left {
      flex: 0 1 auto;
      margin-top: -4px; // no idea why we need this, but we do
      min-width: 0; // required for flex-shrink to work here
      margin-right: 30px;
      ion-button {
        width: 100%;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .labelHeader {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .right {
      flex: 0 0 auto;
    }
    .labelHeader {
      @extend .labelText;
      color: var(--ion-text-color-dark);
    }
  }

  .dropdownButton {
    span {
      @extend .labelText;
      text-transform: uppercase;
    }
    svg {
      font-size: 10px;
      margin-inline-start: 2px;
    }
  }

  .footerRow {
    & > span {
      color: #6c6b6b;
    }
  }
}

.errorCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  padding: 32px 0;

  .errorRow {
    padding: 12px 0;
  }

  .errorText {
    font-family: var(--ion-font-family);
    font-size: 14px;
    color: #bebebe;
  }

  .icon {
    font-size: 38px;
    color: #e3e3e3;
  }
}

.typeDropdown {
  // offsets dropdown so as to not obscure date label
  margin-top: 19px;
}

.salesPerfIcon {
  width: 14px;
  height: 14px;
  color: var(--mipro-color-light-red);
  margin-right: 4px;
}

.footerButtonsRow {
  display: flex;
  align-items: center;
  justify-items: center;
  align-items: center;
  gap: 10px;
}

.footerButton {
  width: 100%;
  --border-radius: 6px;
  height: 44px;
}
.primaryButton {
  --background: var(--mipro-color-primary);
}
.secondaryButton {
  --color: var(--mipro-color-primary);
  --border-color: var(--mipro-color-primary);
}
.timestamp {
  width: 100%;
  margin-block-start: 10px;
}
.secondaryBtnIcon {
  width: 19px;
  height: 15px;
  color: var(--mipro-color-primary);
  margin-inline-end: 10px;
}

.betaBadge {
  margin-inline-start: 4px;
  font-size: 8px;
}
