.secondaryText {
  padding-right: 4px;
  line-height: 14px;
  display: inline-block;

  &.lowerCase {
    text-transform: lowercase;
  }
}

.secondaryTextBlue {
  font-size: 12px;
  font-weight: 700;
  color: var(--mipro-sss-blue);
}

.secondaryTextGreen {
  font-weight: 700;
  color: var(--mipro-sss-green);
}

.titlePrefix {
  border-radius: 50%;
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 4px;
  background: var(--mipro-supplier-connect);
  color: var(--ion-color-primary-contrast);
  text-align: center;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
}

.availDiv {
  line-height: 14px;
  line-break: strict;

  .secondaryText {
    --mipro-color-dark-text: var(--mipro-color-dark-gray);
  }
}

.currentProduct {
  --action-row-background: var(--mipro-list-background-activated);
}
