.modal {
    &::part(handle) {
      width: 50px;
      background: var(--color-help-modal-handle);
    }
  .modalMainContent {
    --padding-start: 0px;
    --padding-end: 0px;
  }
}

.modalContentRow {
  padding-inline-start: 30px;
  padding-inline-end: 30px;
  margin-top: 10px;
  padding-bottom: 24px;
}

.header {
  padding-block-start: 14px;
  display: flex;
  justify-content: center;
  padding-block-end: 32px;
}

.row {
  padding-inline-start: 20px;
}

.modalInfoTitle {
  text-align: center;
  white-space: pre-wrap;
}

.modalInfoIcon {
  width: 38px;
  height: 38px;
}

.info {
  color: var(--mipro-color-dark-blue);
}

.warning {
  color: var(--mipro-color-dark-orange);
}

.infoIconWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.closeButtonDivWrapper {
  border-top: 1px solid var(--mipro-color-light-gray);
  padding-inline-start: 19px;
  padding-inline-end: 19px;
  ion-button {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    --border-radius: 6px;

  }
}

