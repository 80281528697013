.salesPlayAcceptedOverlay {
  display: flex;
  flex: 1;
  height: 390px;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;

  h3 {
    color: green;
    font-weight: bold;
    font-size: 24;
  }
}
