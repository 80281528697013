@import 'theme/mixins';
.cardWrapper {
  margin-top: 16px;
  padding: 16px;
  background: var(--ion-color-primary-contrast);

  .contactSel {
    ion-col {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }

  .selectCustInput {
    border-radius: 6px;
    border: 1px solid var(--mipro-color-dark-text);
  }

  .emailRow {
    padding-block-start: 16px;
    align-items: center;

    ion-col {
      padding: 0px;
    }
  }
  .addRecipientButton {
    padding-inline-start: 4px;

    .plusCircle {
      font-size: 14px;
    }
  }

  .errorList {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 4px;
  }
}

.input {
  margin-top: 16px;

  ion-row {
    border-radius: 6px;
  }

  ion-col {
    padding: 0px;
  }
}

.checkboxWrapper {
  margin-top: 16px;
  display: flex;
  align-items: center;
  .btnWrapper {
    position: relative;
    @include increaseTouchTargetSize();
    padding-inline-start: 8px;
    svg {
      font-size: 14px;
    }
    .infoIcon {
      color: var(--mipro-color-primary);
    }
    .warningIcon {
      color: var(--mipro-color-dark-orange);
    }
  }
}

.noMargin {
  margin-top: 0px;
}

.rulesList {
  padding-inline-start: 20px;
  margin-top: 4px;

  li {
    font-size: 14px;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emptyMessage {
  padding: 0;
  padding-top: 16px;
}
