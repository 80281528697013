.artFastFind {
  .p0 {
    padding: 0;
  }

  .alignCenter {
    align-items: center;
  }

  box-shadow: none;

  .inputCol {
    padding: 0;
  }

  &.boxFocus {
    box-shadow: 0 2px 12px 0 var(--ion-color-primary);

    .inputCol {
      z-index: 3;
    }

    .fastFindInput {
      ion-row {
        border-bottom: 0;
      }
    }
  }

  .ionContent {
    z-index: 2;
    border: 1px solid var(--ion-color-primary);
    border-top: 0;
    height: 75px;
    max-height: 200px;

    &.setHeight {
      height: 200px;
    }

    background-color: white;
    > * {
      background-color: white;
    }

    .resultWrap {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 4px;

      .artAsset {
        padding: 10px;
        margin: 0 16px 12px 16px;
        border: 1px solid var(--ion-border-color);
        border-radius: 3px;
      }
    }
    .loaderWrap {
      height: 75px;
    }
    .emptyReponse {
      width: 100%;
      text-align: center;
    }
  }

  .clearIcon {
    z-index: 2;
    opacity: 0.6;
    position: absolute;
    right: 16px;
    top: 12px;
  }
}
