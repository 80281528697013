.findStartCustomerPage {
  .searchWrapper {
    margin: 0 6px 16px;
    .searchBar {
      width: 100%;
    }

    .input {
      --border-radius: 6px;
    }
  }

  .contentWrapper {
    --background: var(--ion-color-primary-contrast);

    .contentTitle {
      padding: 16px;
    }

    .loader {
      margin-top: 20px;
    }

    .itemTitle {
      margin-bottom: 0px;
    }

    .warngMessage {
      margin: 0 16px;
    }

    .warngMessageWithMaringToTop {
      margin: 16px;
    }
  }
}
