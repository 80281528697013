ion-grid {
  --ion-grid-columns: 100;
}

.avatarCol {
  padding-inline-start: 0px;
}
.avatar {
  width: 30px;
  height: 30px;
  margin-inline-end: 0px;
  margin-right: 0px;
}

.avatarFallback {
  align-self: center;
  font-size: 15px;
  color: var(--ion-color-primary-contrast);
}
.leaderItemRow {
  display: flex;
  align-items: center;
  padding: 8px 0px;
  background-color: var(--ion-background-color);
  ion-col {
    display: flex;
    align-items: center;
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .leaderItemRank {
    --text-default-color: var(--ion-text-color-unselected);
    line-height: 10px;
  }
  .leaderboardItemNameCol {
    display: flex;
    flex-direction: column;
  }

  .leaderItemName {
    width: 130px;
    --text-default-color: var(--ion-color-primary);
    font-weight: 500;
    line-height:13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .leaderItemLoc {
    color: #696969;
    font-size: 12px;
    line-height: 13px;
    text-overflow: ellipsis;
  }

  .leaderItemSalesGP {
    color: #696969;
    line-height: 13px;
    text-overflow: ellipsis;
    font-size: 12px;
  }

  .pick12Icon {
    color:red;
    font-size: 8px;
    text-align: center;
  }
}

.leaderItemRowPersonal {
  composes: leaderItemRow;
  background-color: rgba(12, 108, 212, 0.2);
}

