
.documentTable {
  position: relative;
  width:100%;
  margin: auto;
  font-size: 14px;
  padding: 8px 8px 0 8px;
  overflow: auto;
  background-color: #ffffff;
  text-align: left;
}

.documentTable table {
  width: 100%;
  margin: auto;
}

.documentTable th,
.documentTable td {
  padding: 8px;
  border: 1px solid var(--ion-border-color);
  vertical-align: top;
  font-weight: normal;
}

.documentTable thead th {
  background: var(--ion-color-light);
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background: var(--background);
  text-align: right;
  vertical-align: middle;
}

