@import 'theme/mixins';

.resultItem {
  --min-height: 78px;
}

.container {
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.wrapper {
  padding: 16px 0;
  width: 100%;
}

.avatarTitle {
  color: var(--ion-text-color-reversed);
}

.title {
  color: var(--ion-text-color-dark);
}

.avatar {
  margin-inline-end: 8px;
  @include cardAvatar(var(--ion-avatar-size));
}

.withMargin {
  margin-bottom: 4px;
}

.text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.highlighter {
  color: var(--mipro-color-light-red);
}

.noWrapRow {
  flex-wrap: nowrap;
  justify-content: space-between;
  display: flex;
}

.poTitle {
  font-weight: 700;
}

.poLine {
  max-height: 28px;
}

.clampLine {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.grayRow {
  color: var(--mipro-color-dark-gray);
}

.gap {
  margin-block-end: 4px;
}

.address {
  color: var(--ion-text-color-dark);
}

.mainContact {
  color: var(--ion-color-success);
  text-transform: uppercase;
  font-size: 9px;
  display: flex;
  align-items: center;
}

.description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-inline-end: 4px;
}

.maxWidthContainer {
  max-width: 95%;
}

.iconWrapper {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);

    .arrow {
      color: var(--mipro-color-primary);
    }
}

.badge {
  position: absolute;
  right: 0px;
}

.corpDesc {
  display: flex;
  align-items: center;
}

.firstLine {
  display: flex;
  align-items: center;
}

.corpIcon {
  width: 13px;
  height: 16px;
  margin-right: 4px;
  vertical-align: middle;
  margin-bottom: 4px;
  position: relative;
  float: left;
  color: var(--mipro-color-purple);
  &.smallCorpIcon {
    width: 11px;
    height: 13px;
    margin-bottom: 0;
  }
}


