@import 'theme/mixins';

.WebActivity {
  .webActivityContainer {
    padding-block-start: 8px;
    padding-block-end: var(--wrapper-margin);
    padding-inline-end: var(--wrapper-margin);
    padding-inline-start: var(--wrapper-margin);

    .searchesContainer {
      margin-bottom: 16px;
    }

    .productsContainer {
      .webActivityTabs {
        margin-bottom: 16px;
        @media (max-width: 320px) {
          span {
            font-size: 13px;
          }
        }
        .webActivitySelectedTab {
          span {
            color: var(--text-color);
          }
        }
      }
      .productWrapper {
        .productCard {
          margin-bottom: 16px;
        }
      }
    }
    .title {
      text-transform: uppercase;
    }
    .titleLine {
      margin: 8px 0 16px;
    }
  }
}

.searchTime {
  text-transform: capitalize;
  color: var(--ion-color-medium);
  order: 1;
  margin-inline-start: 4px;
}

.filterWrapper {
  @include stickyScroller();
}

.cardMiniWrapper {
  @include horizontalScroller(0, 24px);
  padding-block-start: 0;
}

.loader {
  margin-top: 16px;
}
.warningMessage {
  margin: 16px;
}
