.component {
  background: var(--mipro-color-white);
  padding-bottom: 16px;
  .title {
    margin-bottom: 8px;
  }
  .buttons {
   padding-right: 16px;
   svg,
   span {
     color: var(--mipro-color-light-blue);
     font-size: 14px;
   }
  }
}
