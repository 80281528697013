@import 'theme/mixins';

.salesPlaysCard {
  margin: 16px;
  color: var(--ion-text-color-dark);
  cursor: pointer;

  .cardHeader {
    border-bottom: 1px solid var(--ion-border-color);
    padding: 8px 16px 0 16px;

    .cardTitle {
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;

      svg {
        font-size: 15px;
        margin-left: 8px;
        margin-bottom: 1px;
      }
    }

    .cardSubtitle {
      font-size: 10px;
      font-weight: 400;
      margin: 4px 0;
    }
  }

  .cardContent {
    margin: 0 16px 4px;
    padding: unset;

    .cardContentTop {
      span {
        color: var(--ion-text-color-dark);
      }
      border-bottom: 1px solid var(--ion-border-color);
      padding: 4px 0;
    }

    .cardContentFooter {
      padding: 4px 0;
      ion-col {
        padding: 0;
      }
      ion-row {
        margin: 2px 0;
      }
      .summaryTextMargin {
        margin-right: 8px;
      }
    }
  }

  .chevronRightIcon {
    position: absolute;
    right: 16px;
    bottom: 8px;
    color: var(--ion-color-primary);
  }

  .positiveText {
    color: var(--ion-color-success);
  }

  .negativeText {
    color: var(--ion-color-danger);
  }

  .pick12Icon {
    top: -2px;
  }
}
