.DocumentCoverTitle {
  margin-left: 16px;
  margin-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.warningMessage {
  margin: 16px;
}

.DocumentCoverPage {
  padding: 16px;
  padding-bottom: 14px;
  border: 1px solid var(--ion-border-color);
  background-color: var(--ion-color-light);

  &:focus {
    outline: none;
  }

  &.noBottomBorder {
    border-bottom: 0;
  }

  &.isLoading {
    background-color: var(--ion-border-color);
  }
}

.coverPanelWrap {
  border: 1px solid var(--ion-border-color);
  border-top: 0;
  background-color: var(--ion-color-light);
}

.documentRow {
  align-items: center;
  padding-bottom: 10px;
  background-color: var(--ion-color-light);
}

.documentCol {
  padding: 0;
  padding-left: 16px;
  padding-right: 16px;
  ion-input {
    text-transform: uppercase !important;
  }
}

.coverImageWrap {
  padding-top: 6px;
  padding-bottom: 0;
}

.documentCoverInput {
  padding: 0;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid var(--ion-color-medium);
  height: 45px;
  align-items: center;
}

.p0 {
  padding: 0;
}

.sectionLoaderWrap {
  display: inline-block;
  padding-right: 16px;
}
