.productHeader {
  padding-top: 0px;
  padding-bottom: 0px;

  // TODO find another approach to extend inner content styles, use parts?
  [class*='itemWrapper'] {
    padding-top: 0px;
  }

  [class*='h3-headline'] {
    font-size: 18px;
  }
}
.wrapper {
  margin-block-start: 16px;
  .item {
    --padding-start: 0px;
    --inner-padding-end: 0px;
    pointer-events: none;
  }
}
.summaryGrid {
  background: var(--mipro-color-white);
  padding: 0;

  &.itemGrid {
    position: relative;
    padding-block-start: 16px;
  }
  .extendedInfo {
    margin: 8px 16px;

    .infoText {
      padding-inline-end: 4px;
    }
  }

  .pointerAll {
    pointer-events: all;
  }

  .quantityPriceRow {
    align-items: center;
    margin: 16px 16px 0;

    .quantity {
      padding-inline-end: 8px;
      display: flex;
      flex-wrap: wrap;
      width: auto;
    }

    ion-item:first-child {
      width: 71px;
    }

    .priceRowBreaker {
      width: 60%;
    }

    .itemSeparator {
      padding-inline-end: 4px;
    }

    .sssText {
      margin-block-start: -2px;
      letter-spacing: -0.3px;
      font-weight: 700;
    }

    .secondaryTextGreen {
      color: var(--mipro-sss-green);
    }

    .secondaryTextBlue {
      color: var(--mipro-sss-blue);
    }

    .totalPrice {
      position: absolute;
      right: 16px;
    }
  }

  .quantityRow {
    align-items: center;

    div {
      width: 45%;
    }
  }

  .error {
    margin: 4px 16px 16px;
  }

  .gpRow {
    align-items: center;
  }

  .quantityInputRow {
    border: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid var(--mipro-color-dark-text);
    background: var(--mipro-color-white);

    &:focus-within {
      border: 1px solid var(--ion-color-primary);
    }

    .quantityInput {
      border-radius: 6px;
      border: 1px solid var(--mipro-color-dark-text);

      &.blankInputWarning {
        border-color: var(--mipro-color-light-red);
      }

      input {
        font-size: 14px;
        line-height: 16px;
        color: var(--mipro-color-dark-text);
        opacity: 1 !important;
        text-align: center;
      }

      &:focus-within {
        border: 1px solid var(--ion-color-primary);
      }
    }
  }

  .calendarIcon {
    font-size: 14px;
    line-height: 16px;
    color: var(--mipro-color-primary);
    padding-inline-start: 4px;
  }

  .attachmentRow {
    margin: 0px 16px 16px 16px;
  }

  .totalPrice {
    user-select: all;
  }

  .priceOverride {
    line-height: 7px;
    width: 100%;

    svg {
      color: var(--mipro-color-light-red);
      font-size: 10px;
      margin-right: 2px;
      margin-bottom: 1px;
    }

    span {
      color: var(--mipro-color-light-red);
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.buttonsRow {
  padding: 0 16px 16px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .uppercase {
    span {
      font-family: var(--ion-font-family-alt);
      text-transform: uppercase;
    }
  }

  .buttonSeparator {
    color: var(--mipro-color-light-gray);
  }

  .removeBtn {
    svg,
    span {
      color: var(--mipro-color-light-red);
    }
    svg {
      width: 10px;
      height: 11px;
    }
  }
}

.textItalic {
  font-style: italic;
  font-weight: normal;
  color: var(--mipro-color-dark-gray);
}

.textBold {
  font-weight: bold;
}

.deleteSlider {
  background: var(--ion-color-secondary);

  ion-button {
    --ion-text-color: var(--ion-color-secondary-contrast);
  }
}

.cancelled {
  background: var(--mipro-color-dark-gray);
}
.shopModalBtn {
  display: flex;

  span {
    margin-right: auto;
  }
}
