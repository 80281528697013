$header-height: 20px;

.slides {
  z-index: 20;
  width: 300px;
  height: 500px;
  background: var(--ion-item-background);
  --swiper-pagination-bullet-size: 0.75rem;
  --bullet-background: var(--ion-color-medium-tint);
  --swiper-pagination-bullet-inactive-opacity: 0.4;
}

.header {
  z-index: 10;
  width: 100%;
  height: $header-height;
  display: flex;
  position: fixed;
  justify-content: center;
  background: var(--ion-background-color);
}

.slide {
  width: 100%;
  height: 100%;
  padding-block-end: 40px;
  padding-block-start: $header-height;
}

.swiperSlide {
  margin-right: 15px !important;
  @media screen and (min-width: 768px) and (min-height: 1024px){
    margin-right: 46px !important;
  }
  @media screen and (min-width: 1024px) and (min-height: 1366px){
    margin-right: 46px !important;
  }
}

.swiperPaginationClick {
  bottom: -30px !important;
  --bullet-background: var(--mipro-color-deselected);
}
