.Notebooks {
  .notebookTabs {
    background: transparent;

    span {
      color: var(--ion-color-light);
    }
  }

  .textWrapper {
    padding: 16px;

    span {
      display: block;
    }
  }
}

.loader {
  margin-top: 16px;
}
.warningMessage {
  margin: 16px;
}
