.noteCard {
  --padding-start: 0px;
  --padding-end: 0px;
  padding-top: 8px;
  .noteItem {
    padding: 8px 0;
  }
  .updateDate {
    color: var(--mipro-color-medium-gray);
    font-weight: 500;
  }
  .spacing {
    margin-inline-start: 4px;
  }
  .userId {
    color: var(--mipro-color-primary);
    font-weight: 400;
  }

  .divEllipsis {
    display: table;
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;

    span {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .attachment {
    background-color: var(--mipro-color-background);
    min-width: 40px;
    height: 40px;
  }

  .attachmentIcon {
    color: var(--mipro-color-light-red) !important;
    font-size: 15px !important;
  }
  .text {
    font-weight: 500;
    font-size: 15px;
  }
}
