@import 'theme/mixins';

.modal {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --background: var(--ion-background-color);
}

.header {
  // TODO avoid this code duplication in services modals (full screen modals)
  padding: 16px;
  position: relative;
  border-bottom: 1px solid var(--ion-border-color);
  margin-top: var(--ion-safe-area-top);
}

.recipientsTitle {
  display: block;
  margin-bottom: 8px;
}

.recipientsWrapper {
  margin-left: 0 !important;
  margin-right: 0 !important;
  position: relative;
  margin-bottom: 24px;

  ion-row {
    margin: 0 !important;
  }

  ion-col {
    padding: 0 !important;
  }

  .addRecipientButton {
    margin-top: 15px;
    width: 50%;
    min-width: 160px;
  }

  .deleteRecipientButton {
    position: absolute;
    right: 6px;
    z-index: 2;
    top: 25px;
  }

  .recipientsInput {
    width: 94%;
    padding-right: 5%;
    margin-top: 15px;
  }

  .recipientButtonInverted,
  .recipientButton {
    height: 32px;
    margin: 0 8px 8px 0;
    padding: 0 8px 0 16px;
    border-radius: var(--ion-avatar-size);
    align-items: center;
    background: var(--ion-item-background);

    ion-button {
      position: relative;
      margin-inline-start: 8px;
      @include increaseTouchTargetSize();

      svg {
        color: var(--ion-color-medium);
      }
    }
  }

  .recipientButtonInverted {
    background: var(--ion-background-color);
  }
}

.recipientsWrapperInverted {
  margin-top: 16px;
  margin-bottom: 0;
}

.searchWrapper {
  margin-top: 16px;
}

.searchbar {
  --searchbar-border-color: var(--ion-text-color);
}

.contactsList {
  margin: 0 -16px;
}

.contactCard {
  .contactRow {
    flex-direction: column;
  }

  &:first-child {
    border-top: 1px solid var(--ion-border-color);
  }

  .withDescription {
    margin-bottom: 4px;
  }

  &.selected {
    --action-row-background: rgba(var(--ion-color-success-rgb), 0.05);

    ion-button {
      svg {
        color: var(--ion-color-success);
      }
    }
  }
}

.subjectInput {
  margin-bottom: 32px;
}

.buttonsWrapper {
  padding: 16px;
  border-top: 1px solid var(--ion-border-color);

  ion-button {
    flex: 1;
  }

  ion-button:first-child {
    margin-inline-end: 16px;
  }
}
