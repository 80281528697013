.title {
  font-stretch: condensed;
  text-transform: uppercase !important;
  font-family: var(--ion-font-family-alt);
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  color: #000000;
}

.noFeatures {
  min-height: 50px;
}

.announcementCard {
  width: 100%;
  margin: 4px;
  border-radius: 8px;
  box-shadow: none;
}
.seeMoreButton {
  margin: auto;
  padding-top: 10px;
}
.wrapper {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 24px;
}
.slidesRow {
  margin-right: -16px;
  margin-bottom: -16px;
}
.slides {
    $minCardSpacing: 20px;
    $minCardWidth: 280px;
    $maxCardWidth: $minCardWidth * 1.55;
    width: calc(70vw + 20px);
    min-width: $minCardWidth;
    padding: 0 $minCardSpacing * 0.5;
    margin: 0 -5px;
    font-size: unset;
    overflow: initial;
    max-width: $maxCardWidth;
}

.divider{
  align-items: center;
  padding: 16px 8px 8px;
}
.divider span {
  font-size: 16px;
  font-weight: 700;
}
.divider::after,
.divider::before {
  content: '';
  border: 1px solid var(--ion-border-color);
  flex: 1;
}

.divider:not(:empty)::before {
  margin-right: 8px;
}

.divider:not(:empty)::after {
  margin-left: 8px;
}

