@import 'theme/mixins';


.contentRow {
  padding: 10px 16px;
  background: var(--ion-item-background);
  border-bottom: 0.5px solid var(--ion-border-color);

  .label {
    color: #696969;
  }

  .valueCol {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .valueWrapper {
      display: flex;
      .button {
        position: relative;
        @include increaseTouchTargetSize(50%, 0, 50%);
        .icon {
          font-size: 11px;
          margin-left: 8px;
          color: var(--mipro-color-primary);
        }
      }
    }
    .value {
      font-family: var(--ion-font-family);
      font-size: 16px;
      font-weight: 500;
      padding-top: 2px;
    }
  }

  .changeCol {
    display: flex;
    align-items: center;

    .positive span {
      color: var(--color-positive-yoy);
    }
    .negative span {
      color: var(--color-negative-yoy);
    }

    .label {
      font-size: 8px;
      margin-inline-start: 4px;
      margin-top: 4px;
      color: var(--ion-text-color-dark);
    }
  }
}

// skeleton loaders
@mixin valueLoader($width: 25%) {
  height: 17.5px;
  margin: 2px 0 0 0;
  width: $width;
}

ion-skeleton-text {
  // value column
  &.label {
    width: 50%;
    height: 10.5px;
    margin: 2px 0 3px 0;
  }
  &.currencyValue {
    @include valueLoader(70%);
  }
  &.percentageValue {
    @include valueLoader(20%);
  }
  &.stringValue {
    @include valueLoader(30%);
  }
  // change column
  &.changeSign {
    width: 8px;
    height: 6px;
    margin-right: 5px;
  }
  &.changePercent {
    width: 50px;
    height: 15px;
  }
  &.changeLabel {
    width: 12px;
    height: 8px;
    margin-top: 10px;
    margin-left: 4px;
  }
  // other
  &.timestampLoader {
    width: 30%;
  }
}

.newLineBreak{
  white-space: pre;
}
