.content {
  height: 100%;
  overflow-y: auto;
}

.accordion {
  margin-top: 16px;
}

.interchangeBtn {
  width: 100%;
  margin-top: 16px;
  --background: var(--mipro-color-primary);
}

.list {
  padding: 0 16px;
}

.message {
  margin: 0;
  padding: 16px;
}
