.cartPage {
  .btnWrapper {
    margin-bottom: 0px;
    align-self: center;
  }

  .addItemButton {
    span {
      text-transform: uppercase;
      font-family: var(--ion-font-family-alt);
    }
  }

  .contentHeader {
    padding: 16px 0 8px;
  }

  .headerRow {
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
  }

  .summaryGrid {
    background: var(--mipro-color-white);
    padding: 0;

    .summaryTitle {
      padding: 16px;
      text-transform: uppercase;
    }

    .totalRow {
      display: flex;
      align-items: center;
      padding: 0 16px 16px 16px;
    }

    .estimatedCol {
      display: flex;
      align-items: flex-end;
      padding: 0px;
    }

    .totalPrice {
      user-select: all;
    }

    .amtCol {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0px;
    }

    .amtOverline {
      color: var(--mipro-color-dark-gray);
      text-align: right;
    }
  }

  .warningMessage {
    margin: 16px;
    --wrapper-margin: 0px;
    padding-block-end: 16px;
    .searchCustBtn {
      width: 100%;
      margin-block-start: 16px;
      --background: var(--mipro-color-primary);
    }
  }

  .loader,
  .warningMessage {
    padding: 16px;
  }
}

.clearText {
  svg {
    min-width: auto !important;
  }
  svg, span {
    color: var(--mipro-color-light-red) !important;
  }
}

.clearOcn {
  .text {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .btnWrapper {
    padding-bottom: 16px;
    .btn {
      width: 100%;
    }
  }
}

.spinner {
  position: fixed;
}
