.pick12Contest {
  ion-content {
    --background: var(--ion-color-primary-contrast);
    --padding-top: var(--ion-safe-area-top) !important;
    .imgWrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 48px;
      padding-bottom: 32px;
    }
    .genericRules {
      padding-left: 32px;
      padding-right: 48px;
      li {
          margin-top: 32px;
          font-family: var(--ion-font-family);
          font-size: 14px;
          line-height: 21px;
          color: var(--ion-text-color-dark);
          span {
            margin-left: 12px;
            display: block;
          }
        }
    }
  }
}

.pick12ContestItems {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .icon {
      font-size: 14px;
      color: var(--ion-text-color-dark);
      width: 14px;
      margin-top: 15px;
    }
    .iconRed {
      color: var(--color-negative-yoy);
    }
    .iconGreen {
      color: var(--color-positive-yoy);
    }
    ion-label {
      white-space: normal !important;
      margin-left: 10px;
      font-family: var(--ion-font-family);
      h5 {
        font-size: 14px;
        line-height: 24px;
        color: var(--ion-text-color-dark);
      }
      p {
        font-size: 12px;
        line-height: 21px;
        color: var(--ion-text-color-light);
      }
    }
}

