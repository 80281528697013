@import 'components/Text/Text.module';

.feedbackContainer {
  padding: 16px 16px 0;
  .feedbackWrapper {
    &:first-child {
      margin-bottom: 16px;
    }
    span {
      &:first-child {
        text-transform: capitalize;
        margin-right: 8px;
      }
    }
  }
}

.page {
  .content {
    .input {
      margin: 16px;

      [data-testid='label'] {
        @include mipro-h3-headline();
        text-transform: capitalize;
      }
    }
  }
}

.feedbackTextArea {
  padding: 0 16px;
  margin: 24px 0 20px 0;
}

.attachmentWrapper {
  margin: 24px 16px 0;

  .attachmentsTitle {
    text-transform: capitalize;
  }

  &.isViewOnly {
    opacity: 0.8;
  }

  ion-row {
    margin-top: 3px;
  }
}

.footer {
  background: var(--ion-item-background);

  ion-toolbar {
    // remove extra safe area whitespace
    --ion-safe-area-bottom: 0;
    --padding-top: 16px;
    --padding-bottom: 16px;
    --padding-start: 16px;
    --padding-end: 16px;
    --background: transparent;
  }

  ion-button {
    flex: 1;
  }

  .saveButton {
    margin-inline-end: 16px;
  }
}

.toolbar {
  --padding-top: 0;
  --padding-bottom: 0;
}
