.Territories {
  .input {
    margin: 0 16px 16px;
    --searchbar-border-color: var(--ion-text-color);
  }
}

.content {
  --padding-top: 16px;
  --padding-bottom: 16px;
}

.title {
  text-transform: uppercase;
}

.item {
  --min-height: 75px;

  .button {
    width: 30px;
    text-decoration: none;

    span {
      text-transform: uppercase;
    }
  }

  ion-button::part(native):focus {
    text-decoration: none;
  }

  &.selectedItem {
    --action-row-background: rgba(var(--ion-color-success-rgb), 0.05);

    span {
      font-weight: 600;
    }

    ion-button {
      svg {
        color: var(--ion-color-success) !important;
      }
    }
  }

  &:first-child {
    border-top: 1px solid var(--ion-border-color);
  }
}

.warning {
  padding: 0px 16px;
}
