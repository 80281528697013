.error {
  .logo {
    width: 100%;
    margin-block-start: 32px;
    text-align: center;
    img {
      max-width: 160px;
      width: 160px;
    }
    &.servicesLogo {
      height: 80px;
      top: -60px;

      img {
        height: 80px;
      }
    }
  }
  .warningMessage {
    padding-inline: 16px;
    margin-block-start: 24px;
    .errorMsg {
      color: var(--mipro-color-dark-gray);
    }
    ion-col:first-child {
      padding-block: 0 2.75rem;
      padding-inline: 1.5rem;
    }
    .column {
      padding: 0;
      .row {
        display: flex;
        align-items: center;
        padding-inline: 16px;
        justify-content: center;
        justify-items: center;
      }
    }

    .gapStart {
      margin-block-start: 0.5rem;
    }
    .gapEnd {
      margin-block-end: 0.5rem;
    }
    .gap {
      margin-block: 0.5rem;
    }
    .phoneRow {
      display: flex;
      flex-direction: column;
      padding-block-end: 16px;
      .phoneNumber {
        svg {
          height: 14px;
          width: 16px;
        }
      }
      .supportId {
        color: var(--mipro-color-dark-gray);
      }
    }
  }

  .footer {
    ion-toolbar {
      --padding-top: 16px;
      --padding-bottom: 16px;
      --padding-start: 16px;
      --padding-end: 16px;
      --background: transparent;
      --border-width: 0;
    }
    ion-button {
      flex: 1;
    }
  }
}
