@import 'theme/mixins';
.listWrapper {
  padding-bottom: 16px;

  .titleLabel {
    --background: var(--ion-item-background);
  }

  .emptyMessage {
    padding: 16px 0;
  }

  .item {
    .title {
      width: auto;
      span {
        text-transform: uppercase;
      }
    }

    .prefix {
      padding-right: 16px;
    }

    .subTitle span {
      font-weight: 400;
      color: var(--mipro-color-dark-text);
    }

    .secondary {
      @include textEllipsis(2);

      span {
        font-size: 12px;
        color: var(--ion-text-color-light);
      }
    }

    .disabledItem {
      --action-row-background: var(--mipro-color-disabled) !important;
    }

    .options {
      width: 90px;
      background: var(--mipro-color-light-red);

      span,
      svg {
        color: var(--mipro-color-white);
      }

      span {
        margin-inline-start: 8px;
        font-weight: 500;
      }
    }
  }
}

.searchButton {
  width: 100%;
  margin-top: 16px;
  --background: var(--mipro-color-primary);
}

.deleteModal {
  .deleteTitle {
    width: 60%;
    font-size: 24px;
    line-height: 110%;
  }

  .deleteContent {
    --padding-start: 27px;
    --padding-end: 27px;
  }

  .msgRow {
    margin-top: 16px;
  }

  .deleteMsg {
    line-height: 21px;
    font-weight: 500;
    --text-default-color: var(--mipro-color-black);
  }

  .deleteButton {
    margin-top: 16px;
    width: 100%;
    --border-radius: 6px;
  }

  .primaryButton {
    --background: var(--mipro-color-primary);
  }

  .deleteOption {
    --background: var(--mipro-color-light-red);
  }
}

.unfinishedBadge {
  background: var(--mipro-color-dark);
}
