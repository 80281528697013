.content {
  --background: var(--ion-item-background);
}

.flexWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.wrapper {
  flex: 1;
  display: flex;
  align-items: center;
}

.headerLogin {
  border-bottom: 0 transparent;

  ion-toolbar:empty {
    display: none;
  }
}

.headerError {
  ion-toolbar {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;

    .errorAlert {
      width: 100%;
      min-height: 67px;
      padding: 8px 16px;
      background: var(--ion-color-danger);
      color: var(--ion-text-color-reversed);
    }

    .errorAlertIcon {
      font-size: 15px;
      margin-inline-end: 8px;
      margin-block-start: 3px;
    }

    p {
      flex: 1;
      margin: 0;
    }

    .errorAlertText {
      line-height: 22px;
    }

    .alertCallLink {
      margin: 0 4px;
      vertical-align: text-bottom;
    }

    .errorAlertText,
    .alertCallLink {
      color: var(--ion-text-color-reversed);
    }
  }
}

.form {
  flex: 1;
  position: relative;

  ion-list {
    padding: 0 16px;
  }
}

.logo {
  width: 100%;
  position: absolute;
  text-align: center;
  top: -80px;

  img {
    max-width: 160px;
    width: 160px;
  }

  &.servicesLogo {
    height: 80px;
    top: -60px;

    img {
      height: 80px;
    }
  }
}

.title {
  display: block;
  margin: 20px 0 0;
  text-align: center;
  text-transform: uppercase;
  color: var(--ion-text-color);
  font-family: var(--ion-font-family);
  font-weight: 400;
  font-stretch: normal;
}

.isLockedAfterBackgrounded {
  font-weight: 600;
  color: var(--color-customerVisit);
  text-align: center;
  white-space: pre-wrap;
  display: block;
  margin-block-start: 16px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.lockButton {
  margin: 40px 16px 0;
  .lockButtonIcon {
    height: 42px;
    width: 42px;
    padding: 4px;
  }
}

.username,
.password,
.modelSelect {
  flex: 1;

  ion-label {
    margin-top: 24px;
  }
}

.credentialsHelp {
  width: 100%;
  text-align: center;
  margin: 24px 16px 0;
}

.signinButton {
  width: 100%;
  text-align: center;
  margin: 40px 16px 0;
}

.note {
  margin-top: 24px;
  justify-content: center;
  align-items: center;
}

.callLink {
  margin: 0 4px;
}

.biometricIcon {
  height: 42px;
  width: 42px;
  padding: 4px;
}

.biometricIconError {
  border: 1px solid var(--ion-color-danger);
  border-left: none;
  margin-bottom: 24px;
  padding: 4px;
}

.hiddenButton {
  visibility: hidden;
  position: absolute;
  position: -999px;
}
