.button {
  --color: var(--ion-text-color);
  --color-focused: var(--ion-text-color);
  --color-activated: var(--ion-text-color);
  --color-hover: var(--ion-text-color);
  min-height: unset;

  .icon,
  .text {
    color: var(--ion-text-color);
  }

  &.disabled {
    opacity: 1;
    --border-color: var(--mipro-color-disabled-shade);

    .icon,
    .text {
      color: var(--mipro-color-disabled-shade);
    }
  }
}

.clear {
  @extend .button;
  margin: 0;
  height: auto;
  outline: none;
  --background: transparent;
  --background-focused: transparent;
  --background-focused-opacity: 1;
  --background-activated: transparent;
  --background-activated-opacity: 1;
  --background-hover: transparent;
  --background-hover-opacity: 1;
  --border-radius: 0;
  --border-style: none;
  --box-shadow: none;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --ripple-color: transparent;
  --opacity: 1;

  &::part(native):focus {
    outline: none;
  }

  &::part(native)::after {
    background: transparent;
  }

  .text {
    text-transform: none;
  }
}

.leftIconWithText {
  margin-inline-end: 5px;
}

.rightIconWithText {
  margin-inline-start: 5px;
}

.underlined {
  @extend .clear;
  text-decoration: underline;
}

.icon-action {
  @extend .clear;

  &:focus-within,
  &:hover,
  &:active {
    .icon,
    .text {
      color: var(--ion-color-primary);
    }
  }

  .wrapper {
    ion-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      --ion-grid-column-padding: 0;
    }

    .icon {
      margin: 0;
    }

    .leftIconWithText {
      margin-bottom: 4px;
    }

    .rightIconWithText {
      margin-top: 4px;
    }
  }
}

.action {
  @extend .clear;
  border-radius: 6px;
  --border-radius: 6px;
  --padding-top: 12px;
  --padding-bottom: 12px;

  $button-action-gradient: linear-gradient(
    to top,
    rgba(12, 108, 212, 0),
    rgba(35, 41, 50, 0.15)
  );
  $primary-color-gradient: linear-gradient(
    to bottom,
    var(--ion-color-primary),
    var(--ion-color-primary)
  );
  $button-shadow-gradient: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0.15)
  );

  --background: #{$button-action-gradient}, #{$primary-color-gradient};
  // TODO needed because Header is overwritting colors, should fix in there
  background: var(--background);
  --background-focused: #{$button-action-gradient}, #{$primary-color-gradient};
  --background-activated: #{$button-shadow-gradient}, #{$button-action-gradient},
    #{$primary-color-gradient};
  --background-hover: #{$button-shadow-gradient}, #{$button-action-gradient},
    #{$primary-color-gradient};
  --color: var(--ion-text-color-reversed);
  --color-focused: var(--ion-text-color-reversed);
  --color-activated: var(--ion-text-color-reversed);
  --color-hover: var(--ion-text-color-reversed);

  &:focus-within {
    box-shadow: 0 2px 12px 0 var(--ion-color-primary),
      0 0 4px 0 var(--ion-color-primary);
  }

  .text {
    font-size: 14px;
    font-weight: 500;
  }

  .icon,
  .text {
    color: var(--ion-text-color-reversed);
  }

  &.disabled {
    --background: var(--mipro-color-disabled);
  }
}

.mipro-action {
  @extend .action;
  --background: var(--mipro-color-primary-tint);
  background: var(--background);

  &.disabled {
    --background: var(--mipro-color-disabled);
  }
}

.textPrimaryColor {
  @extend .clear;
  --color: var(--ion-color-primary);
  --color-focused: var(--ion-color-primary-shade);
  --color-activated: var(--ion-color-primary-shade);
  --color-hover: var(--ion-color-primary-shade);

  &:focus-within,
  &:hover,
  &:active {
    .icon,
    .text {
      color: var(--ion-color-primary-shade);
    }
  }

  .icon,
  .text {
    color: var(--ion-color-primary);
  }
}

.mipro-text-button {
  @extend .textPrimaryColor;

  .icon {
    font-size: 16px;
    min-width: 40px;
  }
  .text {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-default-color);
    white-space: break-spaces;
    text-align: left;
    display: block;
  }
}

.link {
  @extend .textPrimaryColor;

  &:focus-within,
  &:hover,
  &:active {
    text-decoration: underline 1px var(--ion-color-primary);
  }
}

.secondary {
  @extend .textPrimaryColor;
  border-radius: 6px;
  --border-radius: 6px;
  --padding-top: 12px;
  --padding-bottom: 12px;

  --background: var(--ion-item-background);
  --background-focused: var(--ion-item-background);
  --background-activated: var(--ion-item-background);
  --background-hover: var(--ion-item-background);
  --border-style: solid;
  --border-width: 1px;
  --border-color: var(--ion-color-primary);

  &:focus-within {
    box-shadow: 0 2px 12px 0 var(--ion-color-primary),
      0 0 4px 0 var(--ion-color-primary);
  }

  &::part(native):hover {
    border-color: var(--ion-color-primary-shade);
  }

  .text {
    font-size: 14px;
    font-weight: 500;
  }
}

.filter {
  --padding-top: 8px;
  --padding-bottom: 8px;
  --padding-start: 16px;
  --padding-end: 16px;
  --border-style: none;
  --border-radius: var(--ion-avatar-size);
  border-radius: var(--ion-avatar-size);

  .text {
    font-size: 15px;
  }
}

.mipro-owner-link {
  @extend .link;
  padding-top: 8px;

  .icon {
    color: var(--mipro-color-medium-gray);
    width: 15px;
    height: 14.5px;
  }

  .text {
    font-weight: 700;
    font-size: 14px;
    line-height: 70%;
    padding-top: 4px;
    color: var(--mipro-color-primary);
    font-family: var(--ion-font-family-alt);
  }
}

.mipro-danger-default {
  @extend .clear;
  --border-radius: 6px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  --border-style: solid;
  --border-width: 1px;
  --border-color: var(--mipro-color-light-red);
  .text {
    color: var(--mipro-color-light-red);
  }
}
