.modal {
  --padding-top: 0;
  --padding-bottom: 16px;
}

.filterRow {
  --min-height: unset;
  --padding-top: 13px;
  --padding-bottom: 13px;
  --padding-start: 0;
  --padding-end: 0;

  &:last-child {
    border: none;
  }

  .filterText span {
    text-transform: capitalize;
    white-space: break-spaces;
    text-align: start;
  }

  .filterIcon {
    width: 20px;
    font-size: 18px;
    margin-inline-end: 16px;
    color: var(--ion-text-color-light);
  }
}
