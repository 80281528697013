@import 'theme/mixins';
.modal {
  --backdrop-opacity: 0.7;
  --border-radius: 16px;
  --background: var(--ion-item-background);

  &::part(handle) {
    top: 18px;
    width: 80px;
    background: var(--color-modal-handle);
  }

  .title {
    flex: 1;
    text-align: center;
    white-space: pre-wrap;
  }

  .headerRow {
    padding: 40px 16px 0;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
      width: 50px;

      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  .content {
    --background: var(--ion-item-background);
    --padding-start: 16px;
    --padding-end: 16px;
    --padding-top: 0;
    --padding-bottom: 16px;
  }

  .closeBtn {
    position: relative;
    @include increaseTouchTargetSize(50%, 0, 50%);
  }
}
