.loader {
  margin-block-start: var(--wrapper-margin);
}

.contactContent {
  margin-top: 16px;
}

.card {
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-inline: 0px;
  .name {
    margin-inline-start: 16px;
  }
  svg {
    font-size: 14px;
  }
  .roleName {
    padding-top: 6px;
    padding-left: 4px;
  }
  .actionBox {
    display: flex;
    justify-content: center;
    background-color: var(--ion-background-color);
    border: 1px solid var(--ion-color-light);
    .leftButton {
      padding: 8px 0 4px 0;
      width: 100%;
    }
  }
  ion-col {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

}

.footer {
  background: var(--ion-item-background);

  ion-toolbar {
    --padding-top: var(--wrapper-margin);
    --padding-bottom: var(--wrapper-margin);
    --padding-start: var(--wrapper-margin);
    --padding-end: var(--wrapper-margin);
    --background: transparent;
  }

  ion-button {
    flex: 1;
  }
}

.button {
  width: 100%;
}

