.modal {
  --height: 100px;
  align-items: flex-end !important;
  --backdrop-opacity: 1 !important;
  --background: var(--ion-item-background) !important;
  --border-radius: 0;

  &::part(handle) {
   display: none;
  }

  & > div {
    transition: height 300ms;
  }

  .header {
    .toolbar {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      --padding-top: 0;
      --padding-bottom: 0;
      --padding-start: 0;
      --padding-end: 0;
      --border-width: 0;
      --border-style: none;
      --background: transparent;
      --ion-color-base: transparent;
    }
  }
}

.footer {
  padding-bottom: var(--ion-safe-area-bottom);
}

.wrapper {
  --overflow: hidden;
  --padding-top: 24px;
  --padding-bottom: 24px;
  --padding-start: 16px;
  --padding-end: 16px;
  --background: var(--ion-item-background);
}

.fullHeightModal {
  --height: 100%;
  .header {
    margin-top: var(--ion-safe-area-top);
  }
  .wrapper {
    --overflow: auto;
  }
}
