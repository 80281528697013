@import 'theme/mixins';

.card {
  margin: 0;
  --background: var(--ion-item-background);
  --background-activated: transparent;
  --background-activated-opacity: 1;
  --background-focused: transparent;
  --background-focused-opacity: 1;
  --background-hover: transparent;
  --background-hover-opacity: 1;
  --ripple-color: transparent;
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 16px;
  --padding-end: 16px;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  @include cardShadow;
}

.avatar {
  ion-img {
    @include cardAvatar(46px);

    &::part(image) {
      border-radius: 50%;
    }
  }
}

.container {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  grid-template-columns: 46px auto 50px;

  .text {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .name {
    display: flex;
    margin-block-start: 4px;
    color: var(--ion-color-medium);
  }

  .aging {
    margin-inline-start: auto;
    color: var(--ion-color-medium);
  }
}
