@import 'components/Documents/DocumentInput/DocumentInput.module.scss';

.signatureImage {
  height: 200px;
}

.sigPadContainer {
  background-color: rgb(238, 235, 235);
  padding: 10px;
}

.sigCanvas {
  width: 100%;
  height: 350px;
}

.extraPadding {
  padding-top: 10px;
}

.buttonRow {
  justify-content: center;
  margin-bottom: 16px;

  .saveButton {
    margin-left: 2px;
    margin-right: 2px;

    span {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.signatureContent {
  margin-top: 16px;
}

.discardButton {
  margin-top: 16px;
  width: 100%;
  --border-radius: 6px;
}
