.container {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  ion-backdrop {
    opacity: 0.85;
    pointer-events: none;
    background: var(--mipro-color-background);
  }
}

.spinnerClass {
  --color: var(--mipro-color-primary);
}

.updateLoaderText {
  color: var(--mipro-color-primary);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 10px 32px;
  z-index: 10;
  background-color: var(--mipro-color-white);
}
