.contactInfo {
  align-self: flex-start;
}

.inviteWrapper {
  display: flex;
  flex-direction: column;

  .inviteText {
    color: var(--ion-color-primary);
  }
}
