@import 'theme/mixins';

.wrapper {
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: auto;
  position: relative;

  .tabs {
    border-radius: 0px;
    padding: 16px;

    ion-segment-button {
      min-width: unset;
      text-transform: capitalize;
      white-space: nowrap;
      margin: 0 3px;
    }

    ion-segment-button:first-of-type {
      margin-left: 0px;
    }

    span {
      font-family: var(--ion-font-family);
      font-weight: 500;
      font-size: 12px;
      color: var(--ion-text-color-dark);
    }

    .selectedTab {
      border: none;
      --indicator-color: var(--mipro-color-dark-blue);

      span {
        font-weight: 500;
        color: var(--ion-color-light);
      }
    }
  }

  .endShadow {
    @include endShadow();
  }
}
