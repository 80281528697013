@use 'sass:math';

@mixin cardShadow {
  border-radius: 1.5px;
  box-shadow: 0 2px 2px 0 rgba(var(--ion-color-light-contrast-rgb), 0.24),
    0 0 2px 0 rgba(var(--ion-color-light-contrast-rgb), 0.12);
}

@mixin cardAvatar($size) {
  width: $size;
  height: $size;
  min-width: $size;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background-color: var(--ion-background-color);
}

// Set $showTargetArea to true to visually show touch target size
@mixin increaseTouchTargetSize(
  $percentIncrease: 50%,
  $widthIncrease: $percentIncrease,
  $heightIncrease: $percentIncrease,
  $showTargetArea: false
) {
  cursor: pointer;
  &::after {
    top: 50%;
    left: 50%;
    content: '';
    display: block;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    // DOC: hack to force css max function https://github.com/sass/sass/issues/2849#issuecomment-820538086
    width: Max(100% + $widthIncrease, 25px);
    height: Max(100% + $heightIncrease, 25px);
    border-radius: math.min(math.div($percentIncrease, 2), 30%);

    @if $showTargetArea {
      border: dashed 1px red;
    }
  }
}

@mixin horizontalScroller($marginTop: 8px, $marginBottom: 8px) {
  display: grid;
  grid-gap: 8px;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  overflow-x: auto;
  padding-block-end: 8px;
  padding-block-start: 8px;
  padding-inline-end: var(--wrapper-margin);
  padding-inline-start: var(--wrapper-margin);
  margin-block-end: $marginBottom;
  margin-block-start: $marginTop;
  margin-inline-start: var(--wrapper-margin--);
  margin-inline-end: var(--wrapper-margin--);
}

@mixin stickyScroller() {
  position: sticky;
  top: 0;
  z-index: 20;
  margin-block-end: 10px;
  padding-inline-end: var(--wrapper-margin);
  padding-inline-start: var(--wrapper-margin);
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--ion-border-color);
  background-color: var(--ion-item-background);
}

@mixin leaderBoardShape($borderWidth, $indentHeight) {
  // sets the width of the indent
  $indentWidth: 33%;
  $indentRemainder: calc((100% - $indentWidth) / 2 - $indentHeight);

  clip-path: polygon(
    calc($indentRemainder - $borderWidth) 0,
    calc($indentRemainder + $indentHeight - $borderWidth) $indentHeight,
    calc(100% - $indentRemainder - $indentHeight + $borderWidth) $indentHeight,
    calc(100% - $indentRemainder + $borderWidth) 0,
    100% 0,
    100% 100%,
    0 100%,
    0 0
  );
}

@mixin endShadow() {
  width: 40px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

@mixin textEllipsis($clamp: 0) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @if $clamp > 0 {
    display: -webkit-box;
    -webkit-line-clamp: $clamp;
    -webkit-box-orient: vertical;
    // TUDU this should be pre-wrap?
    white-space: pre-line;
    word-break: break-all;
  }
}
