.mainHeadline {
  margin-top: 12px;
  margin-bottom: 4px;

  .row {
    align-items: baseline;
    justify-content: space-between;
  }

  .badgeRow {
    margin-inline-start: auto;
    .crossSellBadge {
      color: #7308AF;
    }
    .decliningBadge {
      color: #F80E0E;
    }
    .overdueBadge {
      color: #E5BA2F;
    }
    .upSellBadge {
      color: #009633;
    }
  }
}

.opportunityType {
  color: var(--ion-color-light);
  &.yellow {
    color: var(--ion-color-warning-shade) !important;
  }

  &.green {
    color: var(--ion-color-success) !important;
  }

  &.red {
    color: var(--ion-color-warning) !important;
  }
  &.white {
    color: white !important;
  }
  .opportunityTypeBadge {
    vertical-align: middle;
    margin-right: 4px;

    svg {
      font-size: 16px;
    }
  }
}

.badge {
  padding: 4px 8px;
  border-radius: 26px;
  margin-inline-start: 8px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.badgeStatus {
  background-color: var(--ion-color-primary);

  &.accepted {
    background-color: var(--ion-color-warning-tint);
  }

  &.rejected,
  &.lost {
    background-color: var(--ion-color-danger);
  }

  &.won {
    background-color: var(--ion-color-success);
  }

  &.archived {
    background-color: var(--ion-color-medium);
  }
}

.badgeText {
  color: var(--ion-color-warning-shade) !important;
}
