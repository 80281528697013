@import 'theme/mixins';

.headerWrapper {
  padding: 24px 16px 16px;
}

.header {
  display: block;
  margin-bottom: 8px;
}

.closeButton {
  @include increaseTouchTargetSize();
  position: absolute;
  right: 16px;
  top: 16px;
}

.title {
  display: block;
  text-transform: uppercase;
  margin-inline-end: 20px;
}

.withTitleLine {
  margin-bottom: 8px;
}

.withTruncatedTitle {
  max-width: 65%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
