@import 'theme/mixins';

.detailsTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .mainAddressWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
}

.buttonTextWrapper {
  display: flex;
  text-align: start;
  white-space: normal;

  .buttonText {
    white-space: pre-line;
    word-break: break-word;
    color: var(--ion-color-primary);
  }
}

.link {
  position: relative;
  @include increaseTouchTargetSize(50%, 0, 0);
}
