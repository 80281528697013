.reportCell {
  display: flex;
  align-items: center;

  &.positive {
    svg,
    span {
      color: var(--color-positive) !important;
    }
  }
  &.negative {
    svg,
    span {
      color: var(--color-negative) !important;
    }
  }

  svg {
    font-size: 11px;
    margin-inline-end: 4px;
  }
}
