.pick12LeaderBoardContent {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 0;
  --padding-end: 0;
  --background: var(--ion-background-color);

  &.backgroundTransparent {
    --background: transparent;
  }
}

.container {
  position: fixed;
  height: 500px;
  left: 0;
  top: 0;
  right: 0;
  $top: 235px;

  .leaderBackground {
    --opacity: 0;
    --visibility: visible;
    position: fixed;
    height: $top;
    left: 0;
    right: 0;
    top: 50px;
    z-index: -1;
    background: linear-gradient(180deg, #333333 0%, rgba(51, 51, 51, 0.4) 98%),
      #202020;
    background-image: url('./images/pick12Leaderboard.svg');
    background-repeat: no-repeat;
    background-position: right -160px bottom -145px;
    opacity: var(--opacity);
    visibility: var(--visibility);
    transition: opacity .2s ease-in;

    &.opacity {
      --opacity: 1;
    }

    &.visibility {
      --visibility: hidden;
    }
  }
}

.leaderboardItem {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 16px 0;
}

.leaderboardLabel {
  font-weight: 700;
  font-family: var(--ion-font-family-alt);
  font-size: 25px;
  color: var(--ion-text-color-reversed);
  text-transform: uppercase;
}

.pick12Label {
  color: var(--ion-color-secondary);
}

.viewYear {
  border: 1px solid var(--ion-color-light);
  border-radius: 6px;
  padding: 8px 12px;

  span {
    font-family: var(--ion-font-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 115%;
    color: var(--ion-text-color-reversed) !important;
  }

  .trophyIcon {
    width: 16px;
    height: 16px;
    color: var(--ion-color-light);
  }
}

.leaderboardContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 16px 0 16px;
}

.leaderboartItem {
  margin: 15px 8px;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 16px 8px 16px 8px;
  font-weight: 600;
  background: var(--ion-background-color);

  .leaderboardText {
    font-family: var(--ion-font-family);
    font-weight: 600;
  }
}

.divider::after,
.divider::before {
  content: '';
  border: 1px solid var(--ion-border-color);
  flex: 1;
}

.toggle {
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --inner-border-width: 0;
  --padding-end: 16px;
  --padding-bottom: 16px;

  // Hiding the toggle here, we can remove this if we need to bring it back
  display: none;

  ion-grid {
    padding: 0;
    flex: 1 1 50%;
  }

  ion-col {
    padding: 0;
  }

  ion-toggle {
    --handle-background: #fff url('./images/truck-pickup-gray.png') no-repeat
      center/80%;
    --handle-background-checked: #fff url('./images/truck-pickup-red.png')
      no-repeat center/80%;
  }

  .infoIcon {
    height: 12px;
    margin-top: 2px;
    margin-inline-start: 4px;
    color: var(--ion-color-primary);
  }

  .truckPickupIconWrapper {
    margin-top: -4px;
    margin-right: 4px;

    &::before {
      color: var(--ion-text-color-light);
      font-size: 12px;
      content: '"';
    }

    &::after {
      color: var(--ion-text-color-light);
      font-size: 12px;
      content: '" =';
    }

    .truckPickupIcon {
      color: var(--ion-text-color-light);
      height: 12px;
      width: 12px;
      margin-top: 2px;
    }
  }
}

.leaderboardTable {
  position: relative;
  z-index: 1000;
}

.tableBackground {
  background-color: var(--ion-background-color);
}

ion-grid {
  --ion-grid-columns: 100;
}

.tableGrid {
  padding: 0;

  .leaderboardTableHeader {
    display: flex;
    justify-content: center;
    background-color: #2b2b2b;
    position: sticky;
    top: -18px;
    z-index: 10000;

    .tableHeaderCol {
      padding-left: 0;
      padding-right: 0;
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }
}

.leaderHeaderText {
  font-family: var(--ion-font-family);
  font-weight: 500;
  color: #ffffff;
}

.pick12Icon {
  width: 15px;
  height: 19px;
  top: 4px;
}

.warningMessage {
  padding: 8px;
}

.tabsRow {
  --min-height: 20px;
  margin-inline: -16px;
}

.yearTabs {
  ion-segment-button {
    max-width: 100%;

    span {
      color: var(--mipro-color-medium-gray);
      text-transform: initial;
      font-weight: 700;
    }
  }

  .selectedSegmentTab {
    span {
      color: var(--ion-text-color-reversed);
    }
  }
}
