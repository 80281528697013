@import 'theme/mixins';

.header {
  margin: 16px 0;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .pick12Dots {
    margin-inline: 0;
    padding-inline-end: 4px;
    text-align: end;
  }
}

.pick12Menu {
  --offset-x: -5px;
  --offset-y: 20px;

  span {
    font-size: 11px;
    font-family: var(--ion-font-family);
    font-weight: 600;
    color: var(--ion-color-primary);
  }
}

.pageHeader {
  .datetoolbar {
    padding: 0;
    margin: 0 0 16px;
  }
}

.loader,
.warningMessage {
  margin: 16px;
}

.sharedBusinessDayLabel {
  display: block;
  margin: 16px;
  color: var(--ion-color-warning);
}

.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 5;
  background: var(--ion-item-background);
}

.drilldownTable {
  background: var(--mipro-color-white);
  padding-inline-start: 0px !important;
  --background: var(--mipro-color-white);

  .tableTH,
  .tableTD {
    border-right: 1px solid var(--mipro-color-light-gray);
  }

  .tableTH > div {
    min-width: 90px;
  }

  .tableTD:not(:first-child) > div {
    min-width: 90px;
  }

  .tableTD:first-child > div {
    justify-content: left;
  }
}

.infoIcon {
  padding-inline-start: 8px;
}
