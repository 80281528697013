.currencySettings {
  --inner-border-width: 0px;
  .content {
    --padding-top:8px;
    --padding-end: 16px;
    --ion-background-color: var(--ion-color-light);
    --label-micro-text-size: 13px;

    .ionGrid {
      padding: 0;
    }

    .ionPageItem {
      --background: var(--ion-background-color);
      --inner-padding-top: 24px;
      --inner-padding-bottom: 0px;
      --inner-padding-end: 4px;
      --inner-border-width: 0px;
      --border-color: var(--ion-border-separator);
    }
    ion-item {
      --background: var(--ion-background-color);
    }

    .title {
      display: block;
      margin-bottom: 32px;
      --text-default-color: var(--mipro-color-dark-text);
      line-height: 22px;
      text-transform: uppercase;
    }

    .subTitle {
      display: block;
      --label-micro-text-size: 14px;
      line-height: 17px;
      margin-bottom: 4px;
    }
    .radioGroupItem {
      padding-block-start: 12px;
    }
  }
}

.flag {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 4px;
}

