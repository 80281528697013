@import 'theme/mixins';

.leaderboardCard {
  $cardBackground: #fff;
  $cardBorder: var(--ion-color-secondary-shade);
  $borderWidth: 1px;
  $rankFontSize: 11px;
  $indentHeight: $rankFontSize * 1.1;

  width: 117px;
  height: 150px;
  background: $cardBackground;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 6px;
  margin: 4px;
  overflow: hidden;

  .rank {
    display: block;
    position: relative;
    font-size: $rankFontSize;
    line-height: $rankFontSize;
    margin-bottom: 0 - $rankFontSize;
    font-family: var(--ion-font-family-alt);
    font-weight: 700;
    color: var(--ion-text-color-dark);
    text-transform: uppercase;
    text-align: center;
  }

  .cardBorder {
    @include leaderBoardShape(0px, $indentHeight);
    background-color: $cardBorder;
    height: 100%;
    width: 100%;
  }

  .cardContent {
    @include leaderBoardShape($borderWidth, $indentHeight);
    display: block;
    position: relative;
    background-color: $cardBackground;
    width: calc(100% - $borderWidth * 2);
    height: calc(100% - $borderWidth * 2);
    top: $borderWidth;
    left: $borderWidth;
    padding-top: $indentHeight;

    // TODO: placeholder
    font-size: 14px;
    text-align: center;
    .circle {
      position: relative;
      height: 70px;
      width: 70px;
      top: 2px;
      left: 0;
      margin: 0 auto;
      margin-block-end: 8px;
      border: var(--ion-color-secondary-shade) solid 1px;
      font-size: 34px;
      .userAlt {
        width: 35px;
        height: 35px;
        color: $cardBorder;
      }
    }
    .runnerUpCircle {
      width: 57px;
      height: 57px;
      top: 2px;
      left: 0;
      margin: 0 auto;
      margin-block-end: 4px;
      font-size: 28px;
      .userAlt {
        width: 28px;
        height: 28px;
        color: $cardBorder;
      }
    }
    .userAltAvatar {
      display: flex;
      align-items: center;
      justify-content: center;
      border: $cardBorder solid 2px;
    }
    .rankerName {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .leaderName {
      position: relative;
      color: var(--ion-color-secondary-shade);
      font-family: var(--ion-font-family-alt);
      font-weight: 700;
      margin-bottom: 0px;
    }
    .firstRankerName {
      font-size: 14px;
      line-height: 12px;
    }
    .runnerUpRankerName {
      font-size: 12px;
      line-height: 10px;
    }
    .leaderLoc {
      font-size: 9px;
      line-height: 13px;
      color: var(--ion-text-color-light);
      font-family: var(--ion-font-family-alt);
    }
  }
}

.runnerUpExperiment {
  width: 96px;
  height: 123px;
}
