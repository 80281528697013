.toolbar {
  --padding-top: 0;
  --padding-bottom: 0;

  .searchRow {
    align-items: center;
    margin: 8px;

    .searchBar {
      flex: 1;
    }
  }
}
