.accountsReceivableChart {
  width: 100%;
  height: 220px;
  padding: 16px 0 0 16px;
  text-transform: uppercase;
  background: var(--ion-item-background);
  text {
    font-size: 13px !important;
    font-weight: 600;
  }
}
