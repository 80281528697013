.container {
  .content {
    --padding-bottom: 16px;
    --padding-start: 0;
    --padding-end: 0;
  }

  .costSavingsContent {
    --background: var(--ion-color-primary-contrast);
    .loader {
      margin-top: 16px;
    }
    .pageTitle {
      padding-top: 8px;
    }
    .createCsBtnWrapper {
      padding: 24px 16px;

      .createCsBtn {
        width: 100%;
        --border-radius: 6px;

        svg {
          width: 18px;
          height: 15px;
        }

        span {
          padding-inline-start: 5px;
        }
      }
    }

    .spacer {
      background-color: var(--mipro-color-background);
      height: 10px;
    }

    .agreementWrapper {
      padding: 16px;

      .sectionWrapper {
        margin-top: 8px;
        border-top: none;
        border-bottom: none;

        div {
          display: flex;
          flex-direction: row;

          span:first-child {
            display: block;
            width: 200px;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .rowClassName {
        padding: 12px 0;
      }

      .rowClassName:not(:last-child) {
        border-bottom: 1px solid var(--mipro-color-light-gray);
      }
    }

    .costSavingInProgressWrapper {
      padding: 16px 16px 0 16px;

      .costSavingsTitleWrapper {
        margin-bottom: 24px;
      }

      .pendingList,
      .draftList {
        margin: 20px 0;
        margin-left: -16px;
        margin-right: -16px;

        .edit,
        .delete {
          width: 50px;
        }

        .edit {
          --background: var(--mipro-color-primary);
        }
      }
    }
  }

  .draftList {
    .isUpdating {
      --background: var(--ion-color-medium);
      pointer-events: none;

      ion-item {
        --background: var(--ion-color-medium);
        pointer-events: none;
      }
    }

    ion-item-options {
      border-bottom-width: 1px !important;
    }

    & > :last-child {
      ion-item,
      ion-item-options {
        border-bottom: none;
      }
    }
  }

  .pendingList ion-item:last-child {
    border-bottom: none;
  }
}
