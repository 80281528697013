.warningMessage,
.loader {
  margin: 16px;
}

.itemHref {
  --padding-top: 4px;
  --padding-bottom: 4px;

  .titleRow {
    padding: 0;
    align-items: center;

    .title {
      text-transform: uppercase;
      color: var(--ion-text-color-dark);
    }

    .pendingIssuesBadge {
      border-radius: 10px;
      margin-inline-start: 8px;
      padding: 0 8px;
      background: var(--ion-color-primary);
      color: var(--ion-text-color-reversed);
      white-space: nowrap;
    }
  }

  .description {
    b {
      font-weight: normal;
      color: var(--ion-color-primary);
    }
  }
}

.itemSet {
  --min-height: 75px;
  border-top: 1px solid var(--ion-border-color);
  border-bottom: 0;

  .button {
    width: 30px;
    text-decoration: none;

    span {
      text-transform: uppercase;
    }
  }

  ion-button::part(native):focus {
    text-decoration: none;
  }

  &.selectedItem {
    --action-row-background: rgba(var(--ion-color-success-rgb), 0.05);

    span {
      font-weight: 600;
    }

    ion-button {
      svg {
        color: var(--ion-color-success) !important;
      }
    }
  }
}

.addAllButton {
  --padding-start: 8px;
  --padding-end: 8px;
  width: 150px;
}
