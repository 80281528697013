.container {
  align-items: center;
  margin-left: -5px;
}

.snoozeIcon {
  color: var(--color-snoozed-active);
  display: inline-block
}

.snoozeText {
  font-weight: 600;
  color: var(--color-snoozed-active);
  padding-top: 2px
}
