@import 'theme/mixins';

.documentImageContainer {
  background: transparent;
  padding: 0 16px 16px;
  width: 100%;

  &.noPadding {
    padding: 0;
    padding-top: 10px;
  }

  ion-row {
    margin-left: -8px;
    margin-right: -8px;
  }

  ion-col {
    padding: 0 8px 16px;
  }

  .closeIcon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--ion-item-background);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    position: absolute;
    top: 0;
    right: 0;
    @include increaseTouchTargetSize();
  }

  ion-img {
    max-width: 100%;
    display: inline-block;
    width: auto;

    &.thumbnail {
      height: 84px;
    }
  }

  .docImageRow {
    position: relative;
  }

  .uploadCol {
    text-align: center;
  }

  ion-button {
    &.openModal {
      width: 100%;
      height: 103px;
    }

    &.add,
    &.reset {
      width: 100%;
      height: 50px;
    }
  }

  .inputRow {
    align-items: center;
  }

  .imageRow {
    align-items: center;
    .imageCol {
      padding-bottom: 0;
      height: 84px;
    }

    .captionCol {
      padding-bottom: 0;
    }
  }

  .pb0 {
    padding-bottom: 0;
  }
}

.needSyncBadge,
.internalBadge {
  border-radius: 10px;
  padding: 0 8px;
  color: var(--ion-text-color-reversed);
}

.needSyncBadge {
  margin-inline-start: 8px;
  background: var(--ion-color-primary);
}

.internalBadge {
  background: var(--ion-color-warning);
}

.captionTitle {
  display: flex;
  align-items: center;
}

.offlinePlaceholder {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--ion-background-color);
}
