@import 'theme/mixins';

.dangerHighlight {
  color: var(--ion-color-danger);
}

.successHighlight {
  color: var(--ion-color-success);
}

.rateVisitButtons {
  margin: 8px 0 0;
}

.rateVisitButtonsModal {
  margin: 8px 0;
}

.webActivity {
  margin: 16px 0 -16px;
}

.webActivityButton {
  margin-top: 16px;
  position: relative;
  @include increaseTouchTargetSize(50%, 0, 50%);

  span {
    text-transform: uppercase !important;
  }

  svg {
    font-size: 11px;
  }
}

.riskCustomerText {
  display: flex;
  margin-top: 16px;
}

.riskCustomerWrapper {
  background: var(--ion-background-color);
  margin: 16px -16px -10px;
  padding: 2px 16px;
}

.customerName {
  color: var(--ion-text-color-dark);
  padding-bottom: 4px;
}

.notes {
  color: var(--mipro-color-dark-text);
}

.badge {
  background: transparent;
  padding-inline-start: 0;
  .linePrefixText {
    display: inline-block;
    font-size: 12px;
    line-height: 17px;
    &.noteText {
      font-weight: normal;
      color: var(--ion-text-color-light);
    }
  }
  .prefixIcon {
    font-size: 14px;
    width: 12px;
    height: 14px;
    &.note {
      color: var(--mipro-color-dark-text);
    }
  }
}

.proTipSubject {
  text-transform: uppercase;
}
