.item {
  display: inline-block;
  line-height: 14px;
  position: relative;

  &::after {
    content: '';
    display: inline-block;
    height: 14px;
    width: 1px;
    background: var(--ion-border-color);
    vertical-align: middle;
    margin-left: 4px;
  }

  &:last-child::after {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
}

.label {
  font-weight: 700;
  padding-inline-end: 4px;
}

.value {
  user-select: auto;
  word-break: break-word;
}
