@import 'theme/mixins';

.headerRow {
  padding: 0 16px;
  margin-bottom: 16px;
  align-items: center;
}

.filterWrapper {
  @include stickyScroller();
  padding: 0;
}

.actionRow {
  width: 100%;
  border: none;

  span {
    color: var(--ion-color-primary);
  }
}

.sort {
  margin-block-end: 4px;
  margin-block-start: 8px;
}

.loader {
  margin-top: 16px;
}

.card {
  margin: 16px 0;
}

.mainContactIcon {
  position: relative;
  height: 0;
  width: 0;
  border-top: 28px solid;
  border-right: 28px solid transparent;
  border-top-color: var(--ion-color-success);
  --overflow: visible;

  svg {
    position: absolute;
    top: -25px;
    left: 3px;
    color: var(--ion-text-color-reversed) !important;
    font-size: 9px;
  }
}

.updatingCard {
  --background: var(--ion-color-medium);
  pointer-events: none;
}

.warningMessage {
  margin: 16px;
}

.titleHeader {
  display: flex;
  align-items: center;
  padding: 0 0 10px 16px;
}

.title {
  flex: auto;
}
