@import 'theme/mixins';

.ocnGrid {
  padding: 16px;
}

.ocnLineHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  .ocnLines {
    text-transform: uppercase;
  }
  .ocnEditBtn {
    padding-bottom: 3px;
  }
  svg {
    font-size: 14px;
  }
  .check {
    color: var(--mipro-color-light-green);
  }
  .times {
    color: var(--mipro-color-light-red);
  }
}

.ocnLineItem:not(:first-child) {
  margin-top: 8px;
}

.ocnLineGrid {
  --ion-grid-columns: 100;
  padding: 0 16px 16px 16px;
}

.totalSectionGrid {
  padding: 16px;
  border-top: 1px solid var(--mipro-color-light-gray);
  .subTotalHeading {
    padding-bottom: 16px;
  }
  .separatorRow {
    margin: 12px 0px;
    border-top: 1px solid var(--mipro-color-light-gray);
  }
  .ocnSummary {
    tr:first-child {
      td {
        padding: 0px;
      }
    }
    tr:nth-child(2) {
      td {
        padding: 2px 0px;
      }
    }
    tr {
      border: none;
      td {
        padding: 4px 0px;
      }
      td:last-child {
        text-align: right;
      }
      .total {
        font-weight: 600;
      }
      .totalAmt {
        font-size: 30px;
      }
    }
  }

  .costDetails {
    tr:first-child {
      td {
        padding: 4px 0px;
      }
    }
    tr {
      border: none;
      td {
        padding: 0px;
      }
      .grossProfit {
        font-style: italic;
        line-height: 120%;
      }
      td:last-child {
        text-align: right;
      }
    }
  }
}
.attachments {
  padding: 16px;
  border-bottom: 1px solid var(--mipro-color-light-gray);
}

.list {
  ion-item {
    position: relative;
    --background: transparent;
    --padding-start: 0px;
  }
  .header {
    padding-left: 16px;
  }
  .badge {
    margin-inline-end: 6px;
  }

  .title {
    margin-top: 4px;
    text-wrap: wrap;
    text-align: left;
    position: relative;
    @include increaseTouchTargetSize(75%, 25%, 150%);
  }

  .expectedDate {
    display: flex;
    gap: 4px;
  }
  .grid {
    padding: 0px;
    margin-top: 4px;
    ion-col {
      padding: 0px;
    }
  }
  .unitPrice {
    display: flex;
    align-items: center;
    gap: 4px;
    svg {
      color: var(--mipro-color-light-red);
      font-size: 14px;
      padding-inline-end: 4px;
    }
  }
  .subTotal {
    text-align: end;
  }
  .orderStatus {
    margin: 8px 0;
    svg {
      font-size: 14px;
    }
  }
}

.ocnLineHeaderWrapper {
  padding: 0px 16px 8px 16px;
  .table {
    tr {
      border: none;
      td {
        padding: 4px 8px;
      }
      td:first-child {
        min-width: 130px;
      }
    }
    tr:nth-of-type(2n) {
      background: var(--mipro-color-background);
    }
    .customRow {
      ion-button {
        text-align: left;
        span {
          word-break: break-word;
          white-space: pre-wrap;
        }
      }
      ion-item {
        --background: transparent;
        border: none;
        --min-height: 0px;
        --padding-start: 0px;
        --padding-end: 0px;
        span,
        svg {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
}
