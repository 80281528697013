

.selCustBar {
  --ion-color-primary: var(--mipro-color-light-blue);
  .banner {
    text-transform: uppercase;
    color: var(--mipro-color-white);
  }
  .icon {
    color: var(--mipro-color-white);
  }
}

.selectedToast {
  &::part(container) {
    align-items: flex-start;
  }
  &::part(icon) {
    padding-top: 14px;
    color: var(--mipro-color-success); // #1d8747;
  }
  &::part(button cancel) {
    padding-top: 20px;
  }
}

.lineHeading {
  padding-top: 10px;
  ion-label {
    font-size: 14px;
    font-weight: 500;
    padding-left: 6px;
  }
}

.magnifier {
  svg {
    font-size: 14px;
    color: var(--mipro-color-primary) !important;
  }
}
