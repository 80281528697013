.modal {
  &::part(handle) {
    width: 50px;
    background: var(--color-help-modal-handle);
  }

  .actionRow {
    span {
      font-weight: 500;
    }

    &:last-child {
      border-bottom: 0px;
    }
  }
}
