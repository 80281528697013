.loader {
  ion-spinner {
    color: var(--ion-color-secondary) !important;
  }
}

.wrapper {
  text-align: center;
  --ion-grid-column-padding: 0;

  .text {
    display: block;
    line-height: 21px;
    margin-top: 4px;
  }
}
