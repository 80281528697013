.issueRow {
  & > ion-button {
    top: 0;
    position: sticky;
    width: 100%;
    padding: 8px 16px;
    z-index: 1;
  }

  .actions,
  & > ion-button {
    background: var(--color-table-header-sorted-background);
  }

  &:nth-child(2n) {
    .actions,
    & > ion-button {
      background: var(--color-table-sorted-background);
    }
  }

  .itemError {
    color: var(--ion-color-danger);
  }

  .issueHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .additionalData {
    margin: 0;
  }
}

.itemRow {
  padding: 16px;
  flex-direction: column;
  background: var(--ion-background-color);

  &:nth-child(2n) {
    background: var(--ion-item-background);
  }

  .itemHeader {
    width: 100%;

    span {
      flex: 1;
      text-align: center;
    }
  }
}

.actions {
  padding: 8px 32px;
  justify-content: space-between;
}

.discardModal {
  .discardTitle {
    width: 60%;
    font-size: 24px;
    line-height: 110%;
  }

  .discardContent {
    --padding-start: 27px;
    --padding-end: 27px;
  }

  .discardMsg {
    display: block;
    margin-top: 16px;
    line-height: 21px;
    --text-default-color: var(--mipro-color-black);
  }

  ion-button {
    margin-top: 16px;
    width: 100%;
  }
}
