.content {
  overflow: hidden;
  position: relative;
  flex: 1;

  .wrapper {
    overflow: auto;
    height: 100%;
  }
}

.cardWrapper {
  margin-top: 16px;
  padding: 16px;
  background: var(--ion-color-primary-contrast);

  .addNoteBtn {
    svg,
    span {
      font-size: 14px;
    }
  }

  .emptyMessage {
    padding: 16px 0;
  }
}
