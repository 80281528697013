.wrapper {
  --background: var(--ion-backdrop-color);
  z-index: var(--bulletins-z-index);
  display: flex;
  align-items: center;
  justify-content: center;
  div[slot='fixed'] {
    z-index: 20;
  }
  .backdrop {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    user-select: none;
    position: absolute;
    background-color: var(--mipro-color-backdrop);
  }
}

.bulletin {
  font-weight: 400;
  font-family: var(--ion-font-family);
  overflow-y: clip;
  height: 419px;
  @media screen and (min-width: 768px) and (min-height: 1024px) {
    height: 542px;
  }
  @media screen and (min-width: 1024px) and (min-height: 1366px) {
    height: 758px;
  }

  .icon {
    left: 50%;
    position: absolute;
    bottom: 50%;
    width: 68px;
    height: 68px;
    color: var(--icon-background-source);
    @media screen and (min-width: 768px) and (min-height: 1024px) {
      width: 80px;
      height: 80px;
    }
    @media screen and (min-width: 1024px) and (min-height: 1366px) {
      width: 90px;
      height: 90px;
    }
  }

  .imageContainer {
    position: relative;
    width: 100%;
  }
}

.bulletinSlides {
  width: 285px;
  height: 525px;
  @media screen and (min-width: 768px) and (min-height: 1024px) {
    width: 354px;
    height: 648px;
  }
  @media screen and (min-width: 1024px) and (min-height: 1366px) {
    width: 472px;
    height: 864px;
  }
  background: transparent;
  border-radius: 10px;
  overflow: visible;
  .slideCont {
    padding-block-start: 0px;
    background: var(--ion-color-primary-contrast);
    border-radius: 10px;
    --bullet-background: var(--mipro-color-deselected);
  }
}

.thumbnail {
  width: 100% !important;
  height: 200px !important;
  @media screen and (min-width: 768px) and (min-height: 1024px) {
    height: 247px !important;
  }
  @media screen and (min-width: 1024px) and (min-height: 1366px) {
    height: 329px !important;
  }
  object-position: center;
  border-radius: 10px 10px 0px 0px;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

.overlay {
  height: 100%;
  width: 100%;
  opacity: 0.3;
  transition: 0.3s ease;
  background-color: transparent;
  position: absolute;
  top: 34px;
  bottom: 0;
  left: -34px;
  right: 0;
  @media screen and (min-width: 768px) and (min-height: 1024px) {
    top: 40px;
    left: -40px;
  }
  @media screen and (min-width: 1024px) and (min-height: 1366px) {
    top: 45px;
    left: -45px;
  }
}

.title {
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  @media screen and (min-width: 768px) {
    font-size: 30px;
    line-height: 33px;
  }
  line-height: 110%;
  text-align: center;
  text-transform: uppercase;
  margin-block-end: 30px;
  margin-block-start: 30px;
  font-family: var(--ion-font-family-alt);
  width: 90%;
}

.content {
  color: var(--ion-text-color-light);
  font-family: var(--ion-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
  line-height: 140%;
  text-align: center;
  margin-block-start: 0px;
  margin-block-end: 0px;
  width: 90%;
  p:first-child {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
}

.callToAction {
  display: flex;
  justify-content: center;
  .callToActionBtn {
    --border-radius: 6px;
    --padding-start: 16px;
    --padding-end: 16px;
    margin-left: 14px;
    margin-right: 14px;
    word-wrap: break-word;
    white-space: pre-wrap;
    position: absolute;
    bottom: 30px;
    --background: var(--mipro-color-primary);
    &:active {
      --background: var(--mipro-color-dark-blue);
    }
    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}



.timesCircle {
  position: absolute;
  top: -39px;
  right: -13px;
  color: var(--icon-background-source);
  width: 28px !important;
  height: 28px !important;
  @media screen and (min-width: 768px) and (min-height: 1024px) {
    width: 42px !important;
    height: 42px !important;
    top: -110px;
    right: -140px;
  }
  @media screen and (min-width: 1024px) and (min-height: 1366px) {
    width: 54px !important;
    height: 54px !important;
    top: -110px;
    right: -140px;
  }
}
