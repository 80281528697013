.updatingCard {
  --background: var(--ion-color-medium) !important;
  pointer-events: none;
}

.multiSelected {
  --background: rgba(12, 108, 212, 0.2);
}


.multiSelectEnabled {
  background-color: #0c6cd4 !important;

  span {
    visibility: hidden;
  }

  ion-button {
    border: 2px solid #0c6cd4 !important;
    background-color: var(--ion-color-primary-contrast) !important;
  }
}

.multiSelectCheck {
  background-color: #0c6cd4 !important;

  ion-button {
    background-color: #0c6cd4 !important;
  }
}

.nonSelectable {
  --background: var(--mipro-color-disabled) !important;
}

.nonSelectableCheck {
  ion-button {
    border-color: var(--ion-color-light-shade) !important;
  }
}
