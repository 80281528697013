@import 'theme/mixins';

.menu {
  pointer-events: auto;
  cursor: auto;

  &::part(container) {
    box-shadow: none;
  }

  .headerButton {
    line-height: 1;
    position: relative;
    color: var(--ion-text-color-reversed);

    svg {
      color: var(--ion-text-color-reversed);
    }
  }

  .homeHeader {
    ion-toolbar {
      text-align: left;

      &:not(:empty) {
        --padding-top: 4px;
        --padding-bottom: 15px;
      }

      .closeMenuButton {
        @extend .headerButton;
        @include increaseTouchTargetSize();
        margin-inline-end: 10px;
      }
    }
  }

  .avatar {
    height: var(--ion-avatar-size);
    margin-bottom: 0;
    margin-inline-end: 8px;
    margin-inline-start: 8px;
    --border-radius: 50%;
    font-size: 2rem;
  }
}

.username {
  height: 20px;
  margin-bottom: 4px;
  text-transform: capitalize;

  span {
    color: var(--ion-text-color-reversed);
  }
}

.location {
  height: 18px;
  align-items: center;

  span {
    color: var(--ion-text-color-reversed);
  }
}

.locationButton {
  @extend .headerButton;
  margin: 0 4px;
  text-decoration: underline;
}

.setLocationButton {
  @extend .locationButton;
  @include increaseTouchTargetSize(50%, 0, 50%);
}

.primary {
  margin-top: 16px;
  border-top: 1px solid var(--ion-border-color);

  span {
    color: var(--ion-color-primary);
  }
}
.demoModeRow {
  border-top: 1px solid var(--ion-border-color);
  span {
    color: var(--ion-color-primary);
  }
}

.primaryButton {
  width: 100%;
  margin-top: 32px;
}

.usernameText {
  display: block;
  margin-bottom: 8px;
}

.password {
  margin-top: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  .wrapper {
    flex: 1;
    padding-top: 16px;

    ion-menu-toggle:first-child ion-item {
      border-top: 1px solid var(--ion-border-color);
    }
  }
}

.menuIconButton {
  margin-inline-end: -12px;
}

.menuImg {
  width: 25px;
}


