.loader,
.warningMessage {
  margin: 16px;
}

.sharedBusinessDayLabel {
  display: block;
  margin: 16px;
  color: var(--ion-color-warning);
}

.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 5;
  background: var(--ion-item-background);
}

.drilldownTable {
  background: var(--mipro-color-white);
  padding-inline-start: 0px !important;
  --background: var(--mipro-color-white);

  .tableTH,
  .tableTD {
    border-right: 1px solid var(--mipro-color-light-gray);
  }

  .tableTH > div {
    min-width: 90px;
  }

  .tableTD:not(:first-child) > div {
    min-width: 90px;
  }

  .tableTD:first-child > div {
    justify-content: left;
  }
}
