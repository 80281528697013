.subjectRow {
  padding-block: 16px;
}
.subject {
  --text-default-color: var(--mipro-color-black);
}
.requestedBy {
  padding-inline-end: 4px;
}
.requestedUser {
  color: var(--mipro-color-light-blue);
}
.divider {
  padding-inline: 8px;
}
.detailsRow {
  padding-block: 16px;
  .details {
    --text-default-color: var(--mipro-color-dark-text);
  }
}
.manufacturerRow {
  padding-block-end: 4px;
  .manufaturerLabel {
    text-transform: uppercase;
  }
}
.manufaturerNameRow {
  padding-block-end: 16px;
}
.proButtonRow {
  padding-block: 16px;
  .proButton {
    width: 100%;
  }
}

