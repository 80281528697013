.sectionTitle {
  text-transform: uppercase;
}

.rowTable {
  height: 600px;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  text-transform: uppercase;
}

.subTitle {
  padding-block-start: 4px;
}

.titleDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .titleFirtRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.data {
  background-color: var(--ion-text-color-reversed);
  border-top: 1px solid var(--ion-border-color);
  border-bottom: 1px solid var(--ion-border-color);
  height: 500px;

  .label {
    height: 100px;
  }
}

.iconClass {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  background: var(--mipro-supplier-connect);
  color: var(--ion-color-primary-contrast);
  text-align: center;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
}

.rowClassName {
  padding: 12px 16px;
  border-bottom: 1px solid var(--mipro-color-disabled-tint);

  span:last-child {
    text-align: left;
  }
}
