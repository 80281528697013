.input {
  margin: 0 16px 16px;
  --searchbar-border-color: var(--ion-text-color);

  ion-row {
    border-radius: 6px;
  }
}

.content {
  --padding-bottom: 16px;
}

.emptyListWrapper {
  padding-top: 8px;

  .emptyListText {
    display: list-item;
    list-style-position: inside;
    font-size: 14px;
    line-height: 20px;

    &:first-child {
      display: block;
    }
  }
}
