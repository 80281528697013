@import 'theme/mixins';

.content {
  --padding-top: 16px;
  --padding-bottom: 16px;
}

.addNoteRow {
  width: 100%;
  border: none;

  span {
    color: var(--ion-color-primary);
  }
}

.noteCard:first-child {
  border-top: 1px solid var(--ion-border-color);
}

.loader {
  margin-top: 16px;
}

.warningMessage {
  margin: 16px;
}

.galleryAttachmentsLarge {
  padding: 6px;
}

.filterWrapper {
  @include stickyScroller();
  padding: 0;
  top: -16px;
  margin-top: -16px;
}

.userId {
  margin-inline-start: 4px;
}

.noteItem {
  padding: 8px 0;
}

.noTitleMessage {
  text-align: left;
}

.filterTabs {
  ion-segment-button span {
    color: var(--ion-text-color-reversed);
  }
}

.ellipsis {
  @include textEllipsis(1);
}
