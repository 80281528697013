.divider {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 16px 8px 8px;
  font-weight: 600;
  background: var(--ion-background-color);
}

.divider::after,
.divider::before {
  content: '';
  border: 1px solid var( --mipro-color-light-gray);
  flex: 1;
}

.divider:not(:empty)::before {
  margin-right: 8px;
}

.divider:not(:empty)::after {
  margin-left: 8px;
}

.hidden {
  max-height: 1px;
  visibility: hidden;
}
