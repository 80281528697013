// TODO: many unused classes, cleanup this file
.container {
  .header {
    .favoritesButton {
      height: auto;
      margin-left: 38px;
      padding-bottom: 16px;

      svg,
      span {
        color: var(--ion-text-color-reversed);
      }
    }
  }

  .content {
    --padding-top: 0;
    --padding-bottom: 16px;
    --padding-start: 0;
    --padding-end: 0;
    background-color: var(--ion-color-secondary-contrast);
  }

  .detailsContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
    background-color: var(--ion-color-secondary-contrast);

    .firstRowWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid var(--ion-border-color);
    }

    .itemWrapper {
      svg {
        font-size: 14px;
        width: 14px;
        margin-inline-end: 8px;
      }
    }
    .rowWrapper {
      width: 100%;
      &:first-child {
        padding: 32px 0;
      }
      &:last-child {
        padding-bottom: 8px;
      }
      .buttonWrapper {
        padding-left: 16px;
      }

      .rowItem {
        margin: 6px;
      }

      .employeeAvatar {
        width: 80px;
        height: 80px;
        align-self: center;
      }

      .employeeFallback {
        align-self: center;
        font-size: 36px;
        color: var(--ion-color-primary-contrast);
      }

      .empInfo {
        align-self: center;
        &:first-of-type {
          padding-top: 16px;
        }
      }
      .titleUpper {
        text-transform: uppercase;
      }


      .buttonTextWrapper {
        display: flex;
        text-align: start;
        white-space: normal;

        .buttonText {
          white-space: pre-line;
          word-break: break-word;
          color: var(--ion-color-primary);
        }
      }
    }

    .contactWrapper {
      color: #0c6cd4;
      margin: 8px;
    }
  }

  .actionRowWrapper {
    margin-top: 10px;

    .actionRow:first-child {
      border-top: 1px solid var(--ion-border-color);
    }
  }

  .loader {
    margin-top: 16px;
  }
}

.miniCardWrapper {
  display: flex;
  padding: 0 16px;
  column-gap: 8px;
  > * {
    flex: 1 1;
    width: 50%;
  }
}

.externalLinks {
  .linkedCard:not(:last-child) {
    margin-block-end: 11px;
  }
}

.linkedCard {
  margin: 0;
}

.customerFeatures {
  .salesTarget {
    display: flex;
    flex-direction: row;
  }

  .salesTargetAvatar {
    --ion-avatar-size: 56px;
    margin-inline-end: 16px;

    svg {
      color: var(--ion-color-secondary);
    }
  }

  .salesTargetHeader {
    padding-inline-end: 0;
    padding-inline-start: 0;
    padding-block-end: 1px;
    padding-block-start: 5px;
    text-transform: uppercase;
  }

  .pickTwelveSales {
    padding-inline-end: 5px;
  }

  .editIcon {
    top: -5px;
    position: relative;

    & > span {
      font-weight: 400;
    }
  }

  .activityCard {
    margin: 8px 16px;
  }

  .logVisit {
    margin-bottom: 16px;

    span {
      color: var(--ion-color-primary);
    }
  }
}

.rowSeparator {
  border-bottom: 1px solid var(--ion-border-color);
}

.warningMessage {
  margin: 16px;
}
