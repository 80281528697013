.modal {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 0;
  --padding-end: 0;
}

.header {
  padding: 16px 16px 0;
  position: relative;
  border-bottom: 1px solid var(--ion-border-color);
  width: 100%;
}

.footer {
  padding: 16px;
  border-top: 1px solid var(--ion-border-color);

  ion-button {
    width: 100%;
  }
}

.searchWrapper {
  margin-top: 16px;

  .searchbar {
    --searchbar-border-color: var(--ion-text-color);
  }
}

.warningMessage {
  margin: 16px;
}

.list {
  // TODO: better calculate height
  height: calc(100vh - 180px - var(--ion-safe-area-top));

  .item {
    background: var(--ion-item-background);

    &.selectedItem {
      --action-row-background: rgba(var(--ion-color-success-rgb), 0.05);
    }

    .wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .title {
      font-size: 16px;
      font-weight: 400;
      font-family: var(--ion-font-family);
      color: var(--ion-text-color-dark);
    }

    .description {
      font-size: 13px;
      font-weight: 400;
      font-family: var(--ion-font-family);
      color: var(--ion-text-color-light);
    }
  }
}

.searchLink {
  margin-top: 4px;
}

.clear {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 16px -16px 0;
  padding: 12px 16px;
  border-top: 1px solid var(--ion-border-color);

  .selectedText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 18px;
  }

  & > div {
    flex: 1;

    span {
      font-size: 16px;
      font-weight: 600;
      text-transform: capitalize;
      font-family: var(--ion-font-family);
      color: var(--ion-text-color-dark);
    }
  }

  ion-button {
    height: 36px;
    --padding-start: 12px;
    --padding-end: 12px;
    --border-radius: 8px;
    --padding-top: 10px;

    &.disabled {
      --border-color: var(--color-disabled-button);

      span {
        color: var(--color-disabled-button);
      }
    }
  }
}

.noSelectedItem {
  @extend .clear;
  padding: 0;
  border-top: 0px;
}

.segmentMargins {
  @extend .clear;
  margin: 0px -16px 0;
}
