.searchBarWrapper {
  padding-bottom: 8px;
  align-items: center;
  &.customerLessSearchBar {
    padding-inline: 16px;
    padding-bottom: 16px;
  }

  .selectBtn {
    z-index: 2;
    height: 46px;
    --border-radius: 0px;
    border-radius: 0px;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    padding-left: 8px;
    padding-right: 8px;

    span,
    svg {
      font-size: 12px;
    }
  }
  .searchBar {
    flex: 1 1;

    .inputRow {
      padding-left: 8px;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
}

.container {
  .content {
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --background: var(--mipro-color-background);
  }
}

.header {
  ion-toolbar {
    --border-width: 0px !important;
  }
}

.searchByContent {
  margin-top: 16px;

  .checkIcon {
    margin-right: 8px;
    color: var(--mipro-color-light-green);
  }

  .bold {
    font-weight: 600;
  }
}

