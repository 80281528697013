.itemRow {
  flex-direction: column;

  .itemName {
    font-weight: 600;
    margin-inline-end: 4px;
  }
}

.viewonlyList {
  flex-direction: column;
  align-items: flex-start;
}
