.border {
  height: 2px;
  margin-block-start: 4px;
  margin-block-end: 0;
  position: relative;

  &:before {
    content: "";
    height: 2px;
    width: 4px;
    left: 0;
    bottom: 0;
    position: absolute;
    background: linear-gradient(to top, var(--ion-color-secondary-shade), var(--ion-color-secondary));

  }
  &:after {
    content: "";
    height: 2px;
    width: 55px;
    left: 1px;
    bottom: 0;
    position: absolute;
    transform: skew(-25deg);
    background: linear-gradient(to top, var(--ion-color-secondary-shade), var(--ion-color-secondary));
  }
}
