.sectionWrap {
  display: block;
  margin-bottom: 16px;

  .p0 {
    padding: 0;
  }

  .accordionHeader {
    margin-left: 16px;
    margin-right: 16px;
    padding: 0;
    padding-bottom: 14px;
    background-color: var(--ion-color-light);
    border: 1px solid var(--ion-border-color);

    &:focus {
      outline: none;
    }

    &.isLoading {
      background-color: var(--ion-border-color);
    }
  }

  .headerRow {
    align-items: center;

    svg {
      margin-right: 16px;
      height: 20px;
      width: 20px;
    }
  }

  .accordionPanel {
    padding: 0;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: -2px;
    background-color: var(--ion-color-light);
    border-radius: 1.5px;
    border: 1px solid var(--ion-border-color);
    border-top: 0;

    &.genericContainer {
      background: none;
      border: none;
    }
  }

  > .paragraphWrap {
    padding-top: 8px;
  }

  > .paragraphWrap ~ .paragraphWrap {
    padding-top: 0px;
  }

  .iconCol {
    margin-top: 16px;
    padding: 0;
  }

  .sectionLoaderWrap {
    display: inline-block;
    padding-right: 16px;
  }
}

.hideSectionHeader {
  display: none;
}
