.section {
  display: grid;
  grid-template-rows: min-content 0fr;
  transition: grid-template-rows 500ms;
  margin-top: 16px;

  &.expanded {
    grid-template-rows: min-content 1fr;

    & > ion-item .icon {
      transform: rotate(180deg);
    }

    & > .content {
      overflow: unset;
    }
  }

  &:first-child {
    margin: 0;
  }

  .header {
    padding: 0;
    --background: var(--mipro-color-white);
    --background-activated: var(--mipro-color-white);
    --background-activated-opacity: 1;
    --background-focused: var(--mipro-color-white);
    --background-focused-opacity: 1;
    --background-hover: var(--mipro-color-white);
    --background-hover-opacity: 1;
    --ripple-color: transparent;
    --padding-top: 4px;
    --padding-bottom: 4px;
    --padding-end: 16px;
    --padding-start: 16px;

    .iconWrapper {
      padding-inline-end: 16px;
    }

    .icon {
      transition: 300ms transform cubic-bezier(0.25, 0.8, 0.5, 1);
      color: var(--mipro-color-primary);
    }
  }

  & > .content {
    overflow: hidden;
    background: var(--mipro-color-white);
  }
}
